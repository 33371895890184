import * as React from 'react';

const SvgSelectionViewIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18.393}
    height={19.062}
    viewBox="0 0 18.393 19.062"
    {...props}
  >
    <g
      id="Group_57321"
      data-name="Group 57321"
      transform="translate(-1506.329 -1044.689)"
    >
      <g
        id="Group_57320"
        data-name="Group 57320"
        transform="translate(1506.633 1049.5)"
      >
        <path
          id="Path_102945"
          data-name="Path 102945"
          d="M5.334,14.326l2.95-3.781a5.347,5.347,0,1,1,1.13.894L6.468,15.213a.722.722,0,0,1-1.015.121h0a.722.722,0,0,1-.119-1.008Zm6.378-3.394A4.125,4.125,0,1,0,8.045,7.265,4.125,4.125,0,0,0,11.712,10.932Z"
          transform="translate(-5.18 -1.489)"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth={0.5}
        />
      </g>
      <path
        id="Path_102947"
        data-name="Path 102947"
        d="M-20311.293,1582.663h10.266v-14.225h-12.744v6.049"
        transform="translate(21825 -523)"
        fill="none"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeDasharray={2}
      />
    </g>
  </svg>
);

export default SvgSelectionViewIcon;
