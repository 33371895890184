import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Viewer translations
import viewerTR from './locale/tr/viewer.json';
import viewerEN from './locale/en/viewer.json';

// Newspaper translations
import newspaperTR from './locale/tr/newspaper.json';
import newspaperEN from './locale/en/newspaper.json';

export const defaultNS = process.env.REACT_APP_TYPE;
export const resources = {
  tr: {
    viewer: viewerTR,
    newspaper: newspaperTR
  },
  en: {
    viewer: viewerEN,
    newspaper: newspaperEN
  }
};

i18n.use(initReactI18next)
  .init({
    ns: ['viewer', 'newspaper'],
    defaultNS,
    resources,
    lng: document.documentElement.lang,
    cleanCode: true,
    fallbackLng: 'tr',
    interpolation: {
      escapeValue: false
    },
    react: {
      transKeepBasicHtmlNodesFor: ['u']
    },
    returnNull: false
  });

export default i18n;
