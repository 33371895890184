import { trackEvent } from '@redux-beacon/google-analytics-gtag';

export const apiSuccessEvent = trackEvent((action) => ({
  action: 'fetch',
  fieldsObject: {
    category: action.type
  }
}));

export const apiErrorEvent = trackEvent((action) => ({
  action: 'exception',
  fieldsObject: {
    description: action.payload.message,
    category: action.type,
    fatal: false
  }
}));
