import { createAction } from 'redux-api-middleware';
import i18n from 'i18n';

export const UPDATE_DOCUMENT_PIN_REQUEST = '@@api/UPDATE_DOCUMENT_PIN_REQUEST';
export const UPDATE_DOCUMENT_PIN_SUCCESS = '@@api/UPDATE_DOCUMENT_PIN_SUCCESS';
export const UPDATE_DOCUMENT_PIN_FAILURE = '@@api/UPDATE_DOCUMENT_PIN_FAILURE';

const updateDocumentPin = (id, data) => (dispatch, getState) => {
  const { documents } = getState();

  const documentId = documents.data.documentId;
  if (! documentId) return;

  return dispatch(createAction({
    endpoint: process.env.REACT_APP_API + `/documents/${documentId}/pins/${id}`,
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Accept-Language': i18n.language,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data),
    types: [
      UPDATE_DOCUMENT_PIN_REQUEST,
      UPDATE_DOCUMENT_PIN_SUCCESS,
      UPDATE_DOCUMENT_PIN_FAILURE
    ]
  }));
};

export default updateDocumentPin;
