import { trackEvent } from '@redux-beacon/google-analytics-gtag';

const searchEvent = trackEvent((action) => ({
  action: 'search',
  fieldsObject: {
    search_term: action.payload.q,
    search_exact_match: action.payload.isFullKeyword
  }
}));

export const selectSearchEvent = trackEvent((action) => ({
  action: 'select_content',
  fieldsObject: {
    content_type: 'search',

    // TODO: arama sonuç id'si gelecek.
    item_id: action.payload
  }
}));

export const resetSearchEvent = trackEvent(() => ({
  action: 'reset_content',
  fieldsObject: {
    content_type: 'search'
  }
}));

export default searchEvent;
