import {
  DOCUMENT_DETAIL_REQUEST,
  DOCUMENT_DETAIL_SUCCESS,
  DOCUMENT_DETAIL_FAILURE
} from 'store/actions/api/fetchDocumentDetail';

const initialState = {
  isFetching: false,
  isError: false,
  data: {}
};

const document = (state = initialState, action) => {
  switch (action.type) {
    case DOCUMENT_DETAIL_REQUEST:
      return { isFetching: true, isError: false, data: {} };
    case DOCUMENT_DETAIL_SUCCESS:
      return { isFetching: false, isError: false, data: action.payload };
    case DOCUMENT_DETAIL_FAILURE:
      return { isFetching: false, isError: true, data: {} };
    default:
      return state;
  }
};

export default document;
