import React, { useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { isEmpty } from 'lodash';
import {
  Button,
  ButtonGroup,
  Input,
  FormLabel,
  FormControl,
  FormErrorMessage
} from '@chakra-ui/react';

// Components
import i18n from 'i18n';

function TitleForm({ initialValues = {}, children, onSubmit, onClose }) {
  const firstFieldRef = useRef(null);

  // handle formik validation
  const handleValidation = (values) => {
    let errors = {};

    // başlık alanını zorunlu yapalım.
    if (! values.title) {
      errors.title = i18n.t('form.validation.null', {
        field: i18n.t('pin.type.title.title')
      });
    }

    return errors;
  };

  // handle submit
  const handleSubmit = (values, actions) => {
    actions.setSubmitting(false);

    // callback
    if (onSubmit) {
      onSubmit(values);
    }
  };

  return (
    <Formik
      initialValues={{
        title: '',
        titleEn: '',
        titleAr: '',
        content: '',
        author: '',
        translator: '',
        ...initialValues
      }}
      enableReinitialize={true}
      validate={handleValidation}
      onSubmit={handleSubmit}>
      {({
        dirty,
        values,
        errors,
        touched,
        isValid,
        handleBlur,
        handleChange
      }) => (
        <Form>
          <FormControl variant="floating" isInvalid={(errors.title && touched.title) && true} mb={5} isRequired>
            <Input
              id="title"
              size="lg"
              value={values.title}
              placeholder=" "
              fontSize="sm"
              onBlur={handleBlur}
              onChange={handleChange}
              ref={firstFieldRef}
            />
            <FormLabel 
              htmlFor="title" 
              fontSize="sm" 
              fontWeight="normal">{i18n.t('form.label.title')}</FormLabel>
            {errors.title && touched.title
              ? <FormErrorMessage>{errors.title}</FormErrorMessage>
              : <Fragment />
            }
          </FormControl>
          <FormControl variant="floating" isInvalid={(errors.content && touched.content) && true} mb={5}>
            <Input
              id="content"
              size="lg"
              value={values.content}
              placeholder=" "
              fontSize="sm"
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <FormLabel 
              htmlFor="content" 
              fontSize="sm" 
              fontWeight="normal">{i18n.t('form.label.summary')}</FormLabel>
            {errors.content && touched.content
              ? <FormErrorMessage>{errors.content}</FormErrorMessage>
              : <Fragment />
            }
          </FormControl>
          <FormControl variant="floating" isInvalid={(errors.titleEn && touched.titleEn) && true} mb={5}>
            <Input
              id="titleEn"
              size="lg"
              value={values.titleEn}
              placeholder=" "
              fontSize="sm"
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <FormLabel 
              htmlFor="titleEn" 
              fontSize="sm" 
              fontWeight="normal">{i18n.t('form.label.titleEn')}</FormLabel>
            {errors.titleEn && touched.titleEn
              ? <FormErrorMessage>{errors.titleEn}</FormErrorMessage>
              : <Fragment />
            }
          </FormControl>
          <FormControl variant="floating" isInvalid={(errors.titleAr && touched.titleAr) && true} mb={5}>
            <Input
              id="titleAr"
              size="lg"
              value={values.titleAr}
              placeholder=" "
              fontSize="sm"
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <FormLabel 
              htmlFor="titleAr" 
              fontSize="sm" 
              fontWeight="normal">{i18n.t('form.label.titleAr')}</FormLabel>
            {errors.titleAr && touched.titleAr
              ? <FormErrorMessage>{errors.titleAr}</FormErrorMessage>
              : <Fragment />
            }
          </FormControl>
          <FormControl variant="floating" isInvalid={(errors.author && touched.author) && true} mb={5}>
            <Input
              id="author"
              size="lg"
              value={values.author}
              placeholder=" "
              fontSize="sm"
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <FormLabel 
              htmlFor="author" 
              fontSize="sm" 
              fontWeight="normal">{i18n.t('form.label.author')}</FormLabel>
            {errors.author && touched.author
              ? <FormErrorMessage>{errors.author}</FormErrorMessage>
              : <Fragment />
            }
          </FormControl>
          <FormControl variant="floating" isInvalid={(errors.translator && touched.translator) ? true : false} mb={5}>
            <Input
              id="translator"
              size="lg"
              value={values.translator}
              placeholder=" "
              fontSize="sm"
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <FormLabel 
              htmlFor="translator" 
              fontSize="sm" 
              fontWeight="normal">{i18n.t('form.label.translator')}</FormLabel>
            {errors.translator && touched.translator
              ? <FormErrorMessage>{errors.translator}</FormErrorMessage>
              : <Fragment />
            }
          </FormControl>
          {children}
          <ButtonGroup width="100%" gap={4}>
            <Button 
              size="lg" 
              fontSize="sm" 
              variant="outline" 
              width="50%" 
              onClick={onClose}>{i18n.t('form.cancel')}</Button>
            <Button 
              type="submit" 
              size="lg" 
              fontSize="sm" 
              colorScheme="blue" 
              width="50%" 
              disabled={(! dirty && isEmpty(initialValues)) || !isValid}>{i18n.t('form.save')}</Button>
          </ButtonGroup>
        </Form>
      )}
    </Formik>
  );
}

TitleForm.propTypes = {
  initialValues: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.bool
  ]),
  onSubmit: PropTypes.func,
  onClose: PropTypes.func
};

export default TitleForm;
