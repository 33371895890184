import * as React from 'react';
import PropTypes from 'prop-types';

const SvgListIcon = ({ color = 'currentColor', ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={4.565}
      height={21}
      viewBox="0 0 4.565 21"
      {...props}
    >
      <g id="List" transform="translate(-37 -388)">
        <circle
          id="Ellipse_493"
          data-name="Ellipse 493"
          cx={2.283}
          cy={2.283}
          r={2.283}
          transform="translate(37 388)"
          fill={color}
        />
        <circle
          id="Ellipse_493-2"
          data-name="Ellipse 493"
          cx={2.283}
          cy={2.283}
          r={2.283}
          transform="translate(37 396.217)"
          fill={color}
        />
        <circle
          id="Ellipse_493-3"
          data-name="Ellipse 493"
          cx={2.283}
          cy={2.283}
          r={2.283}
          transform="translate(37 404.435)"
          fill={color}
        />
      </g>
    </svg>
  );
};

SvgListIcon.propTypes = {
  color: PropTypes.string
};

export default SvgListIcon;
