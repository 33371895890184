import * as React from 'react';

const SvgCatalogViewIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    viewBox="0 0 18 18"
    {...props}
  >
    <g id="Card_View" data-name="Card View" transform="translate(-1396 -1044)">
      <g
        id="Rectangle_14436"
        data-name="Rectangle 14436"
        transform="translate(1396 1044)"
        fill="#fff"
        fillOpacity={0}
        stroke="currentColor"
        strokeWidth={2}
      >
        <rect width={8.1} height={8.1} rx={2} stroke="none" />
        <rect x={1} y={1} width={6.1} height={6.1} rx={1} fill="none" />
      </g>
      <g
        id="Rectangle_14439"
        data-name="Rectangle 14439"
        transform="translate(1396 1053.9)"
        fill="#fff"
        fillOpacity={0}
        stroke="currentColor"
        strokeWidth={2}
      >
        <rect width={8.1} height={8.1} rx={2} stroke="none" />
        <rect x={1} y={1} width={6.1} height={6.1} rx={1} fill="none" />
      </g>
      <g
        id="Rectangle_14437"
        data-name="Rectangle 14437"
        transform="translate(1405.9 1044)"
        fill="#fff"
        fillOpacity={0}
        stroke="currentColor"
        strokeWidth={2}
      >
        <rect width={8.1} height={8.1} rx={2} stroke="none" />
        <rect x={1} y={1} width={6.1} height={6.1} rx={1} fill="none" />
      </g>
      <g
        id="Rectangle_14438"
        data-name="Rectangle 14438"
        transform="translate(1405.9 1053.9)"
        fill="#fff"
        fillOpacity={0}
        stroke="currentColor"
        strokeWidth={2}
      >
        <rect width={8.1} height={8.1} rx={2} stroke="none" />
        <rect x={1} y={1} width={6.1} height={6.1} rx={1} fill="none" />
      </g>
    </g>
  </svg>
);

export default SvgCatalogViewIcon;
