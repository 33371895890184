import { SET_SCALE_MODE } from './actionTypes';

const modes = [
  'page-height',
  'page-width',
  'page-fit'
];

export function setScaleMode(mode) {
  if (! modes.includes(mode)) return {};

  return { type: SET_SCALE_MODE, payload: mode };
}
