import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Flex,
  useColorModeValue
} from '@chakra-ui/react';

// Context
import { useViewer } from 'components/PDFDocument';

// Actions
import {
  closeSidebar,
  openLeftSidebar,
  selectSearchResult,
  resetSearch
} from 'store/actions';

// Components
import i18n from 'i18n';

// UI
import {
  CloseIcon,
  NextIcon,
  PrevIcon,
  SearchIcon
} from 'components/Icons';

function ResultsBar() {
  const dispatch = useDispatch();
  const results = useSelector(state => state.search.results.data);
  const current = useSelector(state => state.search.results.data.findIndex(r =>
    r.selected === true
  ));
  const total = useSelector(state => state.search.results.data.length);
  const hide_searches = useSelector(state => state.annonation.hide_searches);
  const sidebar = useSelector(state => state.app.sidebar);
  const { goToPage } = useViewer();

  // local state
  const color = useColorModeValue('#5b6f8e', 'white');

  const handleNext = () => {
    const next = results[current + 1];

    // seçilmiş sayfaya git
    goToPage(next.pageNumber);

    // save state
    dispatch(selectSearchResult(next.id));
  };

  const handlePrev = () => {
    const prev = results[current - 1];

    // seçilmiş sayfaya git
    goToPage(prev.pageNumber);

    // save state
    dispatch(selectSearchResult(prev.id));
  };

  const handleClose = () => {
    dispatch(resetSearch());
  };

  const toggleSidebar = () => {
    if (sidebar.status === true) {
      return dispatch(closeSidebar());
    }
    dispatch(openLeftSidebar('search'));
  };

  // arama sonuçları görünmüyorsa gizleyelim.
  if (! results.length || hide_searches) return <Fragment />;

  return (
    <Fragment>
      <Flex justifyContent="space-between">
        <Button variant="ghost" color={color}
          fontSize="sm"
          fontWeight="medium"
          ml={-3}
          px={3}
          leftIcon={<SearchIcon color={color} />}
          onClick={toggleSidebar}
          _hover={{ backgroundColor: 'transparent' }}
          _active={{ backgroundColor: 'transparent' }}>
          {i18n.t('search.results')}
        </Button>
        <Box>
          <Button
            variant="ghost"
            height={10}
            onClick={handlePrev}
            disabled={current <= 0}>
            <PrevIcon color={color} />
          </Button>
          <Button
            color={color}
            fontSize="sm"
            fontWeight="normal"
            variant="ghost"
            height={10}>{current + 1}/{total}</Button>
          <Button
            variant="ghost"
            height={10}
            onClick={handleNext}
            disabled={current === (total - 1)}>
            <NextIcon color={color} />
          </Button>
        </Box>
        <Button variant="ghost" onClick={handleClose}>
          <CloseIcon color={color} />
        </Button>
      </Flex>
    </Fragment>
  );
}

export default ResultsBar;
