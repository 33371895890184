import {
  HIDE_PINS,
  SHOW_PINS,
  HIDE_SEARCHES,
  SHOW_SEARCHES,
  HIDE_TRANSLATES,
  SHOW_TRANSLATES
} from 'store/actions/actionTypes';

const initialState = {
  hide_pins: false,
  hide_searches: false,
  hide_translates: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case HIDE_PINS:
      return { ...state, hide_pins: true };
    case SHOW_PINS:
      return { ...state, hide_pins: false };
    case HIDE_SEARCHES:
      return { ...state, hide_searches: true };
    case SHOW_SEARCHES:
      return { ...state, hide_searches: false };
    case HIDE_TRANSLATES:
      return { ...state, hide_translates: true };
    case SHOW_TRANSLATES:
      return { ...state, hide_translates: false };
    default:
      return state;
  }
};

export default reducer;
