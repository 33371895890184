import {
  SET_INITIAL_PAGE,
  SET_CURRENT_PAGE,
  SET_SCALE,
  SET_SCALE_MODE,
  SET_BRIGHTNESS,
  SET_SATURATE,
  SET_CONTRAST,
  SET_GRAYSCALE,
  RESET_SCALE
} from 'store/actions/actionTypes';

const initialState = {
  initial_page: 1,
  current: null,
  scale: 1,
  scale_mode: 'page-height',
  effects: {
    brightness: null,
    contrast: null,
    saturate: null,
    grayscale: null
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_INITIAL_PAGE:
      return { ...state, initial_page: action.payload };
    case SET_CURRENT_PAGE:
      return { ...state, current: action.payload };
    case SET_SCALE:
      return { ...state, scale: action.payload };
    case SET_SCALE_MODE:
      return { ...state, scale_mode: action.payload };
    case SET_BRIGHTNESS:
      return { ...state, effects: { ...state.effects, brightness: action.payload } };
    case SET_SATURATE:
      return { ...state, effects: { ...state.effects, saturate: action.payload } };
    case SET_GRAYSCALE:
      return { ...state, effects: { ...state.effects, grayscale: action.payload } };
    case SET_CONTRAST:
      return { ...state, effects: { ...state.effects, contrast: action.payload } };
    case RESET_SCALE:
      return { ...state, scale: initialState.scale };
    default:
      return state;
  }
};

export default reducer;
