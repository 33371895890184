import * as React from 'react';
import PropTypes from 'prop-types';

const SvgAngleDoubleRightIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17.97}
    height={14.5}
    viewBox="0 0 17.97 14.5"
    {...props}
  >
    <g id="Group_57485" data-name="Group 57485">
      <path
        id="Arrow"
        d="M7.987,7a.9.9,0,0,1-.063.342.79.79,0,0,1-.215.291L1.451,13.764a.829.829,0,0,1-.6.236.886.886,0,0,1-.43-.105.779.779,0,0,1-.308-.3.843.843,0,0,1,.148-1.033L5.954,7,.261,1.442A.833.833,0,0,1,0,.835.834.834,0,0,1,.114.4a.787.787,0,0,1,.308-.3A.886.886,0,0,1,.852,0a.829.829,0,0,1,.6.236L7.708,6.367a.79.79,0,0,1,.215.291A.9.9,0,0,1,7.987,7Z"
        transform="translate(9.483 0)"
        fill={props.color}
        stroke={props.color}
        strokeWidth={0.5}
      />
      <path
        id="Arrow-2"
        data-name="Arrow"
        d="M7.987,7a.9.9,0,0,1-.063.342.79.79,0,0,1-.215.291L1.451,13.764a.829.829,0,0,1-.6.236.886.886,0,0,1-.43-.105.779.779,0,0,1-.308-.3.843.843,0,0,1,.148-1.033L5.954,7,.261,1.442A.833.833,0,0,1,0,.835.834.834,0,0,1,.114.4a.787.787,0,0,1,.308-.3A.886.886,0,0,1,.852,0a.829.829,0,0,1,.6.236L7.708,6.367a.79.79,0,0,1,.215.291A.9.9,0,0,1,7.987,7Z"
        transform="translate(0 0)"
        fill={props.color}
        stroke={props.color}
        strokeWidth={0.5}
      />
    </g>
  </svg>
);

SvgAngleDoubleRightIcon.propTypes = {
  color: PropTypes.string
};

export default SvgAngleDoubleRightIcon;
