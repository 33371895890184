import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@chakra-ui/react';
import {
  PDFWorker,
  getDocument,
  GlobalWorkerOptions,
  version as pdfjsVersion
} from 'pdfjs-dist/lib/pdf';
import { GrabToPan } from 'pdfjs-dist/lib/web/grab_to_pan.js';
import { isEmpty } from 'lodash';

GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsVersion}/pdf.worker.min.js`;

export const PDFDocumentContext = createContext();
export const PDFDocumentProvider = ({ children }) => {
  const $pages = useRef(null);
  const current = useSelector(state => state.viewer.current);
  const scale = useSelector(state => state.viewer.scale);
  const scale_mode = useSelector(state => state.viewer.scale_mode);
  const totalPages = useSelector(state => state.documents.data.totalPages);

  // local state
  // const [ready, setReady] = useState(false);
  // const [loaded, setLoaded] = useState(false);
  // const [loadedPages, setLoadedPages] = useState(0);
  const [offset] = useState(30);
  const [container, setContainer] = useState(undefined);
  const [proxies, setProxies] = useState([]);
  const [pagesProxy, setPagesProxy] = useState([]);
  const [setPagesView] = useState([]);
  const [viewports, setViewports] = useState([]);
  const [currentScale, setCurrentScale] = useState(1);
  const [handTool, setHandTool] = useState();
  const [preventRender, setPreventRender] = useState(false);
  const [firstVisiblePage, setFirstVisiblePage] = useState(null);
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  /**
   * bir veya daha fazla pdf dökümanının
   * proxy bilgilerini getirir.
   *
   * @param  {number} files — dökümanların url listesi
   * @return {Promise}
   */
  async function fetchDocumentsProxy(files) {
    const worker = new PDFWorker({ name: 'wikilala-worker' });

    // dosyaları teker teker yükleyelim.
    const proxies = await files.map(async (file) => {
      const loadingTask = await getDocument({
        url: file,
        worker: worker,
        httpHeaders: {
          'x-ms-version': '2011-08-18'
        },
        disableAutoFetch: true,
        disableStream: true
      });

      return await loadingTask.promise;
    });

    const proxiesPromise = await Promise.all(proxies);

    setProxies(proxiesPromise);
    return proxiesPromise;
  }

  const _fetchDocumentsPage = async (number) => {
    if (! proxies.length) return null;

    let proxyPromise = Promise.resolve({});
    if (proxies.length === 1) {
      proxyPromise = proxies[0].getPage(number);
    } else if (proxies.length > 1) {
      proxyPromise = proxies[number - 1].getPage(1);
    }

    const proxy = await proxyPromise;
    setPagesProxy(previousState => previousState.map((s, i) => {
      if (i === parseInt(number - 1)) {
        return proxy;
      }

      return s;
    }));

    return proxy;
  };

  const getPage = async (number) => {
    if (! pagesProxy.length) return;

    if (isEmpty(pagesProxy[number - 1])) {
      return _fetchDocumentsPage(number);
    }

    return Promise.resolve(pagesProxy[number - 1]);
  };

  const _fetchViewport = async (number, scale, scaleMode, previousViewport) => {
    const rect = container.getBoundingClientRect();
    const containerHeight = rect.height || window.innerHeight;
    const containerWidth = rect.width || window.innerWidth;
    const offset = 116;

    console.log('fetching viewport', number);

    return getPage(number).then(page => {

      // eslint-disable-next-line no-unused-vars
      const [xMin, yMin, xMax, yMax] = page.view;

      // scale types
      const scaleModes = {};
      scaleModes['page-height'] = ((containerHeight - offset) / yMax) * scale;
      scaleModes['page-width'] = ((containerWidth - (offset / 2)) / xMax) * scale;
      scaleModes['page-fit'] = Math.min(
        scaleModes['page-width'],
        scaleModes['page-height']
      );

      // önceki scale bilgisi ile yeni scale bilgisi aynıysa 
      // gereksiz yere render yapmayalım.
      if (previousViewport && previousViewport.scale === scaleModes[scaleMode]) {
        return previousViewport;
      }

      const viewport = page.getViewport({
        scale: scaleModes[scaleMode]
      });

      setCurrentScale({ scale, scaleMode });
      setViewports(previousState => previousState.map((s, i) => {
        if (i === parseInt(number - 1)) {
          return viewport;
        }

        return s;
      }));

      return viewport;
    });
  };

  const getViewport = useCallback(async (number, scale = 1, scaleMode = 'page-height') => {
    if (! viewports.length) return Promise.reject('render does not find');
    if (preventRender) return Promise.reject(number + ' page render prevented');

    // scale_mode, page-width'ten page-height'e ilk geçtiğinde
    // scale bilgisini 1 olarak gönderelim.
    if (currentScale.scaleMode === 'page-height' && scaleMode === 'page-width') {
      scale = 1;
    }

    // mobil'de scaleMode bilgisini page-width yapalım.
    if (isMobile) {
      scaleMode = 'page-width';
    }

    return _fetchViewport(number, scale, scaleMode, viewports[number - 1]);

    // return Promise.resolve(viewports[number - 1]);
  }, [pagesProxy, preventRender, viewports, currentScale, container, isMobile]);

  useEffect(() => {
    setPagesProxy(Array(totalPages).fill({}));
    setViewports(Array(totalPages).fill({}));
  }, [totalPages]);

  useEffect(() => {
    if (! viewports.length) return;

    // scale bilgisi değiştiğinde yeni scale bilgisine göre
    // viewport hazırlayalım.
    pagesProxy.map((page, pageIndex) => {
      if (isEmpty(page)) return page;

      getViewport(pageIndex + 1, scale, scale_mode)
        .then(viewport => {

          // aktif sayfayı kullanarak 
          // scroll'u yeniden konumlandıralım.
          if (viewport && pageIndex + 1 === parseInt(firstVisiblePage.dataset.pageNumber)) {
            const previousViewport = viewports[pageIndex];
            if (! previousViewport) return;

            // offset bilgisi alınırken margin değerini çıkaralım.
            const getPageOffset = (page) => {
              const pageStyle = window.getComputedStyle(firstVisiblePage);

              return {
                y: page.offsetTop - parseInt(pageStyle.marginTop),
                x: page.offsetLeft - parseInt(pageStyle.marginLeft)
              };
            };
            
            // önceki scroll bilgisini yeni 
            // viewport'a göre yeniden hesaplayalım.
            const [prevScrollX, prevScrollY] = previousViewport.convertToPdfPoint(
              container.scrollLeft - getPageOffset(firstVisiblePage).x, 
              container.scrollTop - getPageOffset(firstVisiblePage).y
            );
            const [scrollX, scrollY] = viewport.convertToViewportPoint(prevScrollX, prevScrollY);

            // hesaplanmış scroll'a konumlayalım.
            setTimeout(() => {
              container.scrollTo({
                left: getPageOffset(firstVisiblePage).x + Math.round(scrollX),
                top: getPageOffset(firstVisiblePage).y + Math.round(scrollY)
              });
            }, 10);
          }
        });
    });
  }, [scale, scale_mode]);

  /**
   * grab işaretini aktif eder.
   *
   */
  const initHandTool = (container) => {
    if (! container) return;

    const handTool = new GrabToPan({ element: container });

    handTool.activate();

    setHandTool(handTool);
    return handTool;
  };

  /**
   * kapsayıcıyı tam ekran moduna geçirir.
   *
   */
  const requestFullscreen = useCallback(() => {
    if (! container) return;

    // TODO: tam ekrandan çıkıldığında
    // viewport yeniden konumlandırılacak.
    // scroll ile gezinme iptal edilecek.
    container.requestFullscreen();
  }, [container]);

  /**
   * hedef sayfanın koordinatını bulup
   * kapsayıcı scroll'unu konumlandırır.
   *
   * @param {number} pageNumber — gidilecek sayfa numarası
   */
  const goToPage = (pageNumber) => {
    if (! container) return;
    if (! viewports.length) return;
    if (! pageNumber || pageNumber > totalPages) return;

    const pageDiv = document.getElementById('page-' + pageNumber);
    if (! pageDiv) return;

    container.scrollTo(0, pageDiv.offsetTop - offset);
  };

  const prevPage = () => goToPage(current - 1);
  const nextPage = () => goToPage(current + 1);

  return (
    <PDFDocumentContext.Provider value={{
      container,
      fetchDocumentsProxy,
      getViewport,
      getPage,
      goToPage,
      handTool,
      initHandTool,
      // loaded,
      nextPage,
      offset,
      // loadedPages,
      $pages,
      pagesProxy,
      prevPage,
      preventRender,
      proxies,
      // ready,
      requestFullscreen,
      setContainer,
      setFirstVisiblePage,
      // setLoaded,
      setPagesProxy,
      setPagesView,
      setPreventRender,
      viewports
    }}>
      {children}
    </PDFDocumentContext.Provider>
  );
};

export const useViewer = (callback) => {
  const state = useContext(PDFDocumentContext);

  if (callback) {
    callback(state);
  }

  return state;
};

PDFDocumentProvider.propTypes = {
  children: PropTypes.element.isRequired
};
