import * as React from 'react';

const SvgListHeadingIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    viewBox="0 0 18 18"
    {...props}
  >
    <g id="Group_57325" data-name="Group 57325" transform="translate(-6 -6)">
      <path
        id="Path_86567"
        data-name="Path 86567"
        d="M6.9,7.8H15A.9.9,0,0,0,15,6H6.9a.9.9,0,0,0,0,1.8Z"
        transform="translate(0 0)"
        fill="currentColor"
      />
      <path
        id="Path_86568"
        data-name="Path 86568"
        d="M6.9,13.8H23.1a.9.9,0,0,0,0-1.8H6.9a.9.9,0,1,0,0,1.8Z"
        transform="translate(0 -0.6)"
        fill="currentColor"
      />
      <path
        id="Path_86569"
        data-name="Path 86569"
        d="M23.1,18H6.9a.9.9,0,0,0,0,1.8H23.1a.9.9,0,0,0,0-1.8Z"
        transform="translate(0 -1.2)"
        fill="currentColor"
      />
      <path
        id="Path_86570"
        data-name="Path 86570"
        d="M23.1,24H6.9a.9.9,0,1,0,0,1.8H23.1a.9.9,0,0,0,0-1.8Z"
        transform="translate(0 -1.8)"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default SvgListHeadingIcon;
