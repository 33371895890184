import i18n from 'i18n';
import { SET_SCALE } from './actionTypes';
import { createStandaloneToast } from '@chakra-ui/toast';

const MAX_SCALE = 10.0;
const DEFAULT_SCALE_DELTA = 1.1;

export function increaseScale(steps = 1) {
  const { toast } = createStandaloneToast();

  return (dispatch, getState) => {
    const { viewer } = getState();

    // steps parametresine göre
    // yakınlaştırmayı ayarlayalım.
    let newScale = viewer.scale;
    do {
      newScale = (newScale * DEFAULT_SCALE_DELTA).toFixed(2);
      newScale = Math.ceil(newScale * 10) / 10;
      newScale = Math.min(MAX_SCALE, newScale);
    } while (--steps > 0 && newScale < MAX_SCALE);

    // yakınlaştırma sınırına ulaştığında uyarı verelim.
    if (newScale === MAX_SCALE) {
      const id = 'increaseScale';

      if (! toast.isActive(id)) {
        toast({
          id: id,
          title: i18n.t('toast.title.minZoom'),
          description: i18n.t('toast.message.minZoom'),
          status: 'warning',
          position: 'top',
          duration: 9000,
          isClosable: true
        });
      }
      return;
    }

    dispatch({ type: SET_SCALE, payload: newScale });
  };
}
