import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { Box, useMediaQuery } from '@chakra-ui/react';

import { useViewer } from 'components/PDFDocument';

// Actions
import { addThumbnail } from 'store/actions';

// Components
import Highlight from './Highlight';
import Result from './Result';

// UI
import styles from './PDFPage.module.scss';

const outputScale = 1;

// TEMP: mobil cihazlarda devicePixelRatio problemi çözüldükten sonra kullanılacak
// const outputScale = window.devicePixelRatio || 1;

const PDFPage = forwardRef(({ number, proxy, visible }, pageRef) => {
  const ref = useRef();
  const dispatch = useDispatch();
  const initialPage = useSelector(state => state.viewer.initial_page);
  const current = useSelector(state => state.viewer.current);
  const scale = useSelector(state => state.viewer.scale);
  const scale_mode = useSelector(state => state.viewer.scale_mode);
  const thumbnail = useSelector(state => state.thumbnails.find(t => 
    t.page === number
  ));
  const {
    container,
    getViewport,
    viewports,
    setFirstVisiblePage,
    setPreventRender
  } = useViewer();

  // pass ref to the parent
  useImperativeHandle(pageRef, () => ({
    rect() {
      return ref.current.getBoundingClientRect();
    },
    focus() {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  }));

  // local state
  const [rendered, setRendered] = useState(false);
  const [pageReady, setPageReady] = useState(false);
  const [renderTask, setRenderTask] = useState();
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  // local state
  const canvasRef = useRef();
  const [viewport, setViewport] = useState({});
  
  useEffect(() => {
    if (! viewports.length) return;

    const currentViewport = viewports[number - 1];

    // önceki viewport varsa ve 
    // yeni viewport aynıysa güncelleme yapmayalım.
    if (viewport?.scale === currentViewport?.scale) {
      return;
    }
  
    // render bilgisini
    // sıfırlayıp kaydedelim.
    setRendered(false);
    setViewport(currentViewport);
  }, [viewports]);
  
  useEffect(() => {
    if (rendered) return;
    if (isEmpty(viewport)) return;

    // devam eden render işlemi
    // varsa iptal eder.
    if (renderTask) {
      renderTask.cancel();
    }
  
    // variables
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    const transform = outputScale !== 1
      ? [outputScale, 0, 0, outputScale, 0, 0]
      : null;
  
    const task = proxy.render({
      canvasContext: context,
      transform: transform,
      viewport: viewport,
      background: 'transparent'
    });
  
    // save state
    setRenderTask(task);
    task.promise.then(() => {
      setRendered(true);
      setRenderTask(undefined);

      // 100ms sonra thumbnail'i ekleyelim.
      setTimeout(() => {
        dispatch(addThumbnail(
          number, 
          canvas.toDataURL('image/webp', 0.1)
        ));
      }, 100);
    }).catch(() => {});
  }, [viewport, rendered]);

  useEffect(() => {
    if (! rendered) return;
    if (! pageReady) {
      setTimeout(() => {
        setPageReady(true);
      }, 300);
    }
  }, [rendered]);

  useEffect(() => {
    if (! container) return;
    if (initialPage === number) {
      const $page = ref.current;

      // scroll aktif sayfaya konumlanmışsa scroll 
      // event'ini tetikleyerek görünen sayfaları hesaplayalım.
      if (container.scrollTop !== $page.offsetTop - 30) {
        container.scrollTo(0, $page.offsetTop - 30);
      } else {
        container.dispatchEvent(new Event('scroll'));
      }
      
      setPreventRender(false);
    }
  }, [initialPage, container]);

  useEffect(() => {
    if (rendered || !isEmpty(viewport)) return;
    if (! visible) return;

    if (current === parseInt(number)) {
      setFirstVisiblePage(ref.current);
    }

    getViewport(number, scale, scale_mode);
  }, [visible]);

  if (isEmpty(viewport)) {
    return (
      <Box
        className={styles.self}
        data-current={current === number}
        data-visible={visible}
        data-rendered={false}
        data-page-number={number}
        style={{
          height: (window.innerHeight - 116) * scale,
          width: (isMobile || scale_mode === 'page-width')
            ? 'calc(100% - 58px)'
            : (595 * scale) + 'px'
        }}
        id={`page-${number}`}
        ref={ref} />
    );
  }

  return (
    <Box
      className={styles.self}
      data-current={current === number}
      data-visible={visible}
      data-rendered={rendered}
      data-page-number={number}
      style={{
        height: Math.floor(viewport.height * outputScale),
        width: Math.floor(viewport.width * outputScale)
      }}
      id={`page-${number}`}
      ref={ref}>
      {(thumbnail && !rendered) && (
        <img src={thumbnail.image} className={styles.thumbnail} alt="" />
      )}
      <canvas
        className={pageReady === false
          ? styles.fadein
          : ''
        }
        height={Math.floor(viewport.height * outputScale)}
        width={Math.floor(viewport.width * outputScale)}
        ref={canvasRef} />
      <Highlight pagenumber={number} viewport={viewport} />
      <Result pagenumber={number} />
    </Box>
  );
});

PDFPage.displayName = 'PDFPage';
PDFPage.propTypes = {
  number: PropTypes.number.isRequired,
  proxy: PropTypes.object,
  visible: PropTypes.bool
};

export default PDFPage;
