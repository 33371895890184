import React from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Outlet
} from 'react-router-dom';
import i18n from 'i18n';

// Component
import App from 'app';
import Home from 'routes/Home';
import Viewer from 'routes/Viewer';
import Catalog from 'routes/Catalog';
import Fallback from 'routes/Fallback';

const routes = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route index element={<Home />} />
      <Route path="/:filename-:id/" element={<Outlet />}>
        <Route index element={<Viewer />} />

        {/* Turkish Lang Routes */}
        <Route 
          path="sayfa/:pagenumber" 
          element={<Viewer />}
          loader={() => i18n.changeLanguage('tr')} />
        <Route 
          path="ara" 
          element={<Viewer />}
          loader={() => i18n.changeLanguage('tr')} />
        <Route 
          path="katalog"
          element={<Catalog />}
          loader={() => i18n.changeLanguage('tr')} />

        {/* English Lang Routes */}
        <Route 
          path="page/:pagenumber" 
          element={<Viewer />}
          loader={() => i18n.changeLanguage('en')} />
        <Route 
          path="search" 
          element={<Viewer />}
          loader={() => i18n.changeLanguage('en')} />
        <Route 
          path="catalog" 
          element={<Catalog />}
          loader={() => i18n.changeLanguage('en')} />
      </Route>
    </Route>
  )
);

export { Fallback };
export default routes;
