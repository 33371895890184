import React, { useEffect, useState, Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Flex, Tooltip } from '@chakra-ui/react';

// Components
import i18n from 'i18n';

// UI
import styles from './Highlight.module.scss';
import {
  PinFastenerIcon,
  PinTitleIcon,
  PinDirectoryIcon,
  PinSubjectIcon,
  PinNoteIcon
} from 'components/Icons';

function Highlight({ pagenumber, viewport }) {
  const selectedRef = useCallback((node) => {
    if (! node) return;

    // scroll to selected polygon
    setTimeout(() => {
      node.scrollIntoView({ behavior: 'smooth', block: 'center'});
    }, 100);
  }, []);
  const pin = useSelector(state => state.pin);
  const pins = useSelector(state => state.pins);
  const hide_pins = useSelector(state => state.annonation.hide_pins);
  const hide_translates = useSelector(state => state.annonation.hide_translates);

  // local state
  const [data, setData] = useState([]);

  useEffect(() => {
    if (! pins.data.length) return setData([]);

    setData(
      pins.data.filter(h => h.page === pagenumber)
    );
  }, [pins.data]);

  // veri yoksa
  if (! data.length) return <Fragment />;

  return data.map((h, i) => {
    const selected = h.id === pin.id;

    // pin/çeviri gizlenmişse
    if (h.type === 'translate' && hide_translates) return <Fragment key={i} />;
    if (h.type !== 'translate' && hide_pins) return <Fragment key={i} />;

    // pin düzenleme açıksa gizleyelim.
    if (selected && pin.editing) return <Fragment key={i} />;

    // çeviriyse rectangle çizelim.
    if (h.position.length === 4) {
      const [left, top] = viewport.convertToViewportPoint(h.position[0], h.position[1]);
      const [width, height] = viewport.convertToViewportPoint(h.position[2], h.position[3]);
      const tooltipText = h.content || h.title || h.titleEn || h.titleAr;

      return (
        <Tooltip label={`${i18n.t('pin.type.'+ h.type +'.title')} : ${tooltipText}`} placement="auto" hasArrow key={i}>
          <div className={styles.translate}
            data-focus={selected}
            style={{
              left: left+'px',
              top: top+'px',
              width: Math.abs(width - left)+'px',
              height: Math.abs(height - top)+'px'
            }}
            ref={selected
              ? selectedRef
              : undefined
            } />
        </Tooltip>
      );
    } else {
      const [x, y] = viewport.convertToViewportPoint(...h.position);
      const scale = viewport.scale * 2;

      // pin size by scale
      const pinSize = parseInt(40 * scale);
      const pinShadowSize = parseInt(3 * scale);
      const pinIconHeight = parseInt(11 * scale);
      const pinIconWidth = parseInt(10 * scale);

      return (
        <div
          className={styles.self}
          style={{
            left: x+'px',
            top: y+'px'
          }}
          data-focus={selected}
          ref={selected
            ? selectedRef
            : undefined
          }
          key={i}>
          <Tooltip label={h.title || h.content} placement="auto" hasArrow>
            <Flex backgroundColor="#0027FF" color="white"
              alignItems="center"
              outline={`${pinShadowSize}px solid rgba(0, 39, 255, .2)`}
              justifyContent="center"
              borderRadius="50"
              data-type={h.type}
              ml={-(pinSize / 2) +'px'}
              mt={-(pinSize / 2) +'px'}
              height={pinSize + 'px'}
              width={pinSize + 'px'}>
              {h.type === 'note'
                ? <PinNoteIcon height={pinIconHeight} width={pinIconWidth} />
                : h.type === 'title'
                  ? <PinTitleIcon style={{ fill: 'red' }} height={pinIconHeight} width={pinIconWidth} />
                  : h.type === 'index'
                    ? <PinDirectoryIcon height={pinIconHeight} width={pinIconWidth} />
                    : h.type === 'subject'
                      ? <PinSubjectIcon height={pinIconHeight} width={pinIconWidth} />
                      : <PinFastenerIcon />
              }
            </Flex>
          </Tooltip>
        </div>
      );
    }
  });
}

Highlight.propTypes = {
  pagenumber: PropTypes.number.isRequired,
  viewport: PropTypes.object.isRequired
};

export default Highlight;
