import { SELECT_PIN, DESELECT_PIN } from 'store/actions/actionTypes';

const initialState = {};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_PIN:
      return action.payload;
    case DESELECT_PIN:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
