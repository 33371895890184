import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';

// Actions
import { deselectPin } from 'store/actions';

// Context
import { useViewer } from 'components/PDFDocument';
import ContextMenuProvider, { ContextMenuContext } from 'components/ContextMenu';
import ContextMenuBody from 'components/ContextMenu/ContextMenuBody';

// Components
import i18n from 'i18n';
import PDFPages from 'components/PDFDocument/PDFPages';
import PinMarker from 'components/PDFDocument/PinMarker';
import SelectionArea from 'components/PDFDocument/SelectionArea';

// Context Menu components
import HandTool from 'components/ContextMenu/HandTool';
import AddTitle from 'components/ContextMenu/AddTitle/AddTitle';
import AddDirectory from 'components/ContextMenu/AddDirectory';
import AddSubject from 'components/ContextMenu/AddSubject';
import AddNote from 'components/ContextMenu/AddNote';
import AddTranslate from 'components/ContextMenu/AddTranslate';

// UI
import styles from './PDFDocument.module.scss';
import stylesPDFPage from './PDFPage/PDFPage.module.scss';
import stylesSelectionArea from 'components/PDFDocument/SelectionArea/index.module.scss';

function PDFDocument() {
  const ref = useRef();
  const dispatch = useDispatch();
  const current = useSelector(state => state.viewer.current);
  const pin = useSelector(state => state.pin);
  const navigate = useNavigate();
  const { filename, id } = useParams();
  const {
    container,
    handTool,
    preventRender,
    viewports
  } = useViewer();
  const {
    setPosition,
    openMenu
  } = useContext(ContextMenuContext);

  // local state
  const [pageView, setPageView] = useState({});

  // SelectionArea state
  const [selectionOpen, setSelectionOpen] = useState(false);
  const [selectionLock, setSelectionLock] = useState(false);
  const [selectionStart, setSelectionStart] = useState({});
  const [selectionEnd, setSelectionEnd] = useState({});
  const [selectionType, setSelectionType] = useState('');
  const [selectionEditing, setSelectionEditing] = useState(false);

  // PinMarker state
  const [pinMarkerOpen, setPinMarkerOpen] = useState(false);
  const [pinMarkerEditing, setPinMarkerEditing] = useState(false);
  const [pinMarkerType, setPinMarkerType] = useState('');
  const [pinMarkerPosition, setPinMarkerPosition] = useState({});

  useEffect(() => {
    if (! current) return;

    // render engelleyici varsa işlem tamamlanana
    // kadar yönlendirme yapmayalım.
    if (! preventRender) {
      navigate(`/${filename}-${id}/${i18n.t('page.slug')}/${current}`, { replace: true });
    }
  }, [current]);

  // select pin
  useEffect(() => {
    if (isEmpty(pin) || pin.editing === false) return;
    if (isEmpty(viewports)) return;

    // seçilen pin'in sayfa bilgisini alalım.
    // TEMP
    const pageView = {
      div: document.getElementById('page-' + (pin.page)),
      pdfPage: { pageNumber: pin.page },
      viewport: viewports[pin.page - 1]
    };

    const viewport = pageView.viewport;

    // düzenleme ekranı için offset tanımlayalım.
    const addOffset = (x, y) => {
      const left = x + pageView.div.offsetLeft;
      const top = y + pageView.div.offsetTop;

      return {
        x: left,
        y: top
      };
    };

    // düzenlenecek pin'in sayfa bilgisini tanılmayalım.
    setPageView(pageView);

    // çeviri seçilmişse seçim alanını aktif edelim.
    if (pin.position.length === 4) {
      const [
        startX,
        startY,
        endX,
        endY
      ] = viewport.convertToViewportRectangle(pin.position);

      // düzenleme için parametreleri tanımlayalım.
      activateSelection(pin.type);
      setSelectionLock(true);
      setSelectionEditing(true);
      setSelectionStart(addOffset(startX, startY));
      setSelectionEnd(addOffset(endX, endY));
    } else {
      const [positionX, positionY] = viewport.convertToViewportPoint(...pin.position);

      // diğer pin tipleri seçilmişse
      // pin işaretlemesini aktif edelim.
      activatePinMarker(pin.type);
      setPinMarkerEditing(true);
      setPinMarkerPosition(addOffset(positionX, positionY));
    }
  }, [pin, viewports]);

  const handleContextMenu = (e) => {
    e.preventDefault();

    // sadece wikilala viewer'da sağ tık menüyü açtıralım.
    if (process.env.REACT_APP_TYPE === 'viewer') {

      // seçim alanı ve işaretlemeyi sıfırlayalım.
      cancelPinMarker();
      cancelSelection();

      // koordinatı kaydedip context menüyü açalım.
      setPosition({
        x: e.clientX,
        y: e.clientY
      });
      openMenu();
    }
  };

  const containerCoords = (clientX, clientY) => {
    const {left, top} = container.getBoundingClientRect();

    return {
      x: clientX - left + container.scrollLeft - window.scrollX,
      y: clientY - top + container.scrollTop - window.scrollY
    };
  };

  const handleMouseUp = (e) => {
    if (isEmpty(selectionStart)) return;
    if (isEmpty(selectionEnd)) return setSelectionStart({});
    if (selectionLock) return;

    // alan seçimi aktif değilse veya
    // seçilen alan hedefin dışındaysa
    // mevcut seçimi temizleyelim.
    if (! selectionOpen || (! e.target.closest(`.${stylesPDFPage.self}`) && e.target.className !== stylesSelectionArea.self)) {
      setSelectionLock(false);
      setSelectionStart({});
      setSelectionEnd({});
      return;
    }

    return setSelectionLock(true);
  };

  const handleMouseDown = (e) => {
    if (! e.target.closest(`.${stylesPDFPage.self}`)) return;

    // pin işaretleyicisi aktifse
    // PinMarker component'i için koordinat hazırlayalım.
    if (pinMarkerOpen) {
      const page = e.target.closest(`.${stylesPDFPage.self}`);

      // pin işaretleyicisini başlatalım.
      setPageView({
        div: page,
        pdfPage: { pageNumber: page.dataset.pageNumber },
        viewport: viewports[page.dataset.pageNumber - 1]
      });
      setPinMarkerPosition(containerCoords(e.clientX, e.clientY));
      return;
    }

    // alan seçimi aktif değilse
    // mevcut seçimi iptal edelim.
    if (! selectionOpen) {
      setSelectionLock(false);
      setSelectionStart({});
      setSelectionEnd({});
      return;
    }

    // alan seçimi kilitliyse
    // tekrar seçim için kiliti kaldıralım.
    if (selectionLock) {
      setSelectionLock(false);
      setSelectionEnd({});
    }

    // seçilen alanın sayfa node'unu alalım.
    const page = e.target.closest(`.${stylesPDFPage.self}`);

    // alan seçimini başlatalım.
    // TODO: bu değişkenlerin hepsini tanımlamaya gerek olmayabilir.
    setPageView({
      div: page,
      pdfPage: { pageNumber: page.dataset.pageNumber },
      viewport: viewports[page.dataset.pageNumber - 1]
    });
    setSelectionStart(containerCoords(e.clientX, e.clientY));
  };

  const handleMouseMove = (e) => {
    if (isEmpty(selectionStart)) return;
    if (selectionLock) return;

    // alan seçimi aktif değilse
    // mevcut seçimi iptal edelim.
    if (! selectionOpen) {
      setSelectionLock(false);
      setSelectionStart({});
      setSelectionEnd({});
      return;
    }

    // seçim alanı sayfa dışındaysa veya kilitliyse
    // koordinat kaydetmeyelim.
    if (! e.target.closest(`.${stylesPDFPage.self}`) && e.target.className !== stylesSelectionArea.self) return;
    if (selectionLock) return;

    // anlık koordinatı kaydedelim.
    setSelectionEnd(containerCoords(e.clientX, e.clientY));
  };

  const activateSelection = (type) => {
    handTool.deactivate();
    setSelectionOpen(true);
    setSelectionType(type);

    container.classList.toggle('selection-cursor');
  };

  const cancelSelection = () => {
    handTool.activate();
    container.classList.remove('selection-cursor');

    // selection bilgilerini temizleyelim.
    setSelectionOpen(false);
    setSelectionLock(false);
    setSelectionType('');
    setSelectionEditing(false);
    setSelectionStart({});
    setSelectionEnd({});

    // pin seçiliyse seçimi iptal edelim.
    if (! isEmpty(pin)) {
      dispatch(deselectPin());
    }
  };

  const activatePinMarker = (type) => {
    handTool.deactivate();
    setPinMarkerOpen(true);
    setPinMarkerType(type);

    container.classList.toggle('pin-cursor');
  };

  const cancelPinMarker = () => {
    handTool.activate();
    setPinMarkerOpen(false);
    setPinMarkerEditing(false);
    setPinMarkerType('');
    setPinMarkerPosition({});

    container.classList.remove('pin-cursor');

    // pin seçiliyse seçimi iptal edelim.
    if (! isEmpty(pin)) {
      dispatch(deselectPin());
    }
  };

  return (
    <div
      className={styles.self}
      onContextMenu={handleContextMenu}
      ref={ref}>
      <PDFPages
        onMouseUp={handleMouseUp}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}>
        <Fragment>
          <PinMarker
            page={pageView}
            type={pinMarkerType}
            position={pinMarkerPosition}
            editing={pinMarkerEditing}
            onClear={cancelPinMarker} />
          <SelectionArea
            page={pageView}
            start={selectionStart}
            end={selectionEnd}
            type={selectionType}
            locked={selectionLock}
            editing={selectionEditing}
            onClear={cancelSelection} />
        </Fragment>
      </PDFPages>
      <ContextMenuBody>
        <Fragment>
          <HandTool />
          <AddTitle onClick={activateSelection} />
          <AddDirectory onClick={activateSelection} />
          <AddSubject onClick={activatePinMarker} />
          <AddNote onClick={activatePinMarker} />
          <AddTranslate onClick={activateSelection} />
        </Fragment>
      </ContextMenuBody>
    </div>
  );
}

function PDFDocumentWithContext(props) {
  return (
    <ContextMenuProvider>
      <PDFDocument {...props} />
    </ContextMenuProvider>
  );
}

export default PDFDocumentWithContext;
