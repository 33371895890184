import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// Context
import { useViewer } from 'components/PDFDocument';
import { setCurrentPage } from 'store/actions';

// Components
import PDFPage from 'components/PDFDocument/PDFPage';

// UI
import styles from './PDFPages.module.scss';
import { isEqual } from 'lodash';

function PDFPages({ children, ...props }) {
  const ref = useRef();
  const dispatch = useDispatch();
  const current = useSelector(state => state.viewer.current);
  const effects = useSelector(state => state.viewer.effects);
  const {
    $pages,
    initHandTool,
    setContainer,
    pagesProxy,
    preventRender
  } = useViewer();

  // local state
  const [visiblePages, setVisiblePages] = useState(Array(pagesProxy.length).fill(false));

  const getFilterEffects = useMemo(() => {
    return Object.keys(effects).map(name => effects[name]
      ? `${name}(${effects[name]})`
      : null
    ).join(' ');
  }, [effects]);

  const getPagesRef = () => {
    if (! $pages.current) {
      $pages.current = new Map();
    }

    return $pages.current;
  };

  const handleScroll = useCallback(() => {
    const offset = 30;
    const viewHeight = Math.max(
      window.innerHeight,
      document.documentElement.clientHeight
    );

    // sayfa ref'leri varsa hangi sayfaların 
    // göründüğü bilgisini hesaplayalım
    if ($pages.current.size > 0) {
      const calculatedVisiblePages = Array(pagesProxy.length).fill(false);

      // sayfaların görünüp görünmediğini
      // teker teker kontrol edelim.
      $pages.current.forEach((node, key) => {
        if (! node) return;

        const rect = node.rect();
        if (! rect) return;

        // aktif olması gereken sayfayı belirleyelim.
        if ((rect.bottom - (viewHeight / 2)) > 0 && (rect.top - (viewHeight / 2)) <= offset) {

          // mevcut sayfadan farklıysa
          // ve render engelleyici yoksa kaydedelim.
          if (current !== key && ! preventRender) {
            dispatch(setCurrentPage(key));
          }
        }

        // ekranda görünen sayfaları kaydedelim.
        if (rect.bottom > 0 && (rect.top - viewHeight) <= offset) {
          calculatedVisiblePages[key - 1] = true;
        }
      });

      // hesaplanmış sayfalar mevcut görünen 
      // sayfalardan farklıysa kaydedelim.
      if (! isEqual(visiblePages, calculatedVisiblePages)) {
        return setVisiblePages(calculatedVisiblePages);
      }
    }
  }, [visiblePages, current, $pages, preventRender]);

  useEffect(() => {
    const node = ref.current;

    // container'ı tanımlayalım
    setContainer(node);
    initHandTool(node);
  }, [ref]);

  return (
    <div
      className={styles.self}
      {...props}
      onScroll={handleScroll}
      style={{ filter: getFilterEffects }}
      ref={ref}>
      {pagesProxy.map((proxy, i) => {
        const pageNumber = i + 1;

        return (
          <PDFPage number={pageNumber} 
            proxy={proxy} 
            visible={visiblePages[i]} 
            ref={(node) => {
              const map = getPagesRef();

              if (node) {
                map.set(pageNumber, node);
              } else {
                map.delete(pageNumber);
              }
            }}
            key={i} />
        );
      })}
      {children}
    </div>
  );
}

PDFPages.propTypes = {
  children: PropTypes.element
};

export default PDFPages;
