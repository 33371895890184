import * as React from 'react';

const SvgFolderIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24.007}
    height={18.5}
    viewBox="0 0 24.007 18.5"
    {...props}
  >
    <path
      id="Path_103814"
      data-name="Path 103814"
      d="M121.558,106.4h4.225a1.161,1.161,0,0,1,.743.269l1.338,1.115a.29.29,0,0,0,.186.067h12.088a1.161,1.161,0,0,1,1.161,1.161v2.613a.29.29,0,0,0,.29.29h1.161a1.153,1.153,0,0,1,1.123,1.414L141.508,123.5a1.161,1.161,0,0,1-1.131.9H121.558a1.161,1.161,0,0,1-1.161-1.161V107.556a1.161,1.161,0,0,1,1.161-1.161Zm18.58,5.226V109.3a.29.29,0,0,0-.29-.29h-11.9a1.162,1.162,0,0,1-.743-.269l-1.338-1.115a.29.29,0,0,0-.186-.067h-3.829a.29.29,0,0,0-.29.29v12.109a.087.087,0,0,0,.172.02l1.653-7.165a1.161,1.161,0,0,1,1.132-.9h15.334a.29.29,0,0,0,.29-.29ZM122.5,123.234h17.643a.29.29,0,0,0,.283-.225l2.228-9.581a.291.291,0,0,0-.217-.349.3.3,0,0,0-.066-.007h-17.43a.58.58,0,0,0-.566.45l-2.159,9.357a.29.29,0,0,0,.218.348.283.283,0,0,0,.065.007Z"
      transform="translate(-120.146 -106.145)"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.5}
    />
  </svg>
);

export default SvgFolderIcon;
