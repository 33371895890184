import React, { createContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDisclosure } from '@chakra-ui/react';

export const ContextMenuContext = createContext();
export const ContextMenuProvider = ({ children }) => {
  const { isOpen, onClose: closeMenu, onOpen: openMenu } = useDisclosure();
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const menuRef = useRef(null);
  
  return (
    <ContextMenuContext.Provider
      value={{
        isOpen,
        closeMenu,
        openMenu,
        menuRef,
        position,
        setPosition,
      }}>
      {children}
    </ContextMenuContext.Provider>
  );
};

ContextMenuProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export default ContextMenuProvider;
