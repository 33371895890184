import React from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgress,
  CircularProgressLabel,
  useColorModeValue
} from '@chakra-ui/react';
import Logo from 'components/Logo';

// UI
import styles from './Loading.module.scss';

function Loading({ progress, ...props }) {
  const progressColor = useColorModeValue('white', 'gray.800');
  const trackColor = useColorModeValue('#e7e9ff', '#2d3952');

  return (
    <CircularProgress value={progress}
      size={100}
      className={(!progress && !props.isIndeterminate) && styles.fadein}
      thickness="3px"
      bg={progressColor}
      borderRadius="50%"
      boxShadow="md"
      color="blue.400"
      mx="auto"
      trackColor={trackColor}
      {...props}>
      <CircularProgressLabel>
        <Logo
          height="33"
          width="50"
          style={{ margin: 'auto' }} />
      </CircularProgressLabel>
    </CircularProgress>
  );
}

Loading.propTypes = {
  progress: PropTypes.number,
  isIndeterminate: PropTypes.bool
};

export default Loading;
