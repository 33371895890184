import React, { Fragment } from 'react';
import { PropTypes } from 'prop-types';

// Components
import TitleForm from './TitleForm';
import DirectoryForm from './DirectoryForm';
import TranslateForm from './TranslateForm';

function GetSelectionForm({ type, children, ...props }) {
  if (type === 'title') {
    return <TitleForm {...props}>{children}</TitleForm>;
  } else if (type === 'index') {
    return <DirectoryForm {...props}>{children}</DirectoryForm>;
  } else if (type === 'translate') {
    return <TranslateForm {...props}>{children}</TranslateForm>;
  }

  return <Fragment />;
}

GetSelectionForm.propTypes = {
  type: PropTypes.string.isRequired,
  children: PropTypes.node
};

export default GetSelectionForm;