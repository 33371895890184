import * as React from 'react';

const SvgGrabIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18.357}
    height={19.365}
    viewBox="0 0 18.357 19.365"
    {...props}
  >
    <g
      id="Group_57324"
      data-name="Group 57324"
      transform="translate(-1386.804 -487.686)"
    >
      <path
        id="Path_102957"
        data-name="Path 102957"
        d="M11.817,22.566c-.354-.472-.708-1.3-1.417-2.361-.354-.59-1.417-1.771-1.771-2.243a1.4,1.4,0,0,1-.118-1.18,1.665,1.665,0,0,1,1.653-1.3,3.147,3.147,0,0,1,1.653.826c.236.236.59.708.826.944s.236.354.472.59c.236.354.354.59.236.118-.118-.59-.236-1.535-.472-2.479a9.576,9.576,0,0,0-.354-1.3c-.118-.59-.236-.944-.354-1.535-.118-.354-.236-1.3-.354-1.771a2.684,2.684,0,0,1,.354-2.125,1.449,1.449,0,0,1,1.535-.236,3.031,3.031,0,0,1,1.062,1.535,9.8,9.8,0,0,1,.59,2.361c.236,1.18.59,2.951.59,3.305a8.875,8.875,0,0,1,0-1.771A1.322,1.322,0,0,1,16.775,13a3.268,3.268,0,0,1,1.062-.118,2.57,2.57,0,0,1,.944.59,5.121,5.121,0,0,1,.472,2.125,10.63,10.63,0,0,1,.354-1.889,2.117,2.117,0,0,1,.826-.59,2.012,2.012,0,0,1,1.18,0,1.366,1.366,0,0,1,.826.59,10,10,0,0,1,.472,2.007,7.019,7.019,0,0,1,.354-.826,1.221,1.221,0,0,1,2.243.708v2.715a11.63,11.63,0,0,1-.236,2.007,7.163,7.163,0,0,1-.826,1.653A7.164,7.164,0,0,0,23.031,24.1a4.89,4.89,0,0,0-.118,1.18,6.525,6.525,0,0,0,.118,1.062,5.337,5.337,0,0,1-1.417,0,2.516,2.516,0,0,1-1.18-1.3.454.454,0,0,0-.826,0c-.236.472-.826,1.3-1.3,1.3-.826.118-2.479,0-3.659,0,0,0,.236-1.18-.236-1.653l-1.3-1.3Z"
        transform="translate(1379 480)"
        fill="currentColor"
        fillOpacity={0}
      />
      <path
        id="Path_102958"
        data-name="Path 102958"
        d="M11.817,22.566c-.354-.472-.708-1.3-1.417-2.361-.354-.59-1.417-1.771-1.771-2.243a1.4,1.4,0,0,1-.118-1.18,1.665,1.665,0,0,1,1.653-1.3,3.147,3.147,0,0,1,1.653.826c.236.236.59.708.826.944s.236.354.472.59c.236.354.354.59.236.118-.118-.59-.236-1.535-.472-2.479a9.576,9.576,0,0,0-.354-1.3c-.118-.59-.236-.944-.354-1.535-.118-.354-.236-1.3-.354-1.771a2.684,2.684,0,0,1,.354-2.125,1.449,1.449,0,0,1,1.535-.236,3.031,3.031,0,0,1,1.062,1.535,9.8,9.8,0,0,1,.59,2.361c.236,1.18.59,2.951.59,3.305a8.875,8.875,0,0,1,0-1.771A1.322,1.322,0,0,1,16.775,13a3.268,3.268,0,0,1,1.062-.118,2.57,2.57,0,0,1,.944.59,5.121,5.121,0,0,1,.472,2.125,10.63,10.63,0,0,1,.354-1.889,2.117,2.117,0,0,1,.826-.59,2.012,2.012,0,0,1,1.18,0,1.366,1.366,0,0,1,.826.59,10,10,0,0,1,.472,2.007,7.019,7.019,0,0,1,.354-.826,1.221,1.221,0,0,1,2.243.708v2.715a11.63,11.63,0,0,1-.236,2.007,7.163,7.163,0,0,1-.826,1.653A7.164,7.164,0,0,0,23.031,24.1a4.89,4.89,0,0,0-.118,1.18,6.525,6.525,0,0,0,.118,1.062,5.337,5.337,0,0,1-1.417,0,2.516,2.516,0,0,1-1.18-1.3.454.454,0,0,0-.826,0c-.236.472-.826,1.3-1.3,1.3-.826.118-2.479,0-3.659,0,0,0,.236-1.18-.236-1.653l-1.3-1.3Z"
        transform="translate(1379 480)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.3}
      />
      <line
        id="Line_6"
        data-name="Line 6"
        y1={4.014}
        transform="translate(1400.614 498.907)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth={1}
      />
      <line
        id="Line_7"
        data-name="Line 7"
        x1={0.118}
        y1={4.014}
        transform="translate(1398.136 498.907)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth={1}
      />
      <line
        id="Line_8"
        data-name="Line 8"
        y2={4.014}
        transform="translate(1395.893 498.907)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth={1}
      />
    </g>
  </svg>
);

export default SvgGrabIcon;
