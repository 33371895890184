import * as React from 'react';

const SvgNoteIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20.203}
    height={18.532}
    viewBox="0 0 20.203 18.532"
    {...props}
  >
    <g id="Layer_25" data-name="Layer 25" transform="translate(-2.384 -3.017)">
      <path
        id="Path_10"
        data-name="Path 10"
        d="M21.475,40.337H3.095a.461.461,0,0,0,0,.922h18.38a.461.461,0,0,0,0-.922Z"
        transform="translate(0 -19.96)"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={0.5}
      />
      <path
        id="Path_11"
        data-name="Path 11"
        d="M23.726,3.412a.462.462,0,0,0-.618.011l-9.465,8.865a.46.46,0,0,0-.146.336v4.357a.461.461,0,0,0,.461.461h4.557a.462.462,0,0,0,.331-.14L28.06,7.837a.461.461,0,0,0-.027-.669ZM18.32,16.52h-3.9v-3.7l9.015-8.443L27.06,7.543Z"
        transform="translate(-5.854 0)"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={0.5}
      />
    </g>
  </svg>
);

export default SvgNoteIcon;
