import * as React from 'react';

const SvgPinFastenerIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    width={32}
    height={32}
    style={{
      filter: 'drop-shadow(0px 0px 2px rgb(255 255 255 / 0.4))',
    }}
    {...props}
  >
    <g id="noun-pin-3187990">
      <path
        id="Path_103807"
        fill="#0027ff"
        d="m12.4 21.2l-10.4 10.4c-0.2 0.3-0.5 0.4-0.8 0.5-0.4 0-0.7-0.1-0.9-0.4-0.3-0.2-0.4-0.5-0.4-0.9 0.1-0.3 0.2-0.6 0.5-0.8l10.4-10.4z"
      />
      <path
        id="Path_103808"
        fill="#0027ff"
        d="m31.5 7.9c0.3 0.4 0.5 1 0.4 1.6 0 0.6-0.3 1.1-0.7 1.5-0.4 0.4-0.9 0.7-1.5 0.7-0.6 0.1-1.1-0.1-1.6-0.4q0 0-0.1 0 0-0.1 0-0.1-0.1 0-0.1 0.1 0 0 0 0l-4.1 4q-0.3 0.4-0.6 0.8-0.2 0.5-0.3 1 0 0.5 0.1 1 0.1 0.5 0.3 0.9 0.6 0.8 0.8 1.6 0.2 0.9 0.1 1.8 0 0.9-0.4 1.7-0.4 0.9-1 1.5l-1.4 1.4q-0.1 0.1-0.4 0.2-0.2 0.1-0.4 0.1-0.2 0-0.4-0.1-0.2-0.1-0.4-0.2l-14.8-14.8q-0.1-0.2-0.2-0.4-0.1-0.2-0.1-0.4 0-0.2 0.1-0.4 0.1-0.2 0.2-0.4l1.4-1.3q0.6-0.7 1.4-1.1 0.9-0.4 1.8-0.4 0.9-0.1 1.8 0.1 0.8 0.2 1.6 0.8 0.4 0.2 0.9 0.3 0.5 0.1 1 0.1 0.5-0.1 1-0.3 0.4-0.3 0.8-0.6l4-4.1q0.1 0 0.1-0.1 0 0 0 0 0-0.1 0-0.1 0 0-0.1 0c-0.3-0.5-0.4-1.1-0.4-1.7 0.1-0.5 0.3-1.1 0.7-1.5 0.4-0.4 1-0.6 1.5-0.7 0.6 0 1.2 0.1 1.7 0.5l7.3 7.3z"
      />
    </g>
  </svg>
);

export default SvgPinFastenerIcon;
