import * as React from 'react';

const SvgDirectoryIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={13.846}
    height={18}
    viewBox="0 0 13.846 18"
    {...props}
  >
    <g id="Group_57125" data-name="Group 57125" transform="translate(-65 -181)">
      <g
        id="Rectangle_14027"
        data-name="Rectangle 14027"
        transform="translate(65 181)"
        fill="#fff"
        fillOpacity={0}
        stroke="currentColor"
        strokeWidth={1.5}
      >
        <rect width={13.846} height={18} rx={4} stroke="none" />
        <rect
          x={0.75}
          y={0.75}
          width={12.346}
          height={16.5}
          rx={3.25}
          fill="none"
        />
      </g>
      <rect
        id="Rectangle_14028"
        data-name="Rectangle 14028"
        width={6.923}
        height={1.385}
        rx={0.692}
        transform="translate(68.462 185.28)"
        fill="currentColor"
      />
      <rect
        id="Rectangle_14029"
        data-name="Rectangle 14029"
        width={5.538}
        height={1.385}
        rx={0.692}
        transform="translate(69.154 188.049)"
        fill="currentColor"
      />
      <rect
        id="Rectangle_14030"
        data-name="Rectangle 14030"
        width={2.769}
        height={1.385}
        rx={0.692}
        transform="translate(70.538 190.818)"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default SvgDirectoryIcon;
