import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

// Actions
import {
  API,
  increaseScale,
  decreaseScale,
  openRightSidebar,
  resetScale,
  setCurrentPage,
  setInitialPage,
  setDocumentsReady
} from 'store/actions';
import { DOCUMENT_LOAD_FAILURE } from 'store/actions/actionTypes';
import { DOCUMENT_DETAIL_SUCCESS } from 'store/actions/api/fetchDocumentDetail';

// Context
import { useViewer } from 'components/PDFDocument';

// Components
import PDFDocument from 'components/PDFDocument';
import PDFLoading from 'components/PDFDocument/PDFLoading';
import redirectToLogin from 'helpers/redirectToLogin';
import {
  Alert,
  AlertDescription,
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text, 
  useDisclosure
} from '@chakra-ui/react';
import i18n from 'i18n';

// UI
import { FileAlertIcon } from 'components/Icons';
import { isEmpty } from 'lodash';

function Viewer() {
  const dispatch = useDispatch();
  const documents = useSelector(state => state.documents);
  const documentId = useSelector(state => state.documents.data.documentId);
  const documentsReady = useSelector(state => state.app.documents.ready);
  const navigate = useNavigate();
  const { slug, id, pagenumber } = useParams();
  const { fetchDocumentsProxy, setPreventRender } = useViewer();
  const { isOpen, onOpen, onClose } = useDisclosure();

  // local state
  const [isLoadingAgain, setIsLoadingAgain] = useState(false);

  // döküman detayını getirir.
  const fetchDocumentDetail = (id) => {

    // eski isteğin sonucu varsa 
    // tekrar istek atmayalm.
    if (! isEmpty(documents.data)) {
      return new Promise(r => r({
        type: DOCUMENT_DETAIL_SUCCESS,
        payload: documents.data,
        meta: undefined
      }));
    }

    if (pagenumber > 1) {
      dispatch(setCurrentPage(pagenumber));
      dispatch(setInitialPage(pagenumber));
      setPreventRender(true);
    }

    return dispatch(API.fetchDocumentDetail(id))
      .then(d => {
        if (d.error) throw d;

        // slug hatalıysa düzeltelim.
        if (d.slug !== slug) {
          navigate(`/${d.slug}`);
        }
        
        return d;
      });
  };

  const fetchDocument = () => {
    return fetchDocumentDetail(id)
      .then(a => a.payload.documents.map(d => d.pdfPath))
      .then(f => fetchDocumentsProxy(f))
      .then(() => dispatch(setDocumentsReady(true)))
      .then(() => isOpen && onClose())
      .catch(e => {

        // redirect login
        if (e.payload?.status === 401) {
          return redirectToLogin();
        }

        // send error message when
        // pdf.js has a rendering problem
        if (! e.type) {
          dispatch({
            type: DOCUMENT_LOAD_FAILURE,
            payload: e
          });
        }

        onOpen(true);

        throw e;
      });
  };

  const handleTryAgain = () => {
    setIsLoadingAgain(true);

    // get document again
    fetchDocument(id)
      .then(() => setIsLoadingAgain(false))
      .catch(() => {
        setIsLoadingAgain(false);
        
        // show error report modal 
        // after second attemp fails
        dispatch(openRightSidebar('error'));
      });
  };

  // URL'de id bilgisi varsa
  // döküman detayını getirelim.
  useEffect(() => {
    if (! id) return;
    if (documentId) return;

    // servise istek atalım.
    fetchDocument();
  }, [id]);

  // dökümanID varsa
  // dökümana ait pin'leri getirelim.
  useEffect(() => {
    if (! documentId) return;

    // servise istek atalım.
    dispatch(API.fetchDocumentPins(documentId));
  }, [documentId]);

  useEffect(() => {
    const handleWheel = (e) => {
      if (e.ctrlKey || e.metaKey) {
        if (e.deltaY > 0) {
          dispatch(decreaseScale());
        } else {
          dispatch(increaseScale());
        }
        e.preventDefault();
      }
    };

    const handleKeyDown = (e) => {
      const scaleKeyCodes = [61, 96, 107, 173, 109, 187, 189];

      // yakınlaştırma tuşları ile işlem yapıyorsa yakalayalım.
      if (e.ctrlKey && scaleKeyCodes.includes(e.keyCode)) {
        if (e.keyCode === 107) {
          dispatch(increaseScale());
        } else if (e.keyCode === 96) {
          dispatch(resetScale());
        } else if (e.keyCode === 109) {
          dispatch(decreaseScale());
        }
        e.preventDefault();
      }
    };

    const handleTouchStart = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault();
      }
    };

    // listen
    window.addEventListener('wheel', handleWheel, { passive: false });
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('touchstart', handleTouchStart, { passive: false });

    // clean-up
    return () => {
      window.removeEventListener('wheel', handleWheel, { passive: false });
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('touchstart', handleTouchStart, { passive: false });
    };
  }, []);

  if (! documentsReady) {
    return (
      <Fragment>
        {!isOpen && <PDFLoading isIndeterminate={false} />}
        <Modal size={{ base: 'sm', md: 'lg' }} isOpen={isOpen}>
          <ModalOverlay />
          <ModalContent>
            <Alert
              status="warning"
              variant="subtle"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              borderRadius="6"
              p={6}
              textAlign="center">
              <FileAlertIcon color="currentColor" />
              <AlertDescription 
                fontSize="sm" 
                fontWeight="400"
                mt={4}>
                <Text mb={4} textAlign="justify" style={{ textAlignLast: 'center' }}>{i18n.t('errorReport.message.error')}</Text>
              </AlertDescription>
            </Alert>
            <ModalFooter flexDirection="column" py={6}>
              <Button 
                colorScheme="blue" 
                isLoading={isLoadingAgain}
                onClick={handleTryAgain} 
                size="lg"
                width="100%">{i18n.t('form.again')}</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Fragment>
    );
  }

  return <PDFDocument />;
}

export default Viewer;
