import {
  SELECT_SEARCH_RESULT,
  SET_SEARCH_FILTER,
  RESET_SEARCH
} from 'store/actions/actionTypes';
import {
  SEARCH_REQUEST,
  SEARCH_SUCCESS,
  SEARCH_FAILURE
} from 'store/actions/api/fetchSearch';

const initialState = {
  filters: {
    q: '',
    isFullKeyword: false
  },
  results: {
    isFetching: false,
    isError: false,
    data: []
  }
};

const search = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_REQUEST:
      return {
        ...state,
        results: { isFetching: true, isError: false, data: [] }
      };
    case SEARCH_SUCCESS:
      return {
        ...state,
        results: { isFetching: false, isError: false, data: action.payload }
      };
    case SEARCH_FAILURE:
      return {
        ...state,
        results: { isFetching: false, isError: true, data: [] }
      };
    case SELECT_SEARCH_RESULT:
      return {
        ...state,
        results: {
          ...state.results,
          data: state.results.data.map((result => result.id === action.payload
            ? { ...result, selected: true }
            : { ...result, selected: false }
          ))
        }
      };
    case SET_SEARCH_FILTER:
      return { ...initialState, filters: action.payload };
    case RESET_SEARCH:
      return initialState;
    default:
      return state;
  }
};

export default search;
