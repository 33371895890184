import { createAction } from 'redux-api-middleware';
import i18n from 'i18n';

export const DOCUMENT_DETAIL_REQUEST = '@@api/DOCUMENT_DETAIL_REQUEST';
export const DOCUMENT_DETAIL_SUCCESS = '@@api/DOCUMENT_DETAIL_SUCCESS';
export const DOCUMENT_DETAIL_FAILURE = '@@api/DOCUMENT_DETAIL_FAILURE';

const fetchDocumentDetail = (id) => createAction({
  endpoint: process.env.REACT_APP_API + `/read/document?i=${id}`,
  method: 'GET',
  credentials: 'include',
  headers: {
    'Accept-Language': i18n.language,
    'Content-Type': 'application/json'
  },
  types: [
    DOCUMENT_DETAIL_REQUEST,
    DOCUMENT_DETAIL_SUCCESS,
    DOCUMENT_DETAIL_FAILURE
  ]
});

export default fetchDocumentDetail;
