import React, { useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { isEmpty } from 'lodash';
import {
  Button,
  ButtonGroup,
  FormLabel,
  FormControl,
  FormErrorMessage,
  Textarea
} from '@chakra-ui/react';

// Components
import i18n from 'i18n';

function NoteForm({ initialValues = {}, children, onSubmit, onClose }) {
  const firstFieldRef = useRef(null);

  // handle formik validation
  const handleValidation = (values) => {
    let errors = {};

    // not zorunlu yapalım.
    if (! values.content) {
      errors.content = i18n.t('form.validation.null', {
        field: i18n.t('pin.type.note.title')
      });
    }

    return errors;
  };

  // handle submit
  const handleSubmit = (values, actions) => {
    actions.setSubmitting(false);

    // callback
    if (onSubmit) {
      onSubmit(values);
    }
  };

  return (
    <Formik
      initialValues={{
        content: '',
        ...initialValues
      }}
      enableReinitialize={true}
      validate={handleValidation}
      onSubmit={handleSubmit}>
      {({
        dirty,
        values,
        errors,
        touched,
        isValid,
        handleBlur,
        handleChange,
        ...initialValues
      }) => (
        <Form>
          <FormControl variant="floating" isInvalid={(errors.content && touched.content) && true} mb={5} isRequired>
            <Textarea
              id="content"
              border="0"
              value={values.content}
              placeholder=" "
              size="sm"
              resize="vertical"
              fontSize="sm"
              onBlur={handleBlur}
              onChange={handleChange}
              ref={firstFieldRef}
            />
            <FormLabel 
              htmlFor="content" 
              fontSize="sm" 
              fontWeight="normal">{i18n.t('pin.type.note.label')}</FormLabel>
            {errors.content && touched.content
              ? <FormErrorMessage>{errors.content}</FormErrorMessage>
              : <Fragment />
            }
          </FormControl>
          {children}
          <ButtonGroup width="100%" gap={4}>
            <Button 
              size="lg" 
              fontSize="sm" 
              variant="outline" 
              width="50%" 
              onClick={onClose}>{i18n.t('form.cancel')}</Button>
            <Button 
              type="submit" 
              size="lg" 
              fontSize="sm" 
              colorScheme="blue" 
              width="50%" 
              disabled={(! dirty && isEmpty(initialValues)) || !isValid}>{i18n.t('form.save')}</Button>
          </ButtonGroup>
        </Form>
      )}
    </Formik>
  );
}

NoteForm.propTypes = {
  initialValues: PropTypes.object,
  children: PropTypes.element,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func
};

export default NoteForm;
