import * as React from 'react';

const SvgTranslateIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={23.042}
    height={20}
    viewBox="0 0 23.042 20"
    {...props}>
    <g id="Translate" transform="translate(19741 -802)">
      <path
        id="Path_102950"
        data-name="Path 102950"
        d="M45.121,23h2.116l5.022,13.837H50.206l-1.445-4.145H43.332l-1.5,4.145H39.91Zm3.108,8.167L46.1,25.053l-2.214,6.113Z"
        transform="translate(-19770.217 785.162)"
        fill="currentColor"
      />
      <path
        id="Path_102951"
        data-name="Path 102951"
        d="M13.933,15.808a20.321,20.321,0,0,1-5.895-3.751h0A51.876,51.876,0,0,1,2.679,15.54L1.608,13.933A43.468,43.468,0,0,0,6.7,10.449c-.552-.514-3.215-3.483-3.483-5.091H5.359A11.152,11.152,0,0,0,8.038,9.11a7.043,7.043,0,0,0,2.679-5.091h2.143a11.193,11.193,0,0,1-3.215,6.43h0a15.384,15.384,0,0,0,5.091,3.483ZM0,4.019H15V1.876H8.842V0H6.43V1.876H0Z"
        transform="translate(-19741 802)"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default SvgTranslateIcon;
