import * as React from 'react';

const SvgPinSubjectIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={9.168}
    height={11.296}
    viewBox="0 0 9.168 11.296"
    style={{
      color: '#fff',
    }}
    {...props}
  >
    <path
      id="text"
      d="M.2-5.744,4.984,0H2.248L-1.928-5.168V0H-4.184V-11.3h2.256v5.072L2.264-11.3h2.72Z"
      transform="translate(4.184 11.296)"
      fill="currentColor"
    />
  </svg>
);

export default SvgPinSubjectIcon;
