import { combineReducers } from 'redux';

import app from './app';
import annonation from './annonation';
import documents from './documents';
import pin from './pin';
import pins from './pins';
import search from './search';
import thumbnails from './thumbnails';
import viewer from './viewer';

export default combineReducers({
  app,
  annonation,
  documents,
  pin,
  pins,
  search,
  thumbnails,
  viewer
});
