import * as React from 'react';
import PropTypes from 'prop-types';

const SvgPinNoteIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={9.568}
    height={11.296}
    viewBox="0 0 9.568 11.296"
    style={{
      color: '#fff',
    }}
    {...props}
  >
    <path
      id="text"
      d="M4.784,0H2.528L-2.528-7.68V0H-4.784V-11.3h2.256L2.528-3.552V-11.3H4.784Z"
      transform="translate(4.784 11.296)"
      fill="currentColor"
    />
  </svg>
);

SvgPinNoteIcon.propTypes = {
  color: PropTypes.string
};

export default SvgPinNoteIcon;
