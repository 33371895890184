import { createAction } from 'redux-api-middleware';
import i18n from 'i18n';

export const PIN_CATEGORIES_REQUEST = '@@api/PIN_CATEGORIES_REQUEST';
export const PIN_CATEGORIES_SUCCESS = '@@api/PIN_CATEGORIES_SUCCESS';
export const PIN_CATEGORIES_FAILURE = '@@api/PIN_CATEGORIES_FAILURE';

const fetchPinCategories = (type) => createAction({
  endpoint: process.env.REACT_APP_API + `/documents/pins/${type}/categories`,
  method: 'GET',
  credentials: 'include',
  headers: {
    'Accept-Language': i18n.language,
    'Content-Type': 'application/json'
  },
  types: [
    PIN_CATEGORIES_REQUEST,
    PIN_CATEGORIES_SUCCESS,
    PIN_CATEGORIES_FAILURE
  ]
});

export default fetchPinCategories;
