import { createAction } from 'redux-api-middleware';
import i18n from 'i18n';

export const REPORT_ERROR_REQUEST = '@@api/REPORT_ERROR_REQUEST';
export const REPORT_ERROR_SUCCESS = '@@api/REPORT_ERROR_SUCCESS';
export const REPORT_ERROR_FAILURE = '@@api/REPORT_ERROR_FAILURE';

const reportError = (values) => createAction({
  endpoint: process.env.REACT_APP_API + '/read/ReportError',
  method: 'POST',
  credentials: 'include',
  headers: {
    'Accept-Language': i18n.language,
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({
    documentId: values.documentId,
    search: values.q,
    page: values.pagenumber || 0,
    keyword: values.keyword,
    isFullKeyword: values.isFullKeyword,
    note: values.note,
    email: values.email,
    checks: values.checks
  }),
  types: [
    REPORT_ERROR_REQUEST,
    REPORT_ERROR_SUCCESS,
    REPORT_ERROR_FAILURE
  ]
});

export default reportError;
