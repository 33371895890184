import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  ButtonGroup,
  IconButton,
  Box,
  Container,
  Flex,
  SimpleGrid,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  useColorModeValue,
  useMediaQuery
} from '@chakra-ui/react';
import {
  AddIcon,
  MinusIcon
} from '@chakra-ui/icons';
import i18n from 'i18n';

// Context
import { useViewer } from 'components/PDFDocument';

// UI
import styles from './Catalog.module.scss';
import CatalogPage from './CatalogPage/CatalogPage';

function Catalog() {
  const containerRef = useRef(null);
  const current = useSelector(state => state.viewer.current);
  const documents = useSelector(state => state.documents);
  const totalPages = useSelector(state => state.documents.data.totalPages);
  const navigate = useNavigate();
  const { viewports } = useViewer();

  // local states
  const [max, setMax] = useState(2);
  const [grid, setGrid] = useState(2);
  const [scrollTop, setScrollTop] = useState(null);
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  // color states
  const bg = useColorModeValue('#f8faff', 'gray.700');

  const handlePageView = (page = current) => {
    navigate(`/${documents.data.slug}/${i18n.t('page.slug')}/${page}`);
  };

  const handleScroll = () => {
    const node = containerRef.current;

    setScrollTop(node.scrollTop);
  };

  useEffect(() => {
    if (totalPages < 8) {
      setMax(totalPages);
      setGrid(totalPages);
      return;
    }

    // varsayılan grid
    setMax(8);
    setGrid(8);
    handleScroll();
  }, [totalPages]);

  // 1'li grid varsa
  // sayfa görünümüne geçelim.
  useEffect(() => {
    if (grid === 1) {
      handlePageView();
    }
  }, [grid]);

  // mobil'de 2'li gösterelim.
  useEffect(() => {
    if (isMobile) return setGrid(2);

    setGrid(8);
  }, [isMobile]);

  if (! viewports.length) {
    return (
      <Box className={styles.self} />
    );
  }

  return (
    <Box className={styles.self} bg={bg}>
      <Container maxW="container.xl" height="100%" py={6}>
        <Flex justifyContent="flex-end" my={6} px={4}>
          <ButtonGroup size='xs' variant='outline' spacing={3}>
            <IconButton icon={<MinusIcon />} borderRadius={50} onClick={() => setGrid(grid + 1)} disabled={grid === max} />
            <Slider value={(max + 1) - grid} min={1} max={max} step={1} width="100px">
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb />
            </Slider>
            <IconButton icon={<AddIcon />} borderRadius={50} onClick={() => setGrid(grid - 1)} disabled={grid === 1} />
          </ButtonGroup>
        </Flex>
        <SimpleGrid
          onScroll={handleScroll}
          columns={grid}
          overflow="auto"
          maxHeight="calc(100% - 110px)"
          position="relative"
          spacing="5"
          px={4}
          ref={containerRef}>
          {(viewports).map((v, i) => 
            <CatalogPage number={i + 1} 
              viewport={v} 
              scrollY={scrollTop}
              key={i} />
          )}
        </SimpleGrid>
      </Container>
    </Box>
  );
}

export default Catalog;
