import * as React from 'react';

const SvgDictionaryIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16.875}
    height={20.5}
    viewBox="0 0 16.875 20.5"
    {...props}
  >
    <path
      id="S\xF6zl\xFCk"
      d="M17.581,8H30.012A1.975,1.975,0,0,1,31.986,9.96V26.036A1.975,1.975,0,0,1,30.012,28H17.581a1.974,1.974,0,0,1-1.971-1.962V9.96A1.974,1.974,0,0,1,17.581,8Zm12.431.95h-.6V16.3a.476.476,0,0,1-.789.359l-1.52-1.124-1.633,1.151a.476.476,0,0,1-.749-.387V8.948H19.29v18.1H30.012a1.019,1.019,0,0,0,1.02-1.011V9.96A1.019,1.019,0,0,0,30.012,8.948Zm-1.55,0H25.674v6.427c1.7-1.2,1.194-1.2,2.787-.024Zm-10.125,0c-.53,0-1.773-.122-1.773,1.011V26.036a1.016,1.016,0,0,0,1.017,1.011h.755Z"
      transform="translate(-15.36 -7.748)"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.5}
    />
  </svg>
);

export default SvgDictionaryIcon;
