import { trackEvent } from '@redux-beacon/google-analytics-gtag';

const adjustmentEvent = trackEvent((action, prevState, nextState) => ({
  action: 'adjustment',
  fieldsObject: {
    adjustment_brightness: nextState.viewer.effects.brightness,
    adjustment_contrast: nextState.viewer.effects.contrast,
    adjustment_saturate: nextState.viewer.effects.saturate,
    adjustment_grayscale: nextState.viewer.effects.grayscale
  }
}));

export default adjustmentEvent;
