import * as React from 'react';

const SvgSearchIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19.103}
    height={20.01}
    viewBox="0 0 19.103 20"
    {...props}
  >
    <path
      id="Path_3"
      data-name="Path 3"
      d="M33.444,35.109a1,1,0,0,1-.714-.311l-3.57-3.675a.98.98,0,0,1,.022-1.4.993.993,0,0,1,1.4.022l3.57,3.7a.98.98,0,0,1-.022,1.4A1,1,0,0,1,33.444,35.109Z"
      transform="translate(-15.336 -15.109)"
      fill="currentColor"
    />
    <path
      id="Path_4"
      data-name="Path 4"
      d="M10.563,18.326A9.012,9.012,0,0,1,1.488,9.387,9,9,0,0,1,10.563.449a9.012,9.012,0,0,1,9.075,8.939A9.027,9.027,0,0,1,10.563,18.326Zm0-15.9A7.048,7.048,0,0,0,3.45,9.387a7.048,7.048,0,0,0,7.113,6.965,7.043,7.043,0,0,0,7.09-6.965A7.043,7.043,0,0,0,10.563,2.423Z"
      transform="translate(-1.488 -0.449)"
      fill="currentColor"
    />
  </svg>
);

export default SvgSearchIcon;
