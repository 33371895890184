import createDocumentPin from './createDocumentPin';
import deleteDocumentPin from './deleteDocumentPin';
import downloadFile from './downloadFile';
import fetchDocumentDetail from './fetchDocumentDetail';
import fetchDocumentPins from './fetchDocumentPins';
import fetchPinCategories from './fetchPinCategories';
import fetchSearch from './fetchSearch';
import updateDocumentPin from './updateDocumentPin';
import reportError from './reportError';

export const API = {
  createDocumentPin: createDocumentPin,
  deleteDocumentPin: deleteDocumentPin,
  downloadFile: downloadFile,
  fetchDocumentDetail: fetchDocumentDetail,
  fetchDocumentPins: fetchDocumentPins,
  fetchPinCategories: fetchPinCategories,
  fetchSearch: fetchSearch,
  updateDocumentPin: updateDocumentPin,
  reportError: reportError
};

export default API;
