import React, { lazy, useCallback, useRef, Fragment, Suspense } from 'react';
import {
  Button,
  Flex,
  Hide,
  List,
  ListItem,
  useColorModeValue,
  useDisclosure,
  useMediaQuery
} from '@chakra-ui/react';

// UI
import styles from './Sidebar.module.scss';
import DarkModeButton from './DarkModeButton';
import {
  AngleDoubleRightIcon,
  LoadingIcon,
  TranslationIcon
} from 'components/Icons';

// Lazy components
const SidebarButton = lazy(() => import('./SidebarButton'));
const Dictionary = lazy(() => import('./Dictionary'));
const Solvent = lazy(() => import('./Solvent'));
const Translate = lazy(() => import('./Translate'));
const Calculator = lazy(() => import('./Calculator'));
const ErrorReport = lazy(() => import('./ErrorReport'));

function Sidebar() {
  const asideMenuRef = useRef(0);
  const { isOpen: isMobileOpen, onClose: onMobileClose, onToggle: onMobileToggle } = useDisclosure();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const menuBg = useColorModeValue('white', 'gray.700');
  const menuBorderColor = useColorModeValue('gray.300', 'gray.600');
  const colorActive = useColorModeValue('#0027FF', 'white');

  const drawerContentStyle = useCallback(() => {
    if (! asideMenuRef.current) return {};
    const aside = asideMenuRef.current;

    return {
      paddingLeft: '15px',
      paddingRight: aside.offsetWidth + 'px',
      paddingTop: aside.offsetTop + 'px'
    };
  }, [asideMenuRef.current]);

  // staging ortamında mobil'de sidebar'ı gizleyelim.
  if (isMobile && process.env.REACT_APP_MODE !== 'development') {
    return <Fragment />;
  }

  return (
    <aside className={styles.self} data-mobile-menu={isMobileOpen}>
      <Hide above="md">
        <Button className={styles.mobile_toggle}
          bg="whiteAlpha.300"
          backdropFilter="blur(10px) saturate(180%)"
          border={0}
          borderRightRadius={0}
          boxShadow="md"
          h={66}
          position="absolute"
          onClick={onMobileToggle}
          size="lg">
          <AngleDoubleRightIcon
            color={colorActive}
            style={{
              transform: !isMobileOpen && 'rotate(180deg)',
              transition: 'all .3s ease-in-out'
            }} />
        </Button>
      </Hide>
      {!isMobile &&
        <DarkModeButton
          my={process.env.REACT_APP_MODE === 'production'
            ? 9
            : 10
          } />
      }
      <List bg={menuBg}
        borderBottomWidth={{ base: 0, md: 1 }}
        borderLeftWidth={1}
        borderTopWidth={{ base: 0, md: 1 }}
        borderColor={menuBorderColor}
        borderLeftRadius={{ base: 0, md: 10 }}
        boxShadow="lg"
        display="flex"
        flexDirection="column"
        height={{ base: '100%', md: 'auto' }}
        overflow="hidden"
        px={{ base: 0, md: 3 }}
        ref={asideMenuRef}>
        <Suspense 
          fallback={<Flex justifyContent="center" py={4}><LoadingIcon /></Flex>}>
          {(
            process.env.REACT_APP_MODE === 'development' && 
            process.env.REACT_APP_TYPE === 'viewer'
          ) && (
            <Fragment>
              <ListItem borderBottomWidth="1px" borderBottomColor={menuBorderColor}>
                <Dictionary drawerContentStyle={drawerContentStyle} />
              </ListItem>
              <ListItem borderBottomWidth="1px" borderBottomColor={menuBorderColor}>
                <Solvent drawerContentStyle={drawerContentStyle} />
              </ListItem>
              <ListItem borderBottomWidth="1px" borderBottomColor={menuBorderColor}>
                <SidebarButton icon={TranslationIcon} disabled={true} />
              </ListItem>
              <ListItem borderBottomWidth="1px" borderBottomColor={menuBorderColor}>
                <Translate drawerContentStyle={drawerContentStyle} />
              </ListItem>
              <ListItem borderBottomWidth="1px" borderBottomColor={menuBorderColor}>
                <Calculator drawerContentStyle={drawerContentStyle} />
              </ListItem>
            </Fragment>
          )}
          <ListItem borderBottomWidth="1px" borderBottomColor={menuBorderColor}>
            <ErrorReport onModalOpen={onMobileClose} />
          </ListItem>
          {isMobile &&
            <ListItem mt="auto"><DarkModeButton /></ListItem>
          }
        </Suspense>
      </List>
    </aside>
  );
}

export default Sidebar;
