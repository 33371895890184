import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  Box,
  Button,
  Flex,
  Menu,
  MenuList,
  MenuItem,
  MenuButton
} from '@chakra-ui/react';

// Actions
import { API } from 'store/actions';

// Components
import i18n from 'i18n';

// UI
import { CaretIcon } from 'components/Icons';
import { useColorModeValue } from '@chakra-ui/react';
import { isEmpty } from 'lodash';

function PinCategories({ type }) {
  const dispatch = useDispatch();
  const [category, setCategory] = useState({});
  const [categories, setCategories] = useState([]);

  // color
  const caretBg = useColorModeValue('gray.100', 'gray.600');
  const caretBorderColor = useColorModeValue('gray.200', 'gray.600');
  const colorPassive = useColorModeValue('#5B6F8E', 'gray.300');

  useEffect(() => {
    if (! type.length) return;

    dispatch(API.fetchPinCategories(type))
      .then(d => setCategories(d.payload.data))
      .catch(e => console.error(e));
  }, [type]);

  const handleChangeCategory = (category) => {
    setCategory(category);
  };

  if (! categories.length) return <Fragment />;
  
  return (
    <Box>
      <Menu placement="bottom-end">
        {({ isOpen }) => (
          <Fragment>
            <MenuButton as={Button}
              size="sm"
              color={colorPassive}
              fontSize="xs"
              fontWeight="normal"
              variant="outline"
              overflow="hidden"
              pr={0}
              _hover={{ backgroundColor: 'transparent' }}
              _active={{ backgroundColor: 'transparent' }}
              rightIcon={
                <Flex bg={caretBg}
                  borderLeft="1px"
                  borderLeftColor={caretBorderColor}
                  alignItems="center"
                  justifyContent="center"
                  height={8}
                  width={8}
                  ml={2}>
                  <CaretIcon color={colorPassive}
                    style={{
                      transform: isOpen
                        ? 'rotate(180deg)'
                        : '',
                      transition: 'all .3s ease-in-out'
                    }} />
                </Flex>
              }>
              {isEmpty(category)
                ? i18n.t('form.choose')
                : category.name
              }
            </MenuButton>
            <MenuList fontSize="xs" zIndex="2" maxHeight={240} overflow="auto">
              {categories.map(category =>
                <MenuItem onClick={() => handleChangeCategory(category)} key={category.id}>{category.name}</MenuItem>
              )}
            </MenuList>
          </Fragment>
        )}
      </Menu>
    </Box>
  );
}

PinCategories.propTypes = {
  type: PropTypes.string.isRequired
};

export default PinCategories;