
// CRUD
export const ADD_THUMBNAIL = 'ADD_THUMBNAIL';
export const UPDATE_THUMBNAIL = 'UPDATE_THUMBNAIL';

// HIDE
export const HIDE_PINS = 'HIDE_PINS';
export const HIDE_SEARCHES = 'HIDE_SEARCHES';
export const HIDE_TRANSLATES = 'HIDE_TRANSLATES';

// SHOW
export const SHOW_PINS = 'SHOW_PINS';
export const SHOW_SEARCHES = 'SHOW_SEARCHES';
export const SHOW_TRANSLATES = 'SHOW_TRANSLATES';

// SELECT
export const SELECT_PIN = 'SELECT_PIN';
export const SELECT_SEARCH_RESULT = 'SELECT_SEARCH_RESULT';

// DESELECT
export const DESELECT_PIN = 'DESELECT_PIN';

// OPEN
export const OPEN_LEFT_SIDEBAR = 'OPEN_LEFT_SIDEBAR';
export const OPEN_RIGHT_SIDEBAR = 'OPEN_RIGHT_SIDEBAR';
export const OPEN_IDLE = 'OPEN_IDLE';

// CLOSE
export const CLOSE_SIDEBAR = 'CLOSE_SIDEBAR';
export const CLOSE_IDLE = 'CLOSE_IDLE';

// CLICK
export const CLICK_SHARE = 'CLICK_SHARE';

// SET
export const SET_SCALE = 'SET_SCALE';
export const SET_SCALE_MODE = 'SET_SCALE_MODE';
export const SET_INITIAL_PAGE = 'SET_INITIAL_PAGE';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const SET_SEARCH_FILTER = 'SET_SEARCH_FILTER';
export const SET_BRIGHTNESS = 'SET_BRIGHTNESS';
export const SET_CONTRAST = 'SET_CONTRAST';
export const SET_SATURATE = 'SET_SATURATE';
export const SET_GRAYSCALE = 'SET_GRAYSCALE';
export const SET_IDLE_TIME = 'SET_IDLE_TIME';
export const SET_DOCUMENTS_READY = 'SET_DOCUMENTS_READY';

// RESET
export const RESET_SEARCH = 'RESET_SEARCH';
export const RESET_SCALE = 'RESET_SCALE';
export const RESET_IDLE = 'RESET_IDLE';

// OTHERS
export const DOCUMENT_LOAD_FAILURE = 'DOCUMENT_LOAD_FAILURE';
