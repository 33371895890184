import * as React from 'react';

const SvgFacebookIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={9}
    height={18}
    viewBox="0 0 9 18"
    {...props}
  >
    <path
      id="Path_20721"
      data-name="Path 20721"
      d="M137,6.188h-3.375V3.938a1.125,1.125,0,0,1,1.125-1.125h1.125V0h-2.25a3.375,3.375,0,0,0-3.375,3.375V6.188H128V9h2.25v9h3.375V9h2.25Z"
      transform="translate(-128)"
      fill="currentColor"
    />
  </svg>
);

export default SvgFacebookIcon;
