import * as React from 'react';

const SvgCheckIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18.909}
    height={16}
    viewBox="0 0 18.909 16"
    {...props}
  >
    <path
      id="Path_102802"
      data-name="Path 102802"
      d="M38.949,48.03a1.455,1.455,0,1,0-1.99,2.122l5.818,5.455a1.455,1.455,0,0,0,2.143-.168L55.1,42.348a1.455,1.455,0,0,0-2.3-1.786L43.6,52.393Z"
      transform="translate(-36.5 -40)"
      fill="#2cac1c"
    />
  </svg>
);

export default SvgCheckIcon;
