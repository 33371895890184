import React, { useCallback, useEffect, useRef, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Box, Flex, Image, Text, useColorModeValue } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import i18n from 'i18n';

// Actions
import { addThumbnail, setInitialPage } from 'store/actions';

// Context
import { useViewer } from 'components/PDFDocument';

// UI
import { LoadingIcon } from 'components/Icons';

function CatalogPage({ number, viewport, scrollY }) {
  const ref = useRef();
  const dispatch = useDispatch();
  const documents = useSelector(state => state.documents);
  const thumbnails = useSelector(state => state.thumbnails);
  const thumbnail = useSelector(state => state.thumbnails.find(t => 
    t.page === number
  ));

  // local state
  const [visible, setVisible] = useState(false);
  const bg = useColorModeValue('#f8faff', 'gray.700');
  const color = useColorModeValue('#5b6f8e', 'gray.200');
  const thumbnailBg = useColorModeValue('white', 'gray.600');
  const borderColor = useColorModeValue('#d9e1f0', 'whiteAlpha.300');
  const borderColorActive = useColorModeValue('#0027ff', 'whiteAlpha.700');
  const navigate = useNavigate();
  const {
    getPage,
    getViewport,
    setPreventRender
  } = useViewer();

  /**
   * sayfa ve viewport bilgisini kullanarak
   * ilgili sayfa için thumbnail oluşturur.
   *
   * @param  {integer} page
   * @param  {object} viewport
   * @return {void}
   */
  const generateThumbnails = useCallback(async () => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    // create viewport if needed
    if (isEmpty(viewport)) {
      viewport = await getViewport(number);
    }

    // size
    canvas.height = viewport.height;
    canvas.width = viewport.width;

    getPage(number).then(proxy => {
      const task = proxy.render({
        canvasContext: context,
        viewport: viewport
      });
  
      // generate thumbnail
      task.promise
        .then(() => {
          dispatch(addThumbnail(number, canvas.toDataURL('image/webp', 0.1)));
        })
        .catch(() => console.error('Thumbnail oluşturulurken bir sorun oluştu'));
    });
  }, [number, viewport, thumbnails, getPage]);

  const handleClick = () => {
    setPreventRender(true);
    dispatch(setInitialPage(number));

    // yönlendirelim.
    navigate(`/${documents.data.slug}/${i18n.t('page.slug')}/${number}`);
  };

  useEffect(() => {
    if (! ref.current) return;
    
    // variables
    const rect = ref.current.getBoundingClientRect();
    const parent = ref.current.offsetParent;
    const offset = parent.offsetTop;
    const viewHeight = parent.clientHeight;

    setVisible((rect.bottom - offset) >= 0 && (rect.top - viewHeight) <= offset);
  }, [scrollY, ref]);

  useEffect(() => {
    if (! visible) return;

    // thumbnail yoksa oluşturalım.
    if (isEmpty(thumbnail)) {
      generateThumbnails();
    }
  }, [visible]);

  return (
    <Box
      bg={thumbnailBg}
      onClick={handleClick}
      data-visible={visible}
      data-rendered={! isEmpty(viewport)}
      borderRadius={6}
      borderWidth={1}
      borderColor={borderColor}
      cursor="pointer"
      overflow="hidden"
      gridColumn={false}
      display="flex"
      justifyContent="center"
      flexDirection="column"
      position="relative"
      p={4}
      pb={3}
      _hover={{
        borderColor: borderColorActive
      }}
      ref={ref}>
      {! isEmpty(thumbnail)
        ? <Image src={thumbnail.image} borderRadius={4} my="auto" />
        : (
          <Flex
            bg={bg}
            alignItems="center"
            borderRadius={4}
            my="auto"
            pt={isEmpty(viewport)
              ? '100%'
              : (viewport.height/viewport.width)*100 + '%'
            }
            position="relative">
            {visible && (
              <LoadingIcon
                style={{
                  position: 'absolute',
                  left: 'calc(50% - 8px)',
                  top: 'calc(50% - 8px)'
                }} />
            )}
          </Flex>
        )
      }
      <Text fontSize="xs" fontWeight={500} color={color} mt={2}>{i18n.t('page.name')} {number}</Text>
    </Box>
  );
}

CatalogPage.propTypes = {
  number: PropTypes.number.isRequired,
  viewport: PropTypes.object.isRequired,
  scrollY: PropTypes.number,
};

export default CatalogPage;