import * as React from 'react';

const SvgPinterestIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    viewBox="0 0 18 18"
    {...props}
  >
    <path
      id="path64"
      d="M-143.49,0a9,9,0,0,0-9,9,9,9,0,0,0,5.72,8.384,8.648,8.648,0,0,1,.031-2.585c.164-.7,1.055-4.474,1.055-4.474a3.25,3.25,0,0,1-.269-1.336c0-1.251.725-2.186,1.629-2.186a1.131,1.131,0,0,1,1.139,1.268,18.134,18.134,0,0,1-.745,3,1.307,1.307,0,0,0,1.333,1.626c1.6,0,2.829-1.687,2.829-4.121a3.553,3.553,0,0,0-3.759-3.662,3.9,3.9,0,0,0-4.064,3.906,3.5,3.5,0,0,0,.67,2.054.269.269,0,0,1,.062.258c-.068.284-.22.9-.25,1.021-.039.165-.13.2-.3.12a4.034,4.034,0,0,1-1.827-3.487c0-2.839,2.063-5.446,5.947-5.446a5.282,5.282,0,0,1,5.548,5.2c0,3.1-1.956,5.6-4.67,5.6a2.409,2.409,0,0,1-2.063-1.033s-.451,1.718-.561,2.139a10.06,10.06,0,0,1-1.119,2.359,9,9,0,0,0,2.665.4,9,9,0,0,0,9-9,9,9,0,0,0-9-9"
      transform="translate(152.49)"
      fill="currentColor"
    />
  </svg>
);

export default SvgPinterestIcon;
