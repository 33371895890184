import * as React from 'react';

const SvgQuoteIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={27.785}
    height={16.71}
    viewBox="0 0 27.785 16.71"
    {...props}
  >
    <g
      id="Group_57326"
      data-name="Group 57326"
      transform="translate(-58.545 -186.349)"
    >
      <path
        id="Path_23382"
        data-name="Path 23382"
        d="M174.964,73.585a9.449,9.449,0,0,1-6.722,8.75l-.506.128h-.253a.7.7,0,0,1-.7-.381.763.763,0,0,1,.128-1.014,4.688,4.688,0,0,0,1.014-1.776,3.2,3.2,0,0,0,.19-.761V78.4a6.29,6.29,0,0,1-2.168-1.077,5.552,5.552,0,0,1,6.9-8.7,5.307,5.307,0,0,1,.453.39A5.694,5.694,0,0,1,174.964,73.585Z"
        transform="translate(-89.513 119.738)"
        fill="none"
        stroke="currentColor"
        strokeWidth={1.7}
        style={{
          mixBlendMode: 'normal',
          isolation: 'isolate',
        }}
      />
      <path
        id="Path_23382-2"
        data-name="Path 23382"
        d="M174.964,73.585a9.449,9.449,0,0,1-6.722,8.75l-.506.128h-.253a.7.7,0,0,1-.7-.381.763.763,0,0,1,.128-1.014,4.688,4.688,0,0,0,1.014-1.776,3.2,3.2,0,0,0,.19-.761V78.4a6.29,6.29,0,0,1-2.168-1.077,5.552,5.552,0,0,1,6.9-8.7,5.307,5.307,0,0,1,.453.39A5.694,5.694,0,0,1,174.964,73.585Z"
        transform="translate(-104.513 119.738)"
        fill="none"
        stroke="currentColor"
        strokeWidth={1.7}
        style={{
          mixBlendMode: 'normal',
          isolation: 'isolate',
        }}
      />
    </g>
  </svg>
);

export default SvgQuoteIcon;
