import {
  DOCUMENT_PINS_REQUEST,
  DOCUMENT_PINS_SUCCESS,
  DOCUMENT_PINS_FAILURE
} from '../actions/api/fetchDocumentPins';

const initialState = {
  isFetching: false,
  isError: false,
  data: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case DOCUMENT_PINS_REQUEST:
      return { isFetching: true, isError: false, data: {} };
    case DOCUMENT_PINS_SUCCESS:
      return { isFetching: false, isError: false, data: action.payload.data };
    case DOCUMENT_PINS_FAILURE:
      return { isFetching: false, isError: true, data: {} };
    default:
      return state;
  }
};

export default reducer;
