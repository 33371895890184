import * as React from 'react';

const SvgPageViewIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15}
    height={18}
    viewBox="0 0 15 18"
    {...props}
  >
    <g
      id="Page_view"
      data-name="Page view"
      fill="#fff"
      fillOpacity={0}
      stroke="currentColor"
      strokeWidth={2}
    >
      <rect width={15} height={18} rx={2} stroke="none" />
      <rect x={1} y={1} width={13} height={16} rx={1} fill="none" />
    </g>
  </svg>
);

export default SvgPageViewIcon;
