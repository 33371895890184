import * as React from 'react';

const SvgLightbulbIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15.669}
    height={21.5}
    viewBox="0 0 15.669 21.5"
    {...props}
  >
    <g id="noun_Light_3575070" transform="translate(-19.348 -4.75)">
      <path
        id="Path_102972"
        data-name="Path 102972"
        d="M34.766,12.547a7.478,7.478,0,0,0-2.242-5.355A7.577,7.577,0,0,0,27.17,5h-.025A7.568,7.568,0,0,0,19.6,12.621c0,2.267,1.3,3.935,2.516,5.554a9.38,9.38,0,0,1,1.918,3.337.6.6,0,0,0-.3.5.564.564,0,0,0,.573.573h5.753a.564.564,0,0,0,.573-.573.606.606,0,0,0-.274-.5A9.2,9.2,0,0,1,32.251,18.1C33.5,16.507,34.766,14.813,34.766,12.547Zm-5.554,8.917H25.2a9.955,9.955,0,0,0-2.167-3.985c-1.121-1.469-2.267-2.989-2.291-4.857a6.442,6.442,0,0,1,6.4-6.451h.025a6.42,6.42,0,0,1,6.4,6.4c0,1.868-1.146,3.387-2.242,4.882A9.941,9.941,0,0,0,29.212,21.463Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={0.5}
      />
      <path
        id="Path_102973"
        data-name="Path 102973"
        d="M43.48,80.7H38.673a.573.573,0,1,0,0,1.146H43.48a.573.573,0,1,0,0-1.146Z"
        transform="translate(-13.893 -55.846)"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={0.5}
      />
      <path
        id="Path_102975"
        data-name="Path 102975"
        d="M43.853,34.593l-1.619,1.619-1.619-1.619a.581.581,0,0,0-.822.822l1.868,1.868v3.96a.573.573,0,1,0,1.146,0v-3.96l1.868-1.868a.581.581,0,0,0-.822-.822Z"
        transform="translate(-15.039 -22.096)"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={0.5}
      />
    </g>
  </svg>
);

export default SvgLightbulbIcon;
