import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Rnd } from 'react-rnd';
import { isEmpty } from 'lodash';
import {
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverHeader,
  PopoverBody,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';
import { WarningIcon } from '@chakra-ui/icons';

// Actions
import { API } from 'store/actions';

// Components
import i18n from 'i18n';
import GetPinForm from './GetPinForm';

// UI
import styles from './PinMarker.module.scss';
import {
  PinTitleIcon,
  PinDirectoryIcon,
  PinSubjectIcon,
  PinNoteIcon,
  PinFastenerIcon
} from 'components/Icons';
import PinCategories from '../PinCategories/PinCategories';

function PinMarker({ page, type, position, editing, onClear }) {
  const dispatch = useDispatch();
  const pin = useSelector(state => state.pin);
  const documentId = useSelector(state => state.documents.data.documentId);
  const pinTranslate = i18n.t(`pin.type.${type}`, {
    returnObjects: true
  });

  // local state
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const [size] = useState(40);
  const [formValues, setFormValues] = useState({});
  const { onOpen, onClose, isOpen } = useDisclosure();

  // color
  const alertBg = useColorModeValue('#F8FAFF', 'gray.400');
  const alertBorderColor = useColorModeValue('#D9E1F0', 'gray.340');
  const alertTitleColor = useColorModeValue('#021B62', 'gray.900');
  const alertColor = useColorModeValue('#5B6F8E', 'gray.700');

  useEffect(() => {
    if (isEmpty(position)) {
      setFormValues({});
      setLeft(0);
      setTop(0);
      return;
    }

    // get boundaried coordinate
    const {x, y} = boundariedCoordinate(position.x, position.y);

    // save position
    setLeft(x);
    setTop(y);
  }, [position]);

  useEffect(() => {
    if (! top) return;
    if (! left) return;

    onOpen(true);
  }, [top, left]);

  useEffect(() => {
    if (! editing) return;
    if (isEmpty(pin)) return;

    setFormValues(pin);
  }, [editing, pin]);

  const boundariedCoordinate = (x, y) => {
    const { height, width } = page.div.getBoundingClientRect();

    // position
    let left = x;
    let top = y;

    // boundaries
    const halfSize = Math.round(size / 2);
    const minLeft = page.div.offsetLeft + halfSize;
    const maxLeft = minLeft + width - size;
    const minTop = page.div.offsetTop + halfSize;
    const maxTop = minTop + height - size;

    // check our horizontal boundaries
    if (left < minLeft) {
      left = minLeft;
    } else if (left > maxLeft) {
      left = maxLeft;
    }

    // check our vertical boundaries
    if (top < minTop) {
      top = minTop;
    } else if (top > maxTop) {
      top = maxTop;
    }

    return {
      x: left,
      y: top
    };
  };

  const handleDragStop = (e, d) => {
    const halfSize = Math.round(size / 2);

    const {x, y} = boundariedCoordinate(d.x + halfSize, d.y + halfSize);

    // save position
    setTop(y);
    setLeft(x);
  };

  // handle formik validation
  const handleSubmit = (values) => {
    const pageNumber = page.pdfPage.pageNumber;
    const viewport = page.viewport;

    const positionPoint = viewport.convertToPdfPoint(
      left - page.div.offsetLeft,
      top - page.div.offsetTop
    );

    // veriyi hazırlayalım.
    const payload = {
      page: pageNumber,
      type: type,
      category: values.category,
      ...values,
      position: positionPoint
    };

    // pin ekle/güncelle işlemi için 
    // servise istek atalım.
    dispatch(editing
      ? API.updateDocumentPin(values.id, payload)
      : API.createDocumentPin(payload)
    ).then(() => dispatch(API.fetchDocumentPins(documentId)));

    // state'i sıfırlayalım.
    setTop(0);
    setLeft(0);
    onClose(true);
    if (editing) {
      setFormValues({});
    }

    // parent state'i sıfırlayalım.
    if (onClear) {
      onClear();
    }
  };

  const handleClose = () => {
    onClose();

    // parent state'i sıfırlayalım.
    if (onClear) {
      onClear();
    }
  };

  // hazır değilse
  if (! left || ! top)
    return <div className={styles.self} />;

  return (
    <React.Fragment>
      <Rnd className={styles.self}
        style={{
          backgroundColor: '#ffffff',
          border: '1px dashed #0027FF',
          boxShadow: '0 0 0 3px rgba(255, 255, 255, .3)',
          borderRadius: 50,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 9999
        }}
        onDragStop={handleDragStop}
        enableResizing={false}
        size={{
          height: size,
          width: size
        }}
        position={{
          x: left - Math.round(size / 2),
          y: top - Math.round(size / 2)
        }}>
        {type === 'title'
          ? <PinTitleIcon />
          : type === 'index'
            ? <PinDirectoryIcon />
            : type === 'subject'
              ? <PinSubjectIcon />
              : type === 'note'
                ? <PinNoteIcon />
                : <PinFastenerIcon />
        }
      </Rnd>
      <Popover
        isOpen={isOpen}
        placement="auto"
        onOpen={onOpen}
        onClose={onClose}>
        <PopoverTrigger>
          <span className={styles.transparent} style={{
            left: (left - Math.round(size / 2)) + 'px',
            top: (top - Math.round(size / 2)) + 'px',
            height: size + 'px',
            width: size + 'px'
          }} />
        </PopoverTrigger>
        <PopoverContent cursor="default">
          <PopoverArrow />
          <PopoverHeader display="flex" justifyContent="space-between" alignItems="center">
            <Box>
              <Text as="span" fontWeight="bold" mr={1}>{i18n.t(`pin.type.${type}.title`)} {i18n.t('form.add')}</Text>
              <Tooltip label={i18n.t(`pin.type.${type}.description`)} placement="right" hasArrow>
                <WarningIcon w={4} h={4} color="gray.400" />
              </Tooltip>
            </Box>
            <PinCategories type={type} />
          </PopoverHeader>
          <PopoverBody py={4}>
            <GetPinForm 
              type={type}
              initialValues={formValues} 
              onSubmit={handleSubmit} 
              onClose={handleClose}>
              {type !== 'title' && (
                <Box 
                  px={4} py={3} mb={5} rounded="md" 
                  bg={alertBg} 
                  borderColor={alertBorderColor}
                  borderWidth={1}
                  color={alertColor}
                  textAlign="left" fontSize="sm">
                  <Text color={alertTitleColor} fontWeight={600}>{pinTranslate.whatis}</Text>
                  <Text fontSize="xs">{pinTranslate.description}</Text>
                </Box>
              )}
            </GetPinForm>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </React.Fragment>
  );
}

PinMarker.propTypes = {
  page: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  position: PropTypes.object,
  editing: PropTypes.bool,
  onClear: PropTypes.func
};

export default PinMarker;
