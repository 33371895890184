import { trackEvent } from '@redux-beacon/google-analytics-gtag';

const shareEvent = trackEvent((action, prevState, nextState) => ({
  action: 'share',
  fieldsObject: {
    method: action.payload,
    content_type: 'link',
    item_id: nextState.documents.data.documentId
  }
}));

export default shareEvent;
