import * as React from 'react';
import PropTypes from 'prop-types';

const SvgNextIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={7.346}
    height={12.5}
    viewBox="0 0 7.346 12.5"
    {...props}
  >
    <path
      id="Back"
      d="M6.846,6a.772.772,0,0,0-.054-.293.677.677,0,0,0-.184-.249L1.243.2A.711.711,0,0,0,.73,0,.759.759,0,0,0,.361.09.668.668,0,0,0,.1.351a.722.722,0,0,0,.127.886L5.1,6,.224,10.764a.727.727,0,0,0-.127.889.675.675,0,0,0,.264.257A.759.759,0,0,0,.73,12a.711.711,0,0,0,.513-.2L6.607,6.542a.677.677,0,0,0,.184-.249A.772.772,0,0,0,6.846,6Z"
      transform="translate(0.25 0.25)"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.5}
    />
  </svg>
);

SvgNextIcon.propTypes = {
  color: PropTypes.string
};

export default SvgNextIcon;
