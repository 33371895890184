import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useMatch, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Grid,
  GridItem,
  Slider,
  SliderThumb,
  SliderTrack,
  SliderFilledTrack,
  Input,
  InputGroup,
  InputRightElement,
  Show,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';

// Context
import { useViewer } from 'components/PDFDocument';

// Actions
import {
  increaseScale,
  decreaseScale
} from 'store/actions';

// Components
import i18n from 'i18n';
import ResultsBar from './ResultsBar';
import ResultsPages from './ResultsPages';

// UI
import styles from './Footer.module.scss';
import {
  ArrowRightIcon,
  PrevIcon,
  NextIcon,
  PageViewIcon,
  CatalogViewIcon,
  ZoomInIcon,
  ZoomOutIcon,
  SelectionViewIcon,
  FullsizeIcon
} from 'components/Icons';
import FooterButton from './FooterButton/FooterButton';

function Footer() {
  const firstFieldRef = useRef(null);
  const { onOpen, onClose, isOpen } = useDisclosure();
  const {
    goToPage,
    nextPage,
    prevPage,
    requestFullscreen
  } = useViewer();
  const current = useSelector(state => state.viewer.current);
  const documents = useSelector(state => state.documents.data);
  const navigate = useNavigate();
  const isPageView = useMatch(`/:filename-:id/${i18n.t('page.slug')}/:pagenumber`);
  const isCatalogView = useMatch(`/:filename-:id/${i18n.t('catalog.slug')}`);

  // color states
  const color = useColorModeValue('#5B6F8E', 'white');
  const colorPassive = useColorModeValue('blackAlpha', 'white');
  const sliderBg = useColorModeValue('blue.100', 'gray.600');
  const menuBg = useColorModeValue('white', 'gray.700');
  const menuBorderColor = useColorModeValue('gray.300', 'gray.600');
  const primaryColor = useColorModeValue('#0B26FB', 'gray.300');

  // Dispatch
  const dispatch = useDispatch();

  // Bindings
  const handleZoomIn = () => dispatch(increaseScale());
  const handleZoomOut = () =>  dispatch(decreaseScale());
  const handleNextPage = () => nextPage();
  const handlePrevPage = () => prevPage();
  const handlePageView = () => navigate(`/${documents.slug}/${i18n.t('page.slug')}/${current}`);
  const handleCatalogView = () => navigate(`/${documents.slug}/${i18n.t('catalog.slug')}`);
  const handleToggleView = () => isCatalogView
    ? handlePageView()
    : handleCatalogView();
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      goToPage(e.target.value);
    }
  };
  const handleWheel = (e) => {
    if (e.deltaY > 0) {
      handlePrevPage();
    } else {
      handleNextPage();
    }
  };

  return (
    <Box as="footer"
      className={styles.self}
      bg={menuBg}
      borderTop="1px"
      borderTopColor={menuBorderColor}
      borderTopRadius={{ base: 10, md: 15 }}
      boxShadow="lg"
      py={2}
      pl={4}
      pr={2}>
      <ResultsBar />
      <Grid
        alignItems="flex-end"
        templateColumns="repeat(5, 1fr)"
        gap={{ base: 2, md: 4 }}>
        <GridItem colSpan={4} display="flex" flexDirection="column" position="relative">
          <ResultsPages />
          <Slider
            height={{ base: 8, md: 10 }}
            defaultValue={current} max={documents.totalPages}
            value={current || 1}
            onChange={(num) => goToPage(num)}
            onWheel={handleWheel}>
            <SliderTrack bg={sliderBg} borderRadius={50} height={{ base: 2, md: 1 }}>
              <SliderFilledTrack bg={primaryColor} />
            </SliderTrack>
            <SliderThumb boxSize={5} bg={primaryColor} />
          </Slider>
        </GridItem>
        <GridItem colSpan={1} display="flex" alignItems="center">
          <Popover
            isOpen={isOpen}
            initialFocusRef={firstFieldRef}
            onOpen={onOpen}
            onClose={onClose}
            placement="top">
            <PopoverTrigger>
              <Button size={{ base: 'sm', md: 'md' }}
                colorScheme={colorPassive}
                fontWeight={{ base: 'normal', md: 'medium' }}
                variant="ghost"
                height={10}>{current}/{documents.totalPages}</Button>
            </PopoverTrigger>
            <PopoverContent p={5}>
              <PopoverArrow />
              <InputGroup size="lg">
                <Input
                  fontSize="sm"
                  placeholder={i18n.t('page.number')}
                  _placeholder={{ color: 'inherit' }}
                  onKeyDown={handleKeyDown}
                  ref={firstFieldRef} />
                <InputRightElement>
                  <Button size={{ base: 'sm', md: 'md' }}
                    disabled
                    colorScheme="teal"
                    variant="link">
                    <ArrowRightIcon color={color} />
                  </Button>
                </InputRightElement>
              </InputGroup>
            </PopoverContent>
          </Popover>
          <Show above="lg">
            <FooterButton 
              icon={PrevIcon}
              iconSize={3}
              title={i18n.t('page.prev')}
              onClick={() => handlePrevPage()}
              disabled={current <= 1} />
            <FooterButton 
              icon={NextIcon}
              iconSize={3}
              title={i18n.t('page.next')}
              onClick={() => handleNextPage()}
              disabled={current >= documents.totalPages} />
          </Show>
          <Show above="md">
            <FooterButton 
              icon={PageViewIcon}
              title={i18n.t('page.view')}
              isActive={isPageView && true}
              onClick={() => handlePageView()} />
            <FooterButton 
              icon={CatalogViewIcon}
              title={i18n.t('catalog.view')}
              isActive={isCatalogView && true}
              onClick={() => handleCatalogView()} />
          </Show>
          <Show below="md">
            <FooterButton 
              icon={isCatalogView
                ? PageViewIcon
                : CatalogViewIcon
              }
              title={isCatalogView
                ? i18n.t('catalog.view')
                : i18n.t('page.view')
              }
              isActive={true}
              onClick={() => handleToggleView()} />
          </Show>
          <FooterButton 
            icon={ZoomInIcon}
            title={i18n.t('setting.zoomin')}
            onClick={() => handleZoomIn()} />
          <FooterButton 
            icon={ZoomOutIcon}
            title={i18n.t('setting.zoomout')}
            onClick={() => handleZoomOut()} />
          {process.env.REACT_APP_MODE === 'development' && (
            <Show above="lg">
              <FooterButton 
                icon={SelectionViewIcon}
                title={''}
                disabled />
            </Show>
          )}
          <Show above="lg">
            <FooterButton 
              icon={FullsizeIcon}
              title={i18n.t('setting.fullsize')}
              onClick={() => requestFullscreen()} />
          </Show>
        </GridItem>
      </Grid>
    </Box>
  );
}

export default Footer;
