import { ADD_THUMBNAIL, UPDATE_THUMBNAIL } from './actionTypes';

export function addThumbnail(page, image) {
  return (dispatch, getState) => {
    const { thumbnails } = getState();

    // resim varsa güncelleyelim
    let thumbnail = thumbnails.find(t => t.page === page);
    if (thumbnail) {

      // eski resim ile aynıysa güncellemeyelim.
      if (thumbnail.image === image) return;

      // eski resim yeni resimden kaliteliyse güncellemeyelim.
      if (thumbnail.image.length > image.length) return;

      return dispatch({
        type: UPDATE_THUMBNAIL,
        payload: {
          page: page,
          image: image
        }
      });
    }

    dispatch({
      type: ADD_THUMBNAIL,
      payload: {
        page: page,
        image: image
      }
    });
  };
}
