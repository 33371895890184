import React from 'react';
import { Button, Icon, Text, useColorModeValue } from '@chakra-ui/react';

// UI
import { GrabIcon } from 'components/Icons';
import { Trans } from 'react-i18next';

function HandTool() {
  const color = useColorModeValue('#021b62', 'white');
  const colorActive = useColorModeValue('#0027FF', 'white');

  return (
    <Button
      variant="ghost"
      borderBottomWidth={3}
      borderBottomColor={colorActive}
      flexDirection="column"
      height="auto"
      size="lg"
      fontSize="xs"
      px={2}
      py={3}
      borderRadius={0}>
      <Icon as={GrabIcon} color={color} h={5} w={5} />
      <Text mt={2}><Trans i18nKey="grab" components={{ br: <br /> }} /></Text>
    </Button>
  );
}

export default HandTool;
