import { mode } from '@chakra-ui/theme-tools';
import { extendTheme } from '@chakra-ui/react';

const activeLabelStyles = {
  fontWeight: 'medium',
  transform: 'scale(0.85) translateY(-24px)'
};

const theme = extendTheme({
  config: {
    initialColorMode: 'light',
    useSystemColorMode: true
  },
  colors: {
    brand: {
      900: '#001FFF',
      800: '#0B26FB',
      // 700: '#2a69ac',
    },
    // white: (props) => mode('#ffffff', '#000000')(props),
    black: '#021B62',
    blue: {
      100: '#f0f2ff',
      200: '#b4bfff',
      300: '#788dff',
      400: '#3c5aff',
      500: '#0027ff',
      600: '#0022e1',
      700: '#0019a5',
      800: '#001587',
      900: '#001069',
    },
    // gray: {
    //   100: '#f7f9fc',
    //   200: '#e8edf6',
    //   300: '#d9e1f0',
    //   400: '#cad5ea',
    //   500: '#bac9e4',
    //   600: '#2b426e',
    //   700: '#1a2842',
    //   800: '#090e17',
    //   900: '#0e1625',
    // }
  },
  fonts: {
    heading: 'Poppins, sans-serif',
    body: 'Poppins, sans-serif'
  },
  components: {
    Form: {
      variants: {
        floating: (props) => ({
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles
              }
            },
            'input:not(:placeholder-shown) + label, textarea:not(:placeholder-shown) + label, .chakra-select__wrapper + label': {
              ...activeLabelStyles
            },
            label: {
              // display: 'flex',
              // alignItems: 'center',
              color: '#5B6F8E',
              // bottom: 0,
              top: 0,
              left: 0,
              zIndex: 1,
              position: 'absolute',
              backgroundColor: mode('white', 'gray.700')(props),
              pointerEvents: 'none',
              mx: 3,
              px: 1,
              my: 3,
              transformOrigin: 'left top',
              whiteSpace: 'nowrap'
            }
          }
        })
      }
    },
    Checkbox: {
      sizes: {
        md: {
          label: { fontSize: 'sm' }
        }
      }
    },
    Drawer: {
      variants: {
        alwaysOpen: {
          parts: ['dialog, dialogContainer'],
          dialog: {
            boxShadow: '3px 0 11px 10px rgba(0, 0, 0, .1)',
            pointerEvents: 'auto',
          },
          dialogContainer: {
            pointerEvents: 'none',
          },
        },
        clickThrough: {
          dialogContainer: {
            pointerEvents: 'none',
          },
          dialog: {
            pointerEvents: 'auto',
          },
        },
      },
    }
  },
  sizes: {
    dpr: window.devicePixelRatio
  }
});

export default theme;
