import { trackEvent } from '@redux-beacon/google-analytics-gtag';

const scaleEvent = trackEvent((action, prevState, nextState) => ({
  action: 'scale',
  fieldsObject: {
    scale_ratio: nextState.viewer.scale,
    scale_mode: nextState.viewer.scale_mode
  }
}));

export default scaleEvent;
