import {
  OPEN_IDLE,
  RESET_IDLE,
  SET_IDLE_TIME,
  SET_DOCUMENTS_READY,
  OPEN_LEFT_SIDEBAR,
  OPEN_RIGHT_SIDEBAR,
  CLOSE_IDLE,
  CLOSE_SIDEBAR
} from 'store/actions/actionTypes';

const initialState = {
  idle: {
    status: true,
    time: 3000
  },
  sidebar: {
    name: '',
    status: false,
    direction: ''
  },
  documents: {
    ready: false
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_IDLE:
      return { ...state, idle: { ...state.idle, status: true } };
    case CLOSE_IDLE:
      return { ...state, idle: { ...state.idle, status: false } };
    case SET_IDLE_TIME:
      return { ...state, idle: { ...state.idle, time: action.payload } };
    case RESET_IDLE:
      return { ...state, idle: state.idle };
    case OPEN_LEFT_SIDEBAR:
      return { ...state, sidebar: {
        name: action.payload,
        status: true,
        direction: 'left'
      } };
    case OPEN_RIGHT_SIDEBAR:
      return { ...state, sidebar: {
        name: action.payload,
        status: true,
        direction: 'right'
      } };
    case CLOSE_SIDEBAR:
      return { ...state, sidebar: initialState.sidebar };
    case SET_DOCUMENTS_READY:
      return { ...state, documents: { ready: true } };
    default:
      return state;
  }
};

export default reducer;
