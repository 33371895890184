import { trackEvent } from '@redux-beacon/google-analytics-gtag';

const anonationEvent = trackEvent((action, prevState, nextState) => ({
  action: 'annonation',
  fieldsObject: {
    hide_pins: nextState.annonation.hide_pins,
    hide_searches: nextState.annonation.hide_searches,
    hide_translates: nextState.annonation.hide_translates,
  }
}));

export default anonationEvent;
