import React, { lazy, useEffect, useState, useRef, Fragment, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Badge,
  Flex,
  List,
  ListItem,
  Hide,
  Show,
  useColorModeValue,
  useDisclosure,
  useMediaQuery
} from '@chakra-ui/react';

// Actions
import {
  openLeftSidebar,
  closeSidebar,
  setScaleMode
} from 'store/actions';

// UI
import styles from './Header.module.scss';
import Logo from 'components/Logo';
import {
  AngleDoubleRightIcon,
  LoadingIcon,
  MenuCaretRightIcon
} from 'components/Icons';

// Lazy components
const Search = lazy(() => import('./Search'));
const Pin = lazy(() => import('./Pin'));
const Translate = lazy(() => import('./Translate'));
const Bookshelf = lazy(() => import('./Bookshelf'));
const Download = lazy(() => import('./Download'));
const Share = lazy(() => import('./Share'));
const Setting = lazy(() => import('./Setting'));
const ErrorReport = lazy(() => import('app/Sidebar/ErrorReport'));
const DarkModeButton = lazy(() => import('app/Sidebar/DarkModeButton'));

function Header() {
  const dispatch = useDispatch();
  const headerRef = useRef(0);
  const headerMenuRef = useRef(0);
  const sidebar = useSelector(state => state.app.sidebar);
  const documents = useSelector(state => state.documents);

  // local states
  const [arrowStyle, setArrowStyle] = useState({});
  const [drawerStyle, setDrawerStyle] = useState({});
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { isOpen: isMobileOpen, onClose: onMobileClose, onToggle: onMobileToggle } = useDisclosure();

  // color states
  const menuBg = useColorModeValue('white', 'gray.700');
  const menuBorderColor = useColorModeValue('gray.300', 'gray.600');
  const badgeBg = useColorModeValue('purple', 'gray.300');
  const badgeBgActive = useColorModeValue('green', 'white');
  const colorActive = useColorModeValue('#0027FF', 'white');
  const caretBg = useColorModeValue('#0027FF', 'gray.600');

  // header içindeki drawer bileşenlerinin
  // menü alanı ile aynı hizada olması için padding tanımlar.
  useEffect(() => {
    const menuRef = headerMenuRef.current;

    // menü genişliği yoksa dönelim.
    if (! menuRef.offsetTop && ! isMobile) return;
    if (! menuRef.offsetWidth) return;

    // save state
    setDrawerStyle({
      paddingLeft: menuRef.offsetWidth,
      paddingTop: menuRef.offsetTop
        ? menuRef.offsetTop
        : '20px'
    });
  }, [headerMenuRef.current.offsetTop, headerMenuRef.current.offsetWidth, isMobile]);

  const handleToggle = (e, name) => {
    if (sidebar.name === name) return handleClose();

    // sidebar önceden kapalıysa viewer boyutunu
    // sayfa genişliğine göre düzeltelim.
    if (! sidebar.status && ! isMobile) {
      setTimeout(() => dispatch(setScaleMode('page-width')), 60);
    }

    // sidebar'ı açalım
    dispatch(openLeftSidebar(name));

    // menü arrow'u seçili menü üzerinde gösterelim.
    const menuList = e.target.closest('li');
    setArrowStyle({
      height: menuList.clientHeight,
      top: menuList.offsetTop
    });
  };

  const handleClose = () => {
    setArrowStyle({});
    dispatch(closeSidebar());

    // mobil hariç yakınlaştırma modunu
    // eski haline çevirelim.
    if (! isMobile) {
      setTimeout(() => dispatch(setScaleMode('page-height')), 60);
    }
  };

  return (
    <header className={styles.self} data-mobile-menu={isMobileOpen} ref={headerRef}>
      <Hide above="md">
        <Button className={styles.mobile_toggle}
          bg="whiteAlpha.300"
          backdropFilter="blur(10px) saturate(180%)"
          border={0}
          borderLeftRadius={0}
          boxShadow="md"
          h={66}
          position="absolute"
          onClick={onMobileToggle}
          size="lg">
          <AngleDoubleRightIcon
            color={colorActive}
            style={{
              transform: isMobileOpen && 'rotate(180deg)',
              transition: 'all .3s ease-in-out'
            }} />
        </Button>
      </Hide>
      <Show above="md">
        <div className={styles.logo}>
          <a href={documents.data.detailPageUrl} target="_blank" rel="noreferrer noopener" title={documents.data.name}>
            <Logo height="auto" width="43" />
          </a>
          {process.env.REACT_APP_MODE === 'production'
            ? <Fragment />
            : process.env.REACT_APP_MODE === 'staging'
              ? <Badge colorScheme={badgeBgActive} mt="2">BETA</Badge>
              : <Badge colorScheme={badgeBg} mt="2">DEMO</Badge>
          }
        </div>
      </Show>
      <List bg={menuBg}
        borderBottomWidth={{ base: 0, md: 1 }}
        borderRightWidth={1}
        borderTopWidth={{ base: 0, md: 1 }}
        borderColor={menuBorderColor}
        borderRightRadius={{ base: 0, md: 10 }}
        boxShadow="lg"
        display="flex"
        flexDirection="column"
        height={{ base: '100%', md: 'auto' }}
        px={{ base: 0, md: 3 }}
        ref={headerMenuRef}>
        <Suspense 
          fallback={<Flex justifyContent="center" py={4}><LoadingIcon /></Flex>}>
          <ListItem borderBottomWidth="1px" borderBottomColor={menuBorderColor}>
            <Search
              isDrawerOpen={sidebar.name === 'search' && sidebar.status}
              onDrawerOpen={(e) => handleToggle(e, 'search')}
              onDrawerClose={handleClose}
              style={drawerStyle} />
          </ListItem>
          {process.env.REACT_APP_TYPE === 'viewer' && (
            <Fragment>
              <ListItem borderBottomWidth="1px" borderBottomColor={menuBorderColor}>
                <Pin drawerContentStyle={drawerStyle}
                  isDrawerOpen={sidebar.name === 'pin' && sidebar.status}
                  onDrawerOpen={(e) => handleToggle(e, 'pin')}
                  onDrawerClose={handleClose} />
              </ListItem>
              <ListItem borderBottomWidth="1px" borderBottomColor={menuBorderColor}>
                <Translate drawerContentStyle={drawerStyle}
                  isDrawerOpen={sidebar.name === 'translate' && sidebar.status}
                  onDrawerOpen={(e) => handleToggle(e, 'translate')}
                  onDrawerClose={handleClose} />
              </ListItem>
              {process.env.REACT_APP_MODE === 'development' && (
                <Fragment>
                  <ListItem borderBottomWidth="1px" borderBottomColor={menuBorderColor}>
                    <Bookshelf drawerContentStyle={drawerStyle}
                      isDrawerOpen={sidebar.name === 'bookmark' && sidebar.status}
                      onDrawerOpen={(e) => handleToggle(e, 'bookmark')}
                      onDrawerClose={handleClose} />
                  </ListItem>
                </Fragment>
              )}
            </Fragment>
          )}
          <ListItem borderBottomWidth="1px" borderBottomColor={menuBorderColor}>
            <Download drawerContentStyle={drawerStyle}
              isDrawerOpen={sidebar.name === 'download' && sidebar.status}
              onDrawerOpen={(e) => handleToggle(e, 'download')}
              onDrawerClose={handleClose} />
          </ListItem>
          <ListItem borderBottomWidth="1px" borderBottomColor={menuBorderColor}>
            <Share drawerContentStyle={drawerStyle}
              isDrawerOpen={sidebar.name === 'share' && sidebar.status}
              onDrawerOpen={(e) => handleToggle(e, 'share')}
              onDrawerClose={handleClose} />
          </ListItem>
          {(isMobile && process.env.REACT_APP_MODE !== 'development') &&
            <ListItem borderBottomWidth="1px" borderBottomColor={menuBorderColor}>
              <ErrorReport onModalOpen={onMobileClose} />
            </ListItem>
          }
          <ListItem borderBottomWidth="1px" borderBottomColor={menuBorderColor}>
            <Setting drawerContentStyle={drawerStyle}
              isDrawerOpen={sidebar.name === 'setting' && sidebar.status}
              onDrawerOpen={(e) => handleToggle(e, 'setting')}
              onDrawerClose={handleClose} />
          </ListItem>
          {isMobile &&
            <ListItem mt="auto"><DarkModeButton /></ListItem>
          }
          <ListItem display="flex" alignItems="center"
            position="absolute"
            left="calc(100% - 3px)"
            top="-1000px"
            transition="all .2s ease-in-out"
            style={arrowStyle}>
            <MenuCaretRightIcon
              color={isMobile
                ? caretBg
                : menuBg
              }
              borderColor={menuBorderColor} />
          </ListItem>
        </Suspense>
      </List>
    </header>
  );
}

export default Header;
