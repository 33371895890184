import { createAction } from 'redux-api-middleware';

export const DELETE_DOCUMENT_PIN_REQUEST = '@@api/DELETE_DOCUMENT_PIN_REQUEST';
export const DELETE_DOCUMENT_PIN_SUCCESS = '@@api/DELETE_DOCUMENT_PIN_SUCCESS';
export const DELETE_DOCUMENT_PIN_FAILURE = '@@api/DELETE_DOCUMENT_PIN_FAILURE';

const deleteDocumentPin = (id) => (dispatch, getState) => {
  const { documents } = getState();

  const documentId = documents.data.documentId;
  if (! documentId) return;

  return dispatch(createAction({
    endpoint: process.env.REACT_APP_API + `/documents/${documentId}/pins/${id}`,
    method: 'DELETE',
    credentials: 'include',
    types: [
      DELETE_DOCUMENT_PIN_REQUEST,
      DELETE_DOCUMENT_PIN_SUCCESS,
      DELETE_DOCUMENT_PIN_FAILURE
    ]
  }));
};

export default deleteDocumentPin;
