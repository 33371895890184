import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import {
  Box,
  Button,
  Tooltip,
  useColorModeValue,
  useToken
} from '@chakra-ui/react';

// Context
import { useViewer } from 'components/PDFDocument';

// Actions
import { selectSearchResult } from 'store/actions';

function ResultsPages() {
  const dispatch = useDispatch();
  const totalPages = useSelector(state => state.documents.data.totalPages);
  const results = useSelector(state => state.search.results.data);
  const currentResult = useSelector(state =>
    state.search.results.data.find(r => r.selected === true)
  );
  const { goToPage } = useViewer();

  // local state
  const [resultsByPage, setResultsByPage] = useState([]);

  // color states
  const color = useColorModeValue('#0B26FB', 'gray.200');
  const shadowColor = useToken(['colors'], [color]);

  const handleSelectResult = (result) => {

    // seçilmiş sayfaya git
    goToPage(result.pageNumber);

    // save state
    dispatch(selectSearchResult(result.id));
  };

  useEffect(() => {
    if (! results.length) return;

    setResultsByPage(_.chain(results)
      .groupBy(r => r.page)
      .map((value, key) => ({ page: key, data: value[0] }))
      .value()
    );
  }, [results]);

  if (! results.length) return <Fragment />;

  return (
    <Box height={1} mt={2} position="relative" zIndex={2}>
      {resultsByPage.map((result, i) => (
        <Tooltip label={result.data.searchText} placement="top" hasArrow key={i}>
          <Button backgroundColor="transparent" borderRadius="50%"
            isActive={currentResult?.page === result.data.page}
            alignItems="flex-start"
            height={10}
            width={10}
            onClick={() => handleSelectResult(result.data)}
            position="absolute"
            zIndex={3}
            boxShadow="none"
            _hover={{
              backgroundColor: 'transparent',
              '& div': {
                boxShadow: `0 0 0 5px ${shadowColor}33`
              }
            }}
            _active={{
              '& div': {
                boxShadow: `0 0 0 5px ${shadowColor}33`
              }
            }}
            style={{
              left: `calc(${result.page * (100 / totalPages)}% - 20px)`
            }}>
            <Box bg={color} borderRadius="50%" width={1} height={1} transition="all .3s ease-in-out" />
          </Button>
        </Tooltip>
      ))}
    </Box>
  );
}

export default ResultsPages;
