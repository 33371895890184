import * as React from 'react';

const SvgPinTitleIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={8.56}
    height={11.296}
    viewBox="0 0 8.56 11.296"
    style={{
      color: '#fff',
    }}
    {...props}
  >
    <path
      id="text"
      d="M2.256-5.824a2.74,2.74,0,0,1,1.64.968,2.748,2.748,0,0,1,.616,1.784A2.819,2.819,0,0,1,3.56-.824,4.1,4.1,0,0,1,.8,0H-4.048V-11.3H.7a4.062,4.062,0,0,1,2.632.752,2.575,2.575,0,0,1,.92,2.112A2.633,2.633,0,0,1,3.7-6.7,2.567,2.567,0,0,1,2.256-5.824ZM-1.792-6.56H.24a1.933,1.933,0,0,0,1.288-.376,1.342,1.342,0,0,0,.44-1.08A1.379,1.379,0,0,0,1.536-9.1,1.955,1.955,0,0,0,.208-9.488h-2ZM.352-1.84a2.125,2.125,0,0,0,1.376-.392,1.391,1.391,0,0,0,.48-1.144,1.428,1.428,0,0,0-.488-1.16A2.1,2.1,0,0,0,.336-4.944H-1.792v3.1Z"
      transform="translate(4.048 11.296)"
      fill="currentColor"
    />
  </svg>
);

export default SvgPinTitleIcon;
