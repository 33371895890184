import * as React from 'react';

const SvgZoomOutIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15.816}
    height={18}
    viewBox="0 0 15.816 18"
    {...props}
  >
    <g id="Zoom_-" data-name="Zoom -" transform="translate(20250.818 -1902)">
      <path
        id="Path_102945"
        data-name="Path 102945"
        d="M20.8,17.993l-3.792-4.862a6.874,6.874,0,1,0-1.453,1.149l3.786,4.853a.928.928,0,0,0,1.305.156h0a.928.928,0,0,0,.153-1.3ZM12.6,13.63a5.3,5.3,0,1,1,4.715-4.715A5.3,5.3,0,0,1,12.6,13.63Z"
        transform="translate(-20256 1900.511)"
        fill="currentColor"
      />
      <path
        id="Path_102946"
        data-name="Path 102946"
        d="M23.562,16.688h-5.5c-.269,0-.486.3-.486.663s.218.663.486.663h5.5c.269,0,.486-.3.486-.663S23.83,16.688,23.562,16.688Z"
        transform="translate(-20264.746 1891.63)"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default SvgZoomOutIcon;
