import * as React from 'react';

const SvgShareIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17.273}
    height={20}
    viewBox="0 0 17.273 20"
    {...props}
  >
    <g id="Share" transform="translate(-255 -1000)">
      <g
        id="Ellipse_487"
        data-name="Ellipse 487"
        transform="translate(265 1000)"
        fill="none"
        stroke="currentColor"
        strokeWidth={2}
      >
        <circle cx={3.636} cy={3.636} r={3.636} stroke="none" />
        <circle cx={3.636} cy={3.636} r={2.636} fill="none" />
      </g>
      <g
        id="Ellipse_488"
        data-name="Ellipse 488"
        transform="translate(255 1006.364)"
        fill="none"
        stroke="currentColor"
        strokeWidth={2}
      >
        <circle cx={3.636} cy={3.636} r={3.636} stroke="none" />
        <circle cx={3.636} cy={3.636} r={2.636} fill="none" />
      </g>
      <g
        id="Ellipse_489"
        data-name="Ellipse 489"
        transform="translate(265 1012.727)"
        fill="none"
        stroke="currentColor"
        strokeWidth={2}
      >
        <circle cx={3.636} cy={3.636} r={3.636} stroke="none" />
        <circle cx={3.636} cy={3.636} r={2.636} fill="none" />
      </g>
      <line
        id="Line_1"
        data-name="Line 1"
        x1={5.455}
        y2={3.636}
        transform="translate(260.909 1005)"
        fill="none"
        stroke="currentColor"
        strokeWidth={2}
      />
      <line
        id="Line_2"
        data-name="Line 2"
        x2={5.455}
        y2={3.636}
        transform="translate(260.909 1011.364)"
        fill="none"
        stroke="currentColor"
        strokeWidth={2}
      />
    </g>
  </svg>
);

export default SvgShareIcon;
