import {
  CLOSE_IDLE,
  CLOSE_SIDEBAR,
  DOCUMENT_LOAD_FAILURE,
  OPEN_LEFT_SIDEBAR,
  OPEN_RIGHT_SIDEBAR,
  OPEN_IDLE,
  HIDE_PINS,
  HIDE_SEARCHES,
  HIDE_TRANSLATES,
  CLICK_SHARE,
  SELECT_SEARCH_RESULT,
  SET_BRIGHTNESS,
  SET_CONTRAST,
  SET_SATURATE,
  SET_GRAYSCALE,
  SET_SCALE,
  SET_SCALE_MODE,
  SET_SEARCH_FILTER,
  RESET_SEARCH,
  RESET_IDLE
} from 'store/actions/actionTypes';
import { DOCUMENT_DETAIL_FAILURE, DOCUMENT_DETAIL_SUCCESS } from 'store/actions/api/fetchDocumentDetail';
import { SEARCH_FAILURE, SEARCH_SUCCESS } from 'store/actions/api/fetchSearch';
import { REPORT_ERROR_FAILURE, REPORT_ERROR_SUCCESS } from 'store/actions/api/reportError';

import idleEvent from './idleEvent';
import searchEvent, { selectSearchEvent, resetSearchEvent } from './searchEvent';
import sidebarEvent from './sidebarEvent';
import adjustmentEvent from './adjustmentEvent';
import annonationEvent from './annonationEvent';
import pinEvent from './pinEvent';
import scaleEvent from './scaleEvent';
import shareEvent from './shareEvent';
import { apiErrorEvent, apiSuccessEvent } from './apiEvent';
import { CREATE_DOCUMENT_PIN_SUCCESS } from '../../actions/api/createDocumentPin';

const events = (action) => {
  switch (action.type) {
    case OPEN_LEFT_SIDEBAR:
    case OPEN_RIGHT_SIDEBAR:
    case CLOSE_SIDEBAR:
      return sidebarEvent;

    case OPEN_IDLE:
    case CLOSE_IDLE:
    case RESET_IDLE:
      return idleEvent;

    case DOCUMENT_DETAIL_SUCCESS:
    case SEARCH_SUCCESS:
    case REPORT_ERROR_SUCCESS:
      return apiSuccessEvent;

    case DOCUMENT_DETAIL_FAILURE:
    case DOCUMENT_LOAD_FAILURE:
    case SEARCH_FAILURE:
    case REPORT_ERROR_FAILURE:
      return apiErrorEvent;

    case HIDE_PINS:
    case HIDE_SEARCHES:
    case HIDE_TRANSLATES:
      return annonationEvent;

    case CREATE_DOCUMENT_PIN_SUCCESS:
      return pinEvent;

    case CLICK_SHARE:
      return shareEvent;

    case SELECT_SEARCH_RESULT:
      return selectSearchEvent;

    case SET_SEARCH_FILTER:
      return searchEvent;

    case SET_BRIGHTNESS:
    case SET_CONTRAST:
    case SET_SATURATE:
    case SET_GRAYSCALE:
      return adjustmentEvent;

    case SET_SCALE:
    case SET_SCALE_MODE:
      return scaleEvent;

    case RESET_SEARCH:
      return resetSearchEvent;
    default:
      return [];
  }
};

export default events;
