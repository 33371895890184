import React from 'react';
import { Flex, useColorModeValue } from '@chakra-ui/react';
import Loading from 'components/Loading';

function Fallback() {
  const color = useColorModeValue('#f3f4ff', 'gray.800');

  return (
    <Flex alignItems="center" justifyContent="center" h="100%" backgroundColor={color}>
      <Loading />
    </Flex>
  );
}

export default Fallback;
