import { DESELECT_PIN, SELECT_PIN } from './actionTypes';

export function selectPin(id, editing = false) {
  if (! id) return { type: DESELECT_PIN };

  return (dispatch, getState) => {
    const { pins } = getState();

    // pin'ler yoksa
    if (! pins.data.length)
      throw new Error('Seçim yapılacak pin bulunamadı.');

    // id'ye karşılık gelen pin'i bulalım.
    const pin = pins.data.find(h => h.id === id);

    // seçilecek pin bulunamadıysa
    if (! pin)
      throw new Error(`Pinler arasında "${id}" numaralı pin bulunamadığı için seçim yapılamadı.`);

    // pin seçelim.
    dispatch({
      type: SELECT_PIN,
      payload: { ...pin, editing: editing }
    });
  };
}
