import { createAction } from 'redux-api-middleware';
import i18n from 'i18n';

export const DOCUMENT_PINS_REQUEST = '@@api/DOCUMENT_PINS_REQUEST';
export const DOCUMENT_PINS_SUCCESS = '@@api/DOCUMENT_PINS_SUCCESS';
export const DOCUMENT_PINS_FAILURE = '@@api/DOCUMENT_PINS_FAILURE';

const fetchDocumentPins = (id) => createAction({
  endpoint: process.env.REACT_APP_API + `/documents/${id}/pins`,
  method: 'GET',
  credentials: 'include',
  headers: {
    'Accept-Language': i18n.language,
    'Content-Type': 'application/json'
  },
  types: [
    DOCUMENT_PINS_REQUEST,
    DOCUMENT_PINS_SUCCESS,
    DOCUMENT_PINS_FAILURE
  ]
});

export default fetchDocumentPins;
