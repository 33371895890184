import * as React from 'react';

const SvgDownloadIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16.041}
    height={20}
    viewBox="0 0 16.041 20"
    {...props}
  >
    <path
      id="Shape"
      d="M8.767,19.653a.986.986,0,0,1-1.525-.028C7.2,19.576.294,12.665.294,12.665a1.125,1.125,0,0,1,0-1.517,1.212,1.212,0,0,1,1.588,0l4.985,5.057V.994A1.124,1.124,0,0,1,8.015,0,1.155,1.155,0,0,1,9.194.994V16.205l5.028-5.217a1.128,1.128,0,0,1,1.524,0,1.342,1.342,0,0,1,0,1.677S8.8,19.609,8.767,19.653Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgDownloadIcon;
