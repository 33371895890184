import * as React from 'react';

const SvgRotateIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21.74}
    height={21}
    viewBox="0 0 21.74 21"
    {...props}
  >
    <path
      id="Rotate"
      d="M19.506,28.866a.554.554,0,0,1-.554.554h0a10,10,0,0,1-9.3-13.689A.824.824,0,0,1,9.7,15.62L8.7,14.951a.275.275,0,0,1,.035-.478l3.1-1.523a.275.275,0,0,1,.4.266l-.23,3.45a.275.275,0,0,1-.443.213l-.934-.629a8.893,8.893,0,0,0,8.322,12.064h0A.554.554,0,0,1,19.506,28.866ZM29.2,23.889l-1-.669a.824.824,0,0,0,.044-.111,10,10,0,0,0-9.3-13.689h0a.554.554,0,1,0,0,1.107h0a8.893,8.893,0,0,1,8.308,12.064l-.934-.629a.275.275,0,0,0-.443.213l-.217,3.446a.275.275,0,0,0,.4.266l3.1-1.523a.275.275,0,0,0,.035-.474Z"
      transform="translate(-8.081 -8.92)"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={1}
    />
  </svg>
);

export default SvgRotateIcon;
