import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { useIdleTimer } from 'react-idle-timer';
import { Box, useColorModeValue, useMediaQuery } from '@chakra-ui/react';

// Components
import i18n from 'i18n';
import Header from 'app/Header';
import Sidebar from 'app/Sidebar';
import Footer from 'app/Footer';

// UI
import styles from './App.module.scss';

function App() {
  const bg = useColorModeValue('#f8faff', 'gray.700');
  const idle = useSelector(state => state.app.idle);
  const sidebar = useSelector(state => state.app.sidebar);
  const documents = useSelector(state => state.documents.data);
  const isDocumentsReady = useSelector(state => state.app.documents.ready);
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [isIdleActive, setIdleActive] = useState(false);

  // add data attribute to html
  // when user in active
  useIdleTimer({
    timeout: idle.time,
    onIdle: () => (idle.status || isMobile) && setIdleActive(true),
    onActive: () => setIdleActive(false)
  });

  return (
    <Box
      backgroundColor={bg}
      className={styles.self}
      data-idle={isIdleActive}
      data-ready={isDocumentsReady}
      data-sidebar={sidebar.direction}>
      <Helmet>
        {documents.name &&
          <title>{documents.name} — {i18n.t('title')}</title>
        }
      </Helmet>
      <Header />
      <Outlet />
      <Sidebar />
      <Footer />
    </Box>
  );
}

export default App;
