import React, { Fragment } from 'react';
import { Flex, useColorModeValue } from '@chakra-ui/react';
import { useViewer } from 'components/PDFDocument';
import Loading from 'components/Loading';

// UI
import styles from './PDFLoading.module.scss';

function PDFLoading(props) {
  const { loaded } = useViewer();
  const color = useColorModeValue('#f3f4ff', 'gray.800');

  if (loaded) return <Fragment />;

  return (
    <Flex 
      className={styles.self} 
      backgroundColor={color}
      alignItems="center" 
      justifyContent="center" >
      <Loading {...props} />
    </Flex>
  );
}

export default PDFLoading;
