import { trackEvent } from '@redux-beacon/google-analytics-gtag';

const pinEvent = trackEvent((action) => ({
  action: 'add_pin',
  fieldsObject: {
    id: action.payload.data
  }
}));

export default pinEvent;
