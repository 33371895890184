import * as React from 'react';

const SvgPinIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={22}
    viewBox="0 0 17 22"
    {...props}
  >
    <g id="Pin" transform="translate(-63 1)">
      <path
        id="Path_24092"
        data-name="Path 24092"
        d="M71.5,0A7.508,7.508,0,0,0,64,7.5c0,5.2,6.77,12.029,7.058,12.318a.628.628,0,0,0,.885,0C72.23,19.529,79,12.7,79,7.5A7.508,7.508,0,0,0,71.5,0Z"
        fill="none"
        stroke="currentColor"
        strokeWidth={2}
      />
      <ellipse
        id="Ellipse_225"
        data-name="Ellipse 225"
        cx={2.852}
        cy={2.691}
        rx={2.852}
        ry={2.691}
        transform="translate(68.648 4.901)"
        fill="none"
        stroke="currentColor"
        strokeWidth={2}
      />
    </g>
  </svg>
);

export default SvgPinIcon;
