import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// UI
import styles from './Result.module.scss';

function Result({ pagenumber }) {
  const resultRef = useRef();
  const selectedRef = useCallback((node) => {
    if (! node) return;

    // scroll to selected polygon
    setTimeout(() => {
      node.scrollIntoView({ behavior: 'smooth', block: 'start'});
    }, 300);
  }, []);
  const results = useSelector(state => state.search.results.data);
  const hide_searches = useSelector(state => state.annonation.hide_searches);

  // local state
  const [data, setData] = useState([]);
  const [size, setSize] = useState({ height: 0, width: 0 });

  /**
   * Arama sonucunu mevcut ekrana göre hesaplar.
   *
   * @param  {integer} x
   * @param  {integer} y
   * @return {object}
   */
  const calculateSearchPosition = (x, y) => {
    return [
      x * (resultRef.current.clientWidth / size.width),
      y * (resultRef.current.clientHeight / size.height)
    ].join(' ');
  };

  useEffect(() => {
    if (! results.length) return setData([]);

    // aktif sayfanın arama sonuçlarını alalım.
    const pageResults = results.filter(r => r.pageNumber === pagenumber);
    if (! pageResults.length) return;

    // kaydedelim.
    setData(pageResults);
    setSize({
      height: pageResults[0].height,
      width: pageResults[0].width
    });
  }, [results]);

  // veri yoksa veya gizlenmesi isteniyorsa
  if (! data.length || hide_searches === true) {
    return <svg className={styles.self} ref={resultRef} />;
  }

  return (
    <svg className={styles.self} ref={resultRef}>
      {data.map((result, index) => {
        let coords = result.positions;

        // paragraf varsa
        if (result.paragraph) {
          const polygonTop = [];
          const polygonBottom = [];
          result.paragraph.words.map(w => {

            // arama sonucu sağdan sola yazılan bir dil ise
            if (result.isArabic) {
              polygonTop.push(...w.positions.slice(2, 4));
              polygonBottom.push(...w.positions.slice(0, 2).reverse());
            } else {
              polygonTop.push(...w.positions.slice(0, 2));
              polygonBottom.push(...w.positions.slice(2, 4).reverse());
            }

            return w;
          });
          coords = [...polygonTop, ...polygonBottom.reverse()];
        }

        if (! coords.length) return <React.Fragment />;

        return (
          <polygon points={coords.map(pos => calculateSearchPosition(...pos))}
            className={styles.item}
            tabIndex={index + 1}
            data-focus={result.selected}
            ref={result.selected
              ? selectedRef
              : undefined
            }
            key={index}
          />
        );
      })}
    </svg>
  );
}

Result.propTypes = {
  pagenumber: PropTypes.number.isRequired
};

export default Result;
