import React, { useCallback, useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Rnd } from 'react-rnd';
import { isEmpty } from 'lodash';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  useDisclosure,
  PopoverHeader,
  Box,
  Text,
  Tooltip,
  PopoverBody,
  Image
} from '@chakra-ui/react';
import { WarningIcon } from '@chakra-ui/icons';

// Actions
import { API } from 'store/actions';

// Components
import i18n from 'i18n';
import GetSelectionForm from './GetSelectionForm';
import PinCategories from 'components/PDFDocument/PinCategories';

// UI
import styles from './index.module.scss';

function SelectionArea({ page, start, end, type = 'translate', locked, editing, onClear }) {
  const dispatch = useDispatch();
  const pin = useSelector(state => state.pin);
  const documentId = useSelector(state => state.documents.data.documentId);

  // local state
  const [size, setSize] = useState({});
  const [position, setPosition] = useState({});
  const [image, setImage] = useState('');
  const [formValues, setFormValues] = useState({});
  const { onOpen, onClose, isOpen } = useDisclosure();

  const boundariedCoordinate = (x, y) => {
    const { height, width } = page.div.getBoundingClientRect();

    // boundaries
    const minLeft = page.div.offsetLeft;
    const maxLeft = page.div.offsetLeft + width - Math.abs(end.x - start.x);
    const minTop = page.div.offsetTop;
    const maxTop = page.div.offsetTop + height - Math.abs(end.y - start.y);

    // position
    let left = x;
    let top = y;

    // check our horizontal boundaries
    if (left < minLeft) {
      left = minLeft;
    } else if (left > maxLeft) {
      left = maxLeft;
    }

    // check our vertical boundaries
    if (top < minTop) {
      top = minTop;
    } else if (top > maxTop) {
      top = maxTop;
    }

    return {
      x: left,
      y: top
    };
  };

  // handling the React-rnd drag event
  const handleDragStop = (e, d) => {
    const {x, y} = boundariedCoordinate(d.x, d.y);

    // save position
    return setPosition({
      x: x,
      y: y
    });
  };

  // handling the React-rnd resize event
  const handleResizeStop = (e, direction, ref, delta, position) => {
    setSize({
      width: parseInt(ref.style.width),
      height: parseInt(ref.style.height)
    });
    setPosition(position);
  };

  // handle submit
  const handleSubmit = (values) => {
    const pageNumber = page.pdfPage.pageNumber;
    const viewport = page.viewport;

    // state'i sıfırlayalım.
    setSize({});
    setPosition({});
    onClose(true);

    // veriyi hazırlayalım.
    const payload = {
      page: pageNumber,
      type: type,
      content: values.content,
      image: image || values.image,
      ...values,
      position: [
        ...viewport.convertToPdfPoint(
          position.x - page.div.offsetLeft,
          position.y - page.div.offsetTop
        ),
        ...viewport.convertToPdfPoint(
          (position.x + size.width) - page.div.offsetLeft,
          (position.y + size.height) - page.div.offsetTop
        )
      ]
    };

    // pin ekle/güncelle işlemi için 
    // servise istek atalım.
    dispatch(editing
      ? API.updateDocumentPin(values.id, payload)
      : API.createDocumentPin(payload)
    ).then(() => dispatch(API.fetchDocumentPins(documentId)));

    // state'i sıfırlayalım.
    if (editing) {
      setFormValues({});
    }

    // parent state'i sıfırlayalım.
    if (onClear) {
      onClear();
    }
  };

  const handleClose = () => {
    onClose();

    // parent state'i sıfırlayalım.
    if (onClear) {
      onClear();
    }
  };

  const screenshot = useCallback((size, position) => {
    if (! page) return;

    const canvas = page.div.querySelector('canvas');
    const drawCanvas = document.createElement('canvas');
    const drawContext = drawCanvas.getContext('2d');

    // size
    drawCanvas.height = size.height;
    drawCanvas.width = size.width;

    drawContext.drawImage(
      canvas,
      (position.x - page.div.offsetLeft),
      (position.y - page.div.offsetTop),
      size.width,
      size.height,
      0,
      0,
      size.width,
      size.height
    );

    return drawCanvas.toDataURL('image/png');
  }, [page, size, position]);

  /**
   * Props tanımlandığında lokal state'i günceller.
   *
   * @param {boolean} locked
   * @param {object} start
   * @param {object} end
   */
  useEffect(() => {
    if (! locked) return;
    if (isEmpty(start)) return;
    if (isEmpty(end)) return;

    setSize({
      height: Math.abs(end.y - start.y),
      width: Math.abs(end.x - start.x)
    });

    setPosition(boundariedCoordinate(
      Math.min(start.x, end.x),
      Math.min(start.y, end.y)
    ));
  }, [start, end, locked]);

  /**
   * Boyut ve koordinat değiştiğinde form alanını açar ve
   * selection alanının görselini çeker.
   *
   * @param  {object} size
   * @param  {object} position
   */
  useEffect(() => {
    if (isEmpty(size)) return;
    if (isEmpty(position)) return;

    setImage(screenshot(size, position));

    onOpen(true);
  }, [size, position]);

  /**
   * Düzenleme varsa varsayılan form değerlerini tanımlar.
   *
   * @param  {boolean} editing
   * @param  {object} pin
   */
  useEffect(() => {
    if (! editing) return;
    if (isEmpty(pin)) return;

    setFormValues(pin);
  }, [editing, pin]);

  // loading
  if (isEmpty(start) || isEmpty(end)) {
    return <div className={styles.self} />;
  }

  // active selection area
  if (! locked || isEmpty(size) || isEmpty(position)) {
    return (
      <div className={styles.self}
        style={{
          left: Math.min(start.x, end.x),
          top: Math.min(start.y, end.y),
          height: Math.abs(end.y - start.y) + 'px',
          width: Math.abs(end.x - start.x) + 'px'
        }} />
    );
  }

  return (
    <Fragment>
      <Rnd className={styles.self}
        style={{ zIndex: 9999 }}
        size={{
          height: size.height,
          width: size.width
        }}
        position={{
          x: position.x,
          y: position.y
        }}
        onDragStop={handleDragStop}
        onResizeStop={handleResizeStop}
      />
      <Popover
        isOpen={isOpen}
        placement="auto"
        onOpen={onOpen}
        onClose={onClose}>
        <PopoverTrigger>
          <span className={styles.transparent} style={{
            left: position.x + 'px',
            top: position.y + 'px',
            height: size.height + 'px',
            width: size.width + 'px',
            zIndex: 9
          }} />
        </PopoverTrigger>
        <PopoverContent cursor="default">
          <PopoverArrow />
          <PopoverHeader display="flex" justifyContent="space-between" alignItems="center">
            <Box>
              <Text as="span" fontWeight="bold" mr={1}>{i18n.t(`pin.type.${type}.title`)} {i18n.t('form.add')}</Text>
              <Tooltip label={i18n.t(`pin.type.${type}.description`)} placement="right" hasArrow>
                <WarningIcon w={4} h={4} color="gray.400" />
              </Tooltip>
            </Box>
            <PinCategories type={type} />
          </PopoverHeader>
          <PopoverBody py={4}>
            <GetSelectionForm 
              type={type} 
              initialValues={formValues}
              onSubmit={handleSubmit} 
              onClose={handleClose}>
              {image && 
                <Image 
                  src={image} 
                  borderRadius="md" 
                  mx="auto" 
                  mb={3} 
                  maxHeight="200" />
              }
            </GetSelectionForm>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Fragment>
  );
}

SelectionArea.propTypes = {
  page: PropTypes.object.isRequired,
  start: PropTypes.object.isRequired,
  end: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  locked: PropTypes.bool,
  editing: PropTypes.bool,
  onClear: PropTypes.func
};

export default SelectionArea;
