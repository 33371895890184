import { trackEvent } from '@redux-beacon/google-analytics-gtag';

const idleEvent = trackEvent((action, prevState, nextState) => ({
  action: 'idle',
  fieldsObject: {
    idle_status: nextState.app.idle.status,
    idle_time: nextState.app.idle.time
  }
}));

export default idleEvent;
