import * as React from 'react';

const SvgFullsizeIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18.2}
    height={18.2}
    viewBox="0 0 18.2 18.2"
    {...props}
  >
    <path
      id="Path_103809"
      data-name="Path 103809"
      d="M87.774,17.784a.743.743,0,0,1-.526.219H82.241a.75.75,0,0,1,0-1.5h4.253V12.249a.75.75,0,0,1,1.5,0v5.007a.743.743,0,0,1-.217.527Zm0-17.564A.743.743,0,0,0,87.248,0H82.241a.75.75,0,0,0,0,1.5h4.253V5.756a.75.75,0,0,0,1.5,0V.75a.743.743,0,0,0-.219-.526ZM70.214,17.786a.743.743,0,0,0,.527.217h5.007a.75.75,0,0,0,0-1.5H71.494V12.25a.75.75,0,0,0-1.5,0v5.007a.743.743,0,0,0,.219.526Zm0-17.563a.743.743,0,0,0-.217.527V5.756a.75.75,0,0,0,1.5,0V1.5h4.253a.75.75,0,0,0,0-1.5H70.741a.743.743,0,0,0-.526.219Z"
      transform="translate(-69.894 0.097)"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.2}
      fillRule="evenodd"
    />
  </svg>
);

export default SvgFullsizeIcon;
