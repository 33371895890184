import * as React from 'react';

const SvgPinDirectoryIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={9.936}
    height={11.296}
    viewBox="0 0 9.936 11.296"
    style={{
      color: '#fff',
    }}
    {...props}
  >
    <path
      id="text"
      d="M5.144-5.664a6.043,6.043,0,0,1-.7,2.96A4.9,4.9,0,0,1,2.4-.712,6.664,6.664,0,0,1-.776,0H-4.792V-11.3H-.776a6.79,6.79,0,0,1,3.176.7A4.814,4.814,0,0,1,4.44-8.632,6.031,6.031,0,0,1,5.144-5.664ZM-.968-2.016a3.913,3.913,0,0,0,2.824-.96,3.55,3.55,0,0,0,1-2.688,3.572,3.572,0,0,0-1-2.7,3.913,3.913,0,0,0-2.824-.96H-2.536v7.312Z"
      transform="translate(4.792 11.296)"
      fill="currentColor"
    />
  </svg>
);

export default SvgPinDirectoryIcon;
