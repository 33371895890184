import React, { useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { isEmpty } from 'lodash';
import {
  Button,
  ButtonGroup,
  Box,
  FormLabel,
  FormControl,
  FormErrorMessage,
  Text,
  Textarea,
  useColorModeValue
} from '@chakra-ui/react';

// Components
import i18n from 'i18n';

function TranslateForm({ initialValues = {}, children, onSubmit, onClose }) {
  const firstFieldRef = useRef(null);
  const description = i18n.t('pin.type.translate.description');

  // color
  const alertBg = useColorModeValue('#F8FAFF', 'gray.400');
  const alertBorderColor = useColorModeValue('#D9E1F0', 'gray.340');
  const alertTitleColor = useColorModeValue('#021B62', 'gray.900');
  const alertColor = useColorModeValue('#5B6F8E', 'gray.700');

  // handle formik validation
  const handleValidation = (values) => {
    let errors = {};

    if (! values.content) {
      errors.content = i18n.t('form.validation.null', {
        field: i18n.t('pin.type.translate.title')
      });
    } else if (values.content.length < 2) {
      errors.content = i18n.t('form.validation.minChar', {
        field: i18n.t('pin.type.translate.title'),
        number: 2
      });
    }

    return errors;
  };

  // handle submit
  const handleSubmit = (values, actions) => {
    actions.setSubmitting(false);

    // callback
    if (onSubmit) {
      onSubmit(values);
    }
  };

  return (
    <Formik
      initialValues={{
        content: '',
        image: '',
        ...initialValues
      }}
      enableReinitialize={true}
      validate={handleValidation}
      onSubmit={handleSubmit}>
      {({
        dirty,
        values,
        errors,
        touched,
        isValid,
        handleBlur,
        handleChange
      }) => (
        <Form>
          <FormControl variant="floating" isInvalid={(errors.content && touched.content) && true} mb={3} isRequired>
            <Textarea
              id="content"
              value={values.content}
              placeholder=" "
              size="sm"
              rows="6"
              variant="unstyled"
              resize="vertical"
              fontSize="sm"
              onBlur={handleBlur}
              onChange={handleChange}
              ref={firstFieldRef}
            />
            <FormLabel htmlFor="content" 
              fontSize="sm" 
              fontWeight="normal" 
              style={{ marginLeft: 0, paddingLeft: 0 }}
            >{i18n.t('pin.type.translate.label')}</FormLabel>
            {errors.content && touched.content
              ? <FormErrorMessage>{errors.content}</FormErrorMessage>
              : <Fragment />
            }
          </FormControl>
          {children}
          <Box 
            px={4} py={3} mb={5} rounded="md" 
            bg={alertBg} 
            borderColor={alertBorderColor}
            borderWidth={1}
            color={alertColor}
            textAlign="left" fontSize="sm">
            <Text 
              color={alertTitleColor} 
              fontWeight={600}>{i18n.t('pin.type.translate.whatis')}</Text>
            <Text fontSize="xs">{description}</Text>
          </Box>
          <ButtonGroup width="100%" gap={1} mb={1}>
            <Button 
              width="50%" 
              size="lg" 
              fontSize="sm" 
              variant="outline" 
              onClick={onClose}>{i18n.t('form.cancel')}</Button>
            <Button 
              width="50%" 
              size="lg" 
              fontSize="sm" 
              colorScheme="blue" 
              type="submit" 
              disabled={(
                !dirty && isEmpty(initialValues)) || 
                !isValid
              }>{i18n.t('form.save')}</Button>
          </ButtonGroup>
        </Form>
      )}
    </Formik>
  );
}

TranslateForm.propTypes = {
  initialValues: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.bool
  ]),
  onSubmit: PropTypes.func,
  onClose: PropTypes.func
};

export default TranslateForm;
