import React from 'react';
import {
  Button,
  Icon,
  useColorMode,
  useColorModeValue
} from '@chakra-ui/react';

// UI
import styles from './DarkModeButton.module.scss';
import { DarkModeIcon } from 'components/Icons';

function DarkModeButton(props) {
  const { toggleColorMode } = useColorMode();
  const color = useColorModeValue('#021b62', 'white');

  return (
    <Button className={styles.self}
      colorScheme="teal"
      variant="link"
      minHeight={{ base: 66, md: 14 }}
      size={{ base: 'lg', md: 'sm' }}
      {...props}
      onClick={toggleColorMode}>
      <Icon as={DarkModeIcon} h={5} w={5} color={color} className="sun-and-moon" />
    </Button>
  );
}

export default DarkModeButton;
