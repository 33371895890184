import * as React from 'react';
import PropTypes from 'prop-types';
import { useToken } from '@chakra-ui/react';

const SvgMenuCaretRightIcon = ({ color, borderColor, ...props }) => {
  const [backgroundFill, borderFill] = useToken('colors', [color, borderColor]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 16"
      width={12}
      height={16}
      {...props}
    >
      <path
        fill={borderFill}
        d="m11.8 8.4l-8.7 7.5c-0.3 0.2-0.8 0-0.8-0.5v-14.8c0-0.5 0.5-0.7 0.8-0.5l8.7 7.5c0.3 0.2 0.3 0.6 0 0.8z"
      />
      <path
        fill={backgroundFill}
        d="m9.5 8.4l-8.7 7.5c-0.3 0.2-0.8 0-0.8-0.5v-14.8c0-0.5 0.5-0.7 0.8-0.5l8.7 7.5c0.3 0.2 0.3 0.6 0 0.8z"
      />
    </svg>
  );
};

SvgMenuCaretRightIcon.propTypes = {
  color: PropTypes.string,
  borderColor: PropTypes.string
};

export default SvgMenuCaretRightIcon;
