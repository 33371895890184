import { ADD_THUMBNAIL, UPDATE_THUMBNAIL } from '../actions/actionTypes';

const initialState = [];

const thumbnails = (state = initialState, action) => {
  switch (action.type) {
    case ADD_THUMBNAIL:
      return [ ...state, action.payload ];
    case UPDATE_THUMBNAIL:
      return state.map(t => {
        if (t.page === action.payload.page) return { ...t, ...action.payload };
        return t;
      });
    default:
      return state;
  }
};

export default thumbnails;
