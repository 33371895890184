import * as React from 'react';
import PropTypes from 'prop-types';

const SvgKeyboardIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={12.466}
    viewBox="0 0 28 12.466"
    {...props}
  >
    <path
      id="Klavye"
      d="M26.657,0H1.6C.452,0,.013.438.013,1.584v9.3c0,1.146.439,1.584,1.584,1.584h25.06c1.145,0,1.356-.438,1.356-1.584v-9.3C28.013.438,27.8,0,26.657,0ZM23.885,2.138h2.008V4.145H23.885Zm-6.225,0h2.008V4.145H17.659ZM20.7,5.251V7.258H18.7V5.251ZM14.547,2.138h2.008V4.145H14.547Zm3.045,3.113V7.258H15.584V5.251ZM11.434,2.138h2.008V4.145H11.434Zm3.045,3.113V7.258H12.472V5.251ZM8.322,2.138h2.008V4.145H8.322Zm3.045,3.113V7.258H9.359V5.251ZM5.209,2.138H7.217V4.145H5.209Zm-3.113,0H4.1V4.145H2.1Zm0,3.113H5.2V7.258H2.1Zm4.1,5.118H2.067V8.364H6.195Zm.052-5.118H8.254V7.258H6.247ZM20.72,10.369H7.271V8.364H20.72Zm.052-8.231H22.78V4.145H20.772Zm5.136,8.231H21.781V8.364h4.127Zm.03-3.112H21.81V5.251h4.128Z"
      transform="translate(-0.013)"
      fill={props.color}
      fillRule="evenodd"
    />
  </svg>
);

SvgKeyboardIcon.propTypes = {
  color: PropTypes.string
};

export default SvgKeyboardIcon;
