import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ContextMenuContext } from './ContextMenu';
import { motion } from 'framer-motion';
import {
  Box,
  CloseButton,
  useColorModeValue,
  useOutsideClick
} from '@chakra-ui/react';

const CustomMotionBox = motion(Box);

const motionVariants = {
  enter: {
    visibility: 'visible',
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.2,
      ease: [0.4, 0, 0.2, 1],
    },
  },
  exit: {
    transitionEnd: {
      visibility: 'hidden',
    },
    opacity: 0,
    scale: 0.8,
    transition: {
      duration: 0.1,
      easings: 'easeOut',
    },
  },
};

function ContextMenuBody({ children }) {
  const bg = useColorModeValue('white', 'gray.700');
  const color = useColorModeValue('#021b62', 'white');
  const borderColor = useColorModeValue('#d9e1f0', 'white');
  const {
    closeMenu,
    isOpen,
    menuRef,
    position: { x, y },
  } = useContext(ContextMenuContext);

  const [position, setPosition] = useState({});

  // TODO: Any less manual way to do this
  useEffect(() => {
    let left;
    let right;
    let top;
    let bottom;
    const menuHeight = menuRef?.current?.clientHeight;
    const menuWidth = menuRef?.current?.clientWidth;
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    if (!menuHeight || !menuWidth) {
      return;
    }
    if (x + menuWidth > windowWidth) {
      right = windowWidth - x;
    } else {
      left = x;
    }
    if (y + menuHeight > windowHeight) {
      bottom = windowHeight - y;
    } else {
      top = y;
    }
    setPosition({
      top: `${top}px`,
      bottom: `${bottom}px`,
      left: `${left}px`,
      right: `${right}px`,
    });
  }, [menuRef, x, y]);

  useOutsideClick({
    ref: menuRef,
    handler: () => {
      if (isOpen) {
        closeMenu();
      }
    },
  });

  return (
    <CustomMotionBox
      variants={motionVariants}
      animate={isOpen ? 'enter' : 'exit'}
      ref={menuRef}
      borderWidth={2}
      position="fixed"
      bg={bg}
      pl={5}
      pr={10}
      minW={40}
      borderRadius={5}
      alignItems="top"
      zIndex={1000}
      style={{visibility: 'hidden'}}
      {...position}>
      {children}
      <CloseButton border="1px" borderColor={borderColor} borderRadius="50" color={color} 
        onClick={closeMenu}
        size="sm" 
        sx={{
          position: 'absolute',
          right: '8px',
          top: '8px'
        }} />
    </CustomMotionBox>
  );
}

ContextMenuBody.propTypes = {
  children: PropTypes.element.isRequired
};

export default ContextMenuBody;
