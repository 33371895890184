import * as React from 'react';

const SvgPrevIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={7.346}
    height={12.5}
    viewBox="0 0 7.346 12.5"
    {...props}
  >
    <path
      id="Next"
      d="M0,6a.772.772,0,0,1,.054-.293.677.677,0,0,1,.184-.249L5.6.2A.711.711,0,0,1,6.116,0a.759.759,0,0,1,.369.09.668.668,0,0,1,.264.26.722.722,0,0,1-.127.886L1.742,6l4.88,4.764a.727.727,0,0,1,.127.889.675.675,0,0,1-.264.257.759.759,0,0,1-.369.09.711.711,0,0,1-.513-.2L.239,6.542a.677.677,0,0,1-.184-.249A.772.772,0,0,1,0,6Z"
      transform="translate(0.25 0.25)"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.5}
    />
  </svg>
);

export default SvgPrevIcon;
