import * as React from 'react';

const SvgEmailIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-mail"
    width={44}
    height={44}
    viewBox="0 0 24 24"
    strokeWidth={2}
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path d="M0 0h24v24H0z" fill="none" stroke="none" />
    <rect x={3} y={5} width={18} height={14} rx={2} />
    <polyline points="3 7 12 13 21 7" />
  </svg>
);

export default SvgEmailIcon;
