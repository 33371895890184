import { createAction } from 'redux-api-middleware';
import i18n from 'i18n';

export const DOWNLOAD_FILE_REQUEST = '@@api/DOWNLOAD_FILE_REQUEST';
export const DOWNLOAD_FILE_SUCCESS = '@@api/DOWNLOAD_FILE_SUCCESS';
export const DOWNLOAD_FILE_FAILURE = '@@api/DOWNLOAD_FILE_FAILURE';

const downloadFile = (key) => createAction({
  endpoint: process.env.REACT_APP_API + '/read/DownloadFile',
  method: 'POST',
  credentials: 'include',
  headers: {
    'Accept-Language': i18n.language,
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({
    downloadKey: key
  }),
  types: [
    DOWNLOAD_FILE_REQUEST,
    {
      type: DOWNLOAD_FILE_SUCCESS,
      payload: (action, state, res) => {
        const contentType = res.headers.get('Content-Type');
        if (contentType && ~contentType.indexOf('pdf')) {
          return res.blob();
        }
      }
    },
    DOWNLOAD_FILE_FAILURE
  ]
});

export default downloadFile;
