import { trackEvent } from '@redux-beacon/google-analytics-gtag';

const sidebarEvent = trackEvent((action, prevState, nextState) => ({
  action: 'sidebar',
  fieldsObject: {
    sidebar_name: action.payload,
    sidebar_status: nextState.app.sidebar.status,
    sidebar_direction: nextState.app.sidebar.direction
  }
}));

export default sidebarEvent;
