import * as React from 'react';
import PropTypes from 'prop-types';

function Logo({ color1 = '#0027ff', color2 = '#3656fe', ...props }) {
  if (process.env.REACT_APP_TYPE !== 'viewer') {
    return <img src={`/${process.env.REACT_APP_TYPE}/logo.svg`} {...props} />;
  }

  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 26"
      {...props}>
      <g id="_0027ffff" data-name={color1}>
        <path
          fill={color1}
          d="M3.26.13a4.41,4.41,0,0,1,5.19,3,9.31,9.31,0,0,1-1.56.37v.35a7.6,7.6,0,0,1,1,.38c.27.25.06.63,0,.93l-2,0a10.48,10.48,0,0,0,0,2,3.32,3.32,0,0,0,2.2,0A11.42,11.42,0,0,1,9.32,5.35c.32.74.55,1.5.86,2.24a5,5,0,0,0-2,.3,9,9,0,0,1-1.51.53c-.61.17-.85.82-1.24,1.25s-1.17.8-1.29,1.52a10.1,10.1,0,0,0-.13,4.06C3.23,13.18,2.43,11.1,1.58,9,1.18,7.74.5,6.55.16,5.24A4.12,4.12,0,0,1,3.26.13Z"
        />
        <path
          fill={color1}
          d="M32.27,2a14.78,14.78,0,0,0,1.34,1c.72.21,1.59-.09,2.22.44a13.23,13.23,0,0,1,1.44,1.36c.65.75.29,1.85.73,2.69a1.32,1.32,0,0,0,1,.57,6.78,6.78,0,0,1,1-1.79A11,11,0,0,1,37,13a6,6,0,0,1-3.25,1.74,1,1,0,0,1-.88-.77c.07-.38.44-.56.72-.77a3.32,3.32,0,0,0,1.44-2,9.52,9.52,0,0,0,.15-2.82,4,4,0,0,1-3.27-1.54L32,6.79a15.23,15.23,0,0,1,2.87-1.6c.57-.14.46-.84.06-1.11-.45-.53-1.18-.42-1.8-.52A2.93,2.93,0,0,1,32.27,2Z"
        />
        <path
          fill={color1}
          d="M22.34,4.39a.47.47,0,0,1,.55-.15c.46.57-.11,1.41.38,2a1.65,1.65,0,0,1,.26,1.53,1.53,1.53,0,0,1-1.48.18c-.06-.78-.25-1.54-.34-2.31C21.6,5.09,22,4.71,22.34,4.39Z"
        />
        <path
          fill={color1}
          d="M16.9,4.41A3.56,3.56,0,0,1,19.37,5,1.09,1.09,0,0,1,20.06,6c-.17.41-.68.32-1,.32a13.43,13.43,0,0,0-2.83-.09,3.32,3.32,0,0,0,.36,1.51c.66.08,1.33.16,2,.21l0,1a10.51,10.51,0,0,1-2.68.32c-.32-.7-.54-1.43-.81-2.15l0-.07a2.49,2.49,0,0,1,1.15-1.3C16.43,5.34,16.38,4.67,16.9,4.41Z"
        />
        <path
          fill={color1}
          d="M22.28,10a14.89,14.89,0,0,0,1.34,1,10.94,10.94,0,0,1,2.27.35c.4,1.24,1.07,2.38,1.42,3.64.66,1.52,1.2,3.09,1.85,4.62.14.46.45.91.36,1.4a22.48,22.48,0,0,1-3.19,4.87,3.26,3.26,0,0,1-2.07-.07,4.24,4.24,0,0,1-2.71-2.19A2.29,2.29,0,0,0,22,21.44a1.9,1.9,0,0,0-.54.07,4.84,4.84,0,0,0-.31.93c-.43-1-.85-1.93-1.18-2.92.59-.63,1.33-1.12,1.91-1.76a7.23,7.23,0,0,0,.92-2.55c-.94.29-1.76,1-2.81.85a4,4,0,0,0,0-1.89c-.47-1.19-1.37-2.27-1.34-3.62,1.11.92,1.33,2.43,1.93,3.67.15.26.24.63.55.75a5.93,5.93,0,0,0,2.49-1.25c.5-.31,1.1-.42,1.58-.77.3-.34,0-.79-.31-1-.46-.46-1.14-.34-1.71-.45A2.87,2.87,0,0,1,22.28,10Z"
        />
        <path
          fill={color1}
          d="M4.38,16a9.43,9.43,0,0,1,1.49,1.18,2.51,2.51,0,0,0,2.59.45,2.66,2.66,0,0,1,2.09,0c.7.5.84,1.41,1.13,2.16.18.55.6,1,.75,1.56-.09.64-.56,1.18-.56,1.84a4.55,4.55,0,0,0,1,2.46,4,4,0,0,1-2.4.25,4.24,4.24,0,0,1-3.27-2.68C6.27,20.79,5.23,18.42,4.38,16Z"
        />
      </g>
      <g id="_3656feff" data-name={color2}>
        <path
          fill={color2}
          d="M17.36.17a4.31,4.31,0,0,1,5.1,2.47c1.16,2.89,2.28,5.79,3.43,8.69A10.94,10.94,0,0,0,23.62,11a14.89,14.89,0,0,1-1.34-1,2.87,2.87,0,0,0,.85,1.54c.57.11,1.25,0,1.71.45.28.22.61.67.31,1-.48.35-1.08.46-1.58.77A5.93,5.93,0,0,1,21.08,15c-.31-.12-.4-.49-.55-.75-.6-1.24-.82-2.75-1.93-3.67,0,1.35.87,2.43,1.34,3.62a4,4,0,0,1,0,1.89c1.05.17,1.87-.56,2.81-.85a7.23,7.23,0,0,1-.92,2.55c-.58.64-1.32,1.13-1.91,1.76-.35-.89-.79-1.75-1.05-2.67-.25,0-.63-.07-.62.3a17.15,17.15,0,0,1-2,5.45,7.1,7.1,0,0,1-3.43,3,4.55,4.55,0,0,1-1-2.46c0-.66.47-1.2.56-1.84-.15-.57-.57-1-.75-1.56-.29-.75-.43-1.66-1.13-2.16a2.66,2.66,0,0,0-2.09,0,2.51,2.51,0,0,1-2.59-.45A9.43,9.43,0,0,0,4.38,16a2.05,2.05,0,0,1-.33-.74,10.1,10.1,0,0,1,.13-4.06c.12-.72.89-1,1.29-1.52S6.1,8.59,6.71,8.42a9,9,0,0,0,1.51-.53,5,5,0,0,1,2-.3c.46.83.59,1.8,1.11,2.61.87,2.55,1.94,5,2.9,7.56a2.6,2.6,0,0,0,.61,1.16,7.44,7.44,0,0,0,.68-2.11,21,21,0,0,0,.56-5,10.87,10.87,0,0,0-.13-2.48A10.51,10.51,0,0,0,18.59,9l0-1c-.67,0-1.34-.13-2-.21a3.32,3.32,0,0,1-.36-1.51A13.43,13.43,0,0,1,19,6.34c.35,0,.86.09,1-.32A1.09,1.09,0,0,0,19.37,5a3.56,3.56,0,0,0-2.47-.54c-.52.26-.47.93-.62,1.41a2.49,2.49,0,0,0-1.15,1.3,5.43,5.43,0,0,1-.77-4,4,4,0,0,1,3-2.95m5,4.22c-.33.32-.74.7-.63,1.21.09.77.28,1.53.34,2.31a1.53,1.53,0,0,0,1.48-.18,1.65,1.65,0,0,0-.26-1.53c-.49-.55.08-1.39-.38-2A.47.47,0,0,0,22.34,4.39Z"
        />
        <path
          fill={color2}
          d="M31.26,5.52C30.34,3.12,32.28,0,35,.08a4.29,4.29,0,0,1,3.84,1.85,5.93,5.93,0,0,1,1,2.43,5,5,0,0,1,.12,1.95A6.78,6.78,0,0,0,39,8.1a1.32,1.32,0,0,1-1-.57c-.44-.84-.08-1.94-.73-2.69a13.23,13.23,0,0,0-1.44-1.36C35.2,3,34.33,3.25,33.61,3a14.78,14.78,0,0,1-1.34-1,2.93,2.93,0,0,0,.84,1.54c.62.1,1.35,0,1.8.52.4.27.51,1-.06,1.11A15.23,15.23,0,0,0,32,6.79C31.53,6.58,31.45,6,31.26,5.52Z"
        />
        <path
          fill={color2}
          d="M6.89,3.5a9.31,9.31,0,0,0,1.56-.37c.26.75.57,1.48.87,2.22A11.42,11.42,0,0,0,8.11,7.16a3.32,3.32,0,0,1-2.2,0,10.48,10.48,0,0,1,0-2l2,0c0-.3.24-.68,0-.93a7.6,7.6,0,0,0-1-.38Z"
        />
        <path
          fill={color2}
          d="M29.52,21l.14-.07A4.55,4.55,0,0,1,28,25.14a4.15,4.15,0,0,1-1.7.72A22.48,22.48,0,0,0,29.52,21Z"
        />
        <path
          fill={color2}
          d="M21.44,21.51a1.9,1.9,0,0,1,.54-.07,2.29,2.29,0,0,1-.43,2.16,4.49,4.49,0,0,1-.42-1.16A4.84,4.84,0,0,1,21.44,21.51Z"
        />
      </g>
    </svg>
  );
}

Logo.propTypes = {
  color1: PropTypes.string,
  color2: PropTypes.string
};

export default Logo;
