import { customAlphabet } from 'nanoid';
import { createAction } from 'redux-api-middleware';
import i18n from 'i18n';

export const SEARCH_REQUEST = '@@api/SEARCH_REQUEST';
export const SEARCH_SUCCESS = '@@api/SEARCH_SUCCESS';
export const SEARCH_FAILURE = '@@api/SEARCH_FAILURE';

const nanoid = customAlphabet('1234567890abcdef', 10);

const fetchSearch = (values) => (dispatch, getState) => {
  const { documents } = getState();

  const documentId = values.documentId || documents.data.documentId;
  if (! documentId) {
    return {
      error: true,
      payload: {
        message: 'DocumentId bulunamadı.'
      }
    };
  }

  // aranacak metin tırnak işareti içindeyse
  // içindeki metini gönderelim.
  let search = values.q;
  if (search.match(/^"(.{1,})"$/g)) {
    search = search.replace(/^"(.{1,})"$/g, '$1');
  }

  return dispatch(createAction({
    endpoint: process.env.REACT_APP_API + '/read/search',
    method: 'POST',
    credentials: 'include',
    headers: {
      'Accept-Language': i18n.language
    },
    body: new URLSearchParams({
      documentId: documentId,
      search: search,
      isFullKeyword: values.isFullKeyword,
    }),
    types: [
      SEARCH_REQUEST,
      {
        type: SEARCH_SUCCESS,
        payload: (action, state, res) => res.json().then(results =>
          results.map((res) => {
            return {
              ...res,
              id: nanoid(),
              page: parseInt(res.page.replace('0_', '')),
              selected: false
            };
          })
        )
      },
      SEARCH_FAILURE
    ]
  }));
};

export default fetchSearch;
