import * as React from 'react';
import PropTypes from 'prop-types';

const SvgArrowRightIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={14.437}
    viewBox="0 0 18 14.437"
    {...props}
  >
    <path
      id="Shape"
      d="M7.89,17.687a.887.887,0,0,1-1.373-.025C6.482,17.618.265,11.4.265,11.4a1.012,1.012,0,0,1,0-1.365,1.091,1.091,0,0,1,1.429,0L6.18,14.585V.895A1.012,1.012,0,0,1,7.214,0,1.039,1.039,0,0,1,8.275.895v13.69L12.8,9.89a1.015,1.015,0,0,1,1.371,0,1.207,1.207,0,0,1,0,1.509S7.924,17.648,7.89,17.687Z"
      transform="translate(0 14.437) rotate(-90)"
      fill={props.color}
    />
  </svg>
);

SvgArrowRightIcon.propTypes = {
  color: PropTypes.string
};

export default SvgArrowRightIcon;
