import * as React from 'react';

const SvgTwitterIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22.154}
    height={18}
    viewBox="0 0 22.154 18"
    {...props}
  >
    <path
      id="Path_87123"
      data-name="Path 87123"
      d="M22.154,50.131a9.469,9.469,0,0,1-2.617.717,4.516,4.516,0,0,0,2-2.51,9.076,9.076,0,0,1-2.88,1.1A4.541,4.541,0,0,0,10.8,52.543a4.677,4.677,0,0,0,.105,1.036,12.855,12.855,0,0,1-9.361-4.751,4.543,4.543,0,0,0,1.4,6.07,4.485,4.485,0,0,1-2.052-.559v.05a4.563,4.563,0,0,0,3.639,4.463A4.533,4.533,0,0,1,3.334,59a4.016,4.016,0,0,1-.86-.078,4.585,4.585,0,0,0,4.244,3.164,9.126,9.126,0,0,1-5.631,1.937A8.5,8.5,0,0,1,0,63.962,12.786,12.786,0,0,0,6.967,66,12.838,12.838,0,0,0,19.894,53.076c0-.2-.007-.395-.017-.587A9.061,9.061,0,0,0,22.154,50.131Z"
      transform="translate(0 -48)"
      fill="currentColor"
    />
  </svg>
);

export default SvgTwitterIcon;
