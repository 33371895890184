import * as React from 'react';
import PropTypes from 'prop-types';
import { useToken } from '@chakra-ui/react';

const SvgCaretIcon = ({ color, ...props }) => {
  const [backgroundFill] = useToken('colors', [color]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={9}
      height={5}
      viewBox="0 0 10.66 6"
      {...props}
    >
      <path
        id="Down"
        d="M-411.136,262.9a.655.655,0,0,1,.2.474.655.655,0,0,1-.2.474l-4.66,4.66a.655.655,0,0,1-.474.2.655.655,0,0,1-.474-.2l-4.66-4.66a.655.655,0,0,1-.2-.474.655.655,0,0,1,.2-.474.655.655,0,0,1,.474-.2h9.319A.655.655,0,0,1-411.136,262.9Z"
        transform="translate(421.6 -262.7)"
        fill={backgroundFill}
      />
    </svg>
  );
};

SvgCaretIcon.propTypes = {
  color: PropTypes.string
};

export default SvgCaretIcon;
