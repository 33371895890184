import * as React from 'react';

const SvgSettingIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20.3}
    height={20.3}
    viewBox="0 0 20.3 20.3"
    {...props}
  >
    <g
      id="Group_57328"
      data-name="Group 57328"
      transform="translate(-1.85 -1.85)"
    >
      <path
        id="Path_86994"
        data-name="Path 86994"
        d="M19.767,9.333A.6.6,0,0,1,19.233,9a.461.461,0,0,1,.133-.6,2.253,2.253,0,0,0,0-3.167l-.6-.6a2.253,2.253,0,0,0-3.167,0,.563.563,0,0,1-.6.133.523.523,0,0,1-.333-.533A2.233,2.233,0,0,0,12.433,2h-.867A2.233,2.233,0,0,0,9.333,4.233.6.6,0,0,1,9,4.767a.461.461,0,0,1-.6-.133,2.253,2.253,0,0,0-3.167,0l-.6.6a2.253,2.253,0,0,0,0,3.167.563.563,0,0,1,.133.6.523.523,0,0,1-.533.333A2.233,2.233,0,0,0,2,11.567v.867a2.233,2.233,0,0,0,2.233,2.233A.6.6,0,0,1,4.767,15a.461.461,0,0,1-.133.6,2.253,2.253,0,0,0,0,3.167l.6.6a2.253,2.253,0,0,0,3.167,0,.563.563,0,0,1,.6-.133.523.523,0,0,1,.333.533A2.233,2.233,0,0,0,11.567,22h.867a2.233,2.233,0,0,0,2.233-2.233A.6.6,0,0,1,15,19.233a.5.5,0,0,1,.6.133,2.253,2.253,0,0,0,3.167,0l.6-.6a2.253,2.253,0,0,0,0-3.167.563.563,0,0,1-.133-.6.523.523,0,0,1,.533-.333A2.233,2.233,0,0,0,22,12.433v-.867A2.233,2.233,0,0,0,19.767,9.333Zm.567,3.1a.584.584,0,0,1-.567.567,2.233,2.233,0,0,0-1.6,3.8.545.545,0,0,1,0,.8l-.6.6a.545.545,0,0,1-.8,0,2.256,2.256,0,0,0-2.433-.5,2.22,2.22,0,0,0-1.367,2.067.584.584,0,0,1-.567.567h-.867a.584.584,0,0,1-.567-.567A2.22,2.22,0,0,0,9.6,17.7a2.007,2.007,0,0,0-.867-.167,2.227,2.227,0,0,0-1.567.667.545.545,0,0,1-.8,0l-.6-.6a.545.545,0,0,1,0-.8,2.256,2.256,0,0,0,.5-2.433A2.22,2.22,0,0,0,4.2,13a.555.555,0,0,1-.533-.567v-.867A.562.562,0,0,1,4.233,11,2.22,2.22,0,0,0,6.3,9.633,2.163,2.163,0,0,0,5.8,7.2a.545.545,0,0,1,0-.8l.6-.6a.545.545,0,0,1,.8,0,2.269,2.269,0,0,0,2.433.5A2.22,2.22,0,0,0,11,4.233a.562.562,0,0,1,.567-.567h.867A.562.562,0,0,1,13,4.233a2.233,2.233,0,0,0,3.8,1.6.545.545,0,0,1,.8,0l.6.6a.545.545,0,0,1,0,.8,2.256,2.256,0,0,0-.5,2.433,2.22,2.22,0,0,0,2.067,1.367.584.584,0,0,1,.567.567ZM12,8.033A3.967,3.967,0,1,0,15.967,12,3.976,3.976,0,0,0,12,8.033ZM12,14.3A2.3,2.3,0,1,1,14.3,12,2.3,2.3,0,0,1,12,14.3Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={0.3}
      />
    </g>
  </svg>
);

export default SvgSettingIcon;
