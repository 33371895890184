import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Icon,
  Tooltip,
  useColorModeValue,
  useMediaQuery
} from '@chakra-ui/react';

function FooterButton({ title, icon, iconSize = 5, isActive, ...props }) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const color = useColorModeValue('#5B6F8E', 'white');
  const colorActive = useColorModeValue('#0027FF', 'white');

  return (
    <Tooltip label={title} placement="top" isDisabled={isMobile} hasArrow>
      <Button 
        variant="ghost"
        height={10}
        size={{ base: 'sm', md: 'md' }}
        {...props}>
        {icon && 
          <Icon as={icon} h={iconSize} w={iconSize}
            color={isActive
              ? colorActive
              : color
            } />
        }
        {props.children}
      </Button>
    </Tooltip>
  );
}

FooterButton.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.elementType.isRequired,
  iconSize: PropTypes.number,
  isActive: PropTypes.bool,
  children: PropTypes.any
};

export default FooterButton;
