import { createAction } from 'redux-api-middleware';
import i18n from 'i18n';

export const CREATE_DOCUMENT_PIN_REQUEST = '@@api/CREATE_DOCUMENT_PIN_REQUEST';
export const CREATE_DOCUMENT_PIN_SUCCESS = '@@api/CREATE_DOCUMENT_PIN_SUCCESS';
export const CREATE_DOCUMENT_PIN_FAILURE = '@@api/CREATE_DOCUMENT_PIN_FAILURE';

const createDocumentPin = (data) => (dispatch, getState) => {
  const { documents } = getState();

  const documentId = documents.data.documentId;
  if (! documentId) return;

  return dispatch(createAction({
    endpoint: process.env.REACT_APP_API + `/documents/${documentId}/pins`,
    method: 'POST',
    credentials: 'include',
    headers: {
      'Accept-Language': i18n.language,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      documentId: documentId,
      ...data
    }),
    types: [
      CREATE_DOCUMENT_PIN_REQUEST,
      CREATE_DOCUMENT_PIN_SUCCESS,
      CREATE_DOCUMENT_PIN_FAILURE
    ]
  }));
};

export default createDocumentPin;
