import * as React from 'react';

const SvgVideoIcon = (props) => (
  <svg
    data-name="Group 55024"
    xmlns="http://www.w3.org/2000/svg"
    width={60}
    height={60}
    viewBox="0 0 60 60"
    {...props}
  >
    <g
      id="Ellipse_456"
      data-name="Ellipse 456"
      fill="#fff"
      stroke="#0027ff"
      strokeWidth={2}
    >
      <circle cx={30} cy={30} r={30} stroke="none" />
      <circle cx={30} cy={30} r={29} fill="none" />
    </g>
    <path
      id="Path_102971"
      data-name="Path 102971"
      d="M37.764,983.362A2.859,2.859,0,0,0,35,986.244v18.221a2.848,2.848,0,0,0,4.244,2.527l15.793-9.14a2.867,2.867,0,0,0,0-4.975l-15.793-9.14a2.952,2.952,0,0,0-1.481-.375Z"
      transform="translate(-15 -965.362)"
      fill="#0027ff"
    />
  </svg>
);

export default SvgVideoIcon;
