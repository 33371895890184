import * as React from 'react';

const SvgCalculateIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22.748}
    height={22.7}
    viewBox="0 0 22.748 22.7"
    {...props}
  >
    <path
      id="Calculate"
      d="M5,9.082a.816.816,0,0,1,.816-.816H8.266V5.816a.816.816,0,0,1,1.633,0V8.266h2.449a.816.816,0,0,1,0,1.633H9.9v2.449a.816.816,0,0,1-1.633,0V9.9H5.816A.816.816,0,0,1,5,9.082ZM20.076,7.351a.82.82,0,1,1,1.159-1.159l1.731,1.731L24.7,6.192a.82.82,0,0,1,1.159,1.159L24.125,9.082l1.731,1.731a.833.833,0,0,1,0,1.159.816.816,0,0,1-.588.229.8.8,0,0,1-.571-.229l-1.731-1.731-1.731,1.731a.8.8,0,0,1-.571.229.816.816,0,0,1-.588-.229.833.833,0,0,1,0-1.159l1.731-1.731ZM8.511,20.272a.784.784,0,0,1,0-1.143.8.8,0,0,1,.882-.245.588.588,0,0,1,.261.18.784.784,0,0,1,0,1.143.588.588,0,0,1-.261.18.7.7,0,0,1-.31,0A.767.767,0,0,1,8.511,20.272ZM9.654,25.66a.767.767,0,0,1,.245.571.669.669,0,0,1,0,.31.865.865,0,0,1-.441.441.7.7,0,0,1-.31,0,.767.767,0,0,1-.571-.245.588.588,0,0,1-.31-.2.669.669,0,0,1,0-.31.767.767,0,0,1,.245-.571.8.8,0,0,1,.882-.245.588.588,0,0,1,.261.245Zm3.511-2.694a.816.816,0,0,1-.816.816H5.816c-.451,0-.816-.121-.816-.572s.366-1.061.816-1.061h6.531A.816.816,0,0,1,13.164,22.966Zm13.884,0a.816.816,0,0,1-.816.816H19.7a.816.816,0,1,1,0-1.633h6.531A.816.816,0,0,1,27.048,22.966Z"
      transform="translate(-4.65 -4.65)"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.7}
    />
  </svg>
);

export default SvgCalculateIcon;
