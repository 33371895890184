import i18n from 'i18n';
import { SET_SCALE } from './actionTypes';
import { createStandaloneToast } from '@chakra-ui/toast';

const MIN_SCALE = 0.1;
const DEFAULT_SCALE_DELTA = 1.1;

export function decreaseScale(steps = 1) {
  const { toast } = createStandaloneToast();

  return (dispatch, getState) => {
    const { viewer } = getState();

    // steps parametresine göre
    // yakınlaştırmayı ayarlayalım.
    let newScale = viewer.scale;
    do {
      newScale = (newScale / DEFAULT_SCALE_DELTA).toFixed(2);
      newScale = Math.floor(newScale * 10) / 10;
      newScale = Math.max(MIN_SCALE, newScale);
    } while (--steps > 0 && newScale > MIN_SCALE);

    // uzaklaştırma sınırına ulaştığında uyarı verelim.
    if (newScale === MIN_SCALE) {
      const id = 'decreaseScale';

      if (! toast.isActive(id)) {
        toast({
          id: id,
          title: i18n.t('toast.title.maxZoom'),
          description: i18n.t('toast.message.maxZoom'),
          status: 'warning',
          position: 'top',
          duration: 9000,
          isClosable: true
        });
      }
      return;
    }

    dispatch({ type: SET_SCALE, payload: newScale });
  };
}
