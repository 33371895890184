import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import redirectToLogin from 'helpers/redirectToLogin';

function Home() {
  if (process.env.REACT_APP_MODE === 'development') {
    return (
      <Navigate to="/genc-kalemler-3-cilt-sayi-21-1956-sb-253-40461/page/1" />
    );
  }

  return <Outlet>{redirectToLogin()}</Outlet>;
}

export const loader = () => {};
export const action = () => {};

export default Home;
