import * as React from 'react';

const SvgWarningIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20.238}
    height={18.5}
    viewBox="0 0 20.238 18.5"
    {...props}>
    <g
      id="Group_58563"
      data-name="Group 58563"
      transform="translate(-205.47 -114.75)">
      <path
        id="Path_103811"
        data-name="Path 103811"
        d="M324.934,174.2a.327.327,0,0,0,.328.219c.131,0,.219-.087.306-.241a11.593,11.593,0,0,0,.437-2.143,20.672,20.672,0,0,0,.262-2.778c0-1.225-.328-1.815-1.006-1.815-.7,0-1.028.612-1.028,1.815a20.71,20.71,0,0,0,.284,2.953,9.147,9.147,0,0,0,.416,1.99Z"
        transform="translate(-109.651 -47.827)"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={0.5}
      />
      <path
        id="Path_103812"
        data-name="Path 103812"
        d="M323.634,369.6a1.014,1.014,0,0,0-.744.328,1.026,1.026,0,0,0-.328.766,1.041,1.041,0,0,0,.306.744,1.026,1.026,0,0,0,.766.328,1.062,1.062,0,0,0,.766-.328,1.007,1.007,0,0,0,0-1.487,1.038,1.038,0,0,0-.766-.35Z"
        transform="translate(-108.045 -242.09)"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={0.5}
      />
      <path
        id="Path_103813"
        data-name="Path 103813"
        d="M117.325,63.255l-7.152-12.6a2.668,2.668,0,0,0-2.34-1.378,2.625,2.625,0,0,0-2.34,1.378l-7.152,12.6a2.7,2.7,0,0,0,2.362,4.024h14.282a2.679,2.679,0,0,0,2.34-4.024Zm-.831,2.231a1.771,1.771,0,0,1-1.531.875H100.7a1.771,1.771,0,0,1-1.531-.875,1.718,1.718,0,0,1,0-1.772l7.13-12.6a1.747,1.747,0,0,1,1.531-.9,1.721,1.721,0,0,1,1.531.9l7.13,12.6a1.717,1.717,0,0,1,0,1.772Z"
        transform="translate(107.756 65.721)"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={0.5}
      />
    </g>
  </svg>
);

export default SvgWarningIcon;
