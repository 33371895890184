import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import { Button, Icon, Text, useColorModeValue } from '@chakra-ui/react';

// Components
import i18n from 'i18n';
import { ContextMenuContext } from 'components/ContextMenu';
import { ListHeadingIcon } from 'components/Icons';

function AddTitle({ onClick }) {
  const { closeMenu } = useContext(ContextMenuContext);
  const color = useColorModeValue('#021b62', 'white');

  const handleClick = () => {
    closeMenu();

    // parent onClick event'ını tetikleyelim.
    if (onClick) {
      onClick('title');
    }
  };

  return (
    <Button
      variant="ghost"
      borderBottomColor="transparent"
      borderBottomWidth={3}
      borderRadius={0}
      flexDirection="column"
      fontSize="xs"
      fontWeight="medium"
      height="auto"
      p={3}
      size="lg"
      onClick={handleClick}>
      <Icon as={ListHeadingIcon} color={color} h={5} w={5} />
      <Text mt={2}>
        <Trans 
          i18nKey="form.label.add" 
          values={{
            field: i18n.t('pin.type.title.title')
          }}
          components={{ br: <br /> }} 
        />
      </Text>
    </Button>
  );
}

AddTitle.propTypes = {
  onClick: PropTypes.func
};

export default AddTitle;
