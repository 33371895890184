import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import { apiMiddleware } from 'redux-api-middleware';
import { persistStore, persistReducer } from 'redux-persist';
import { getPersistConfig } from 'redux-deep-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import { createMiddleware } from 'redux-beacon';
import GoogleAnalyticsGtag from '@redux-beacon/google-analytics-gtag';

// reducers
import reducers from './reducers';
import events from './reducers/events';

// variables
const middlewares = [thunk, apiMiddleware];

// activate the logger other than the production
if (process.env.REACT_APP_MODE !== 'production') {
  middlewares.push(createLogger({
    collapsed: true,
    diff: true
  }));
}

// analytics events'ları için middleware kullanalım.
middlewares.push(createMiddleware(
  events,
  GoogleAnalyticsGtag(process.env.REACT_APP_ANALYTICS_ID)
));

// bazı state'lerin tarayıcıda kalıcı
// tutulması için ayar yapalım.
const persistedReducer = persistReducer(
  getPersistConfig({
    key: 'root',
    storage: storage,
    whitelist: [
      'app.idle'
    ],
    rootReducer: reducers
  }),
  reducers
);

const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(...middlewares))
);

export const persistor = persistStore(store);

export default store;
