import * as React from 'react';
import PropTypes from 'prop-types';
import { useColorMode } from '@chakra-ui/react';

const SvgLoadingIcon = ({ style, ...props }) => {
  const { colorMode } = useColorMode();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={16}
      width={16}
      fill={colorMode === 'dark'
        ? 'white'
        : 'false'
      }
      style={{
        animation: 'spinLoadingIcon 1s steps(12,end) infinite',
        willChange: 'transform',
        ...style
      }}
      {...props}
    >
      <style>
        {'\n    @keyframes spinLoadingIcon{to{transform:rotate(360deg)}}\n  '}
      </style>
      <path d="M7 3V1s0-1 1-1 1 1 1 1v2s0 1-1 1-1-1-1-1z" />
      <path
        d="M4.63 4.1l-1-1.73S3.13 1.5 4 1c.87-.5 1.37.37 1.37.37l1 1.73s.5.87-.37 1.37c-.87.57-1.37-.37-1.37-.37z"
        fillOpacity={0.93}
      />
      <path
        d="M3.1 6.37l-1.73-1S.5 4.87 1 4c.5-.87 1.37-.37 1.37-.37l1.73 1s.87.5.37 1.37c-.5.87-1.37.37-1.37.37z"
        fillOpacity={0.86}
      />
      <path d="M3 9H1S0 9 0 8s1-1 1-1h2s1 0 1 1-1 1-1 1z" fillOpacity={0.79} />
      <path
        d="M4.1 11.37l-1.73 1S1.5 12.87 1 12c-.5-.87.37-1.37.37-1.37l1.73-1s.87-.5 1.37.37c.5.87-.37 1.37-.37 1.37z"
        fillOpacity={0.72}
      />
      <path
        d="M3.63 13.56l1-1.73s.5-.87 1.37-.37c.87.5.37 1.37.37 1.37l-1 1.73s-.5.87-1.37.37c-.87-.5-.37-1.37-.37-1.37z"
        fillOpacity={0.65}
      />
      <path d="M7 15v-2s0-1 1-1 1 1 1 1v2s0 1-1 1-1-1-1-1z" fillOpacity={0.58} />
      <path
        d="M10.63 14.56l-1-1.73s-.5-.87.37-1.37c.87-.5 1.37.37 1.37.37l1 1.73s.5.87-.37 1.37c-.87.5-1.37-.37-1.37-.37z"
        fillOpacity={0.51}
      />
      <path
        d="M13.56 12.37l-1.73-1s-.87-.5-.37-1.37c.5-.87 1.37-.37 1.37-.37l1.73 1s.87.5.37 1.37c-.5.87-1.37.37-1.37.37z"
        fillOpacity={0.44}
      />
      <path d="M15 9h-2s-1 0-1-1 1-1 1-1h2s1 0 1 1-1 1-1 1z" fillOpacity={0.37} />
      <path
        d="M14.56 5.37l-1.73 1s-.87.5-1.37-.37c-.5-.87.37-1.37.37-1.37l1.73-1s.87-.5 1.37.37c.5.87-.37 1.37-.37 1.37z"
        fillOpacity={0.3}
      />
      <path
        d="M9.64 3.1l.98-1.66s.5-.874 1.37-.37c.87.5.37 1.37.37 1.37l-1 1.73s-.5.87-1.37.37c-.87-.5-.37-1.37-.37-1.37z"
        fillOpacity={0.23}
      />
    </svg>
  );
};

SvgLoadingIcon.propTypes = {
  style: PropTypes.object
};

export default SvgLoadingIcon;
