import React from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';

// Providers
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { ChakraProvider } from '@chakra-ui/react';
import { PersistGate } from 'redux-persist/integration/react';
import { PDFDocumentProvider } from 'components/PDFDocument';

// Polyfills
import 'url-search-params-polyfill';

// App
import i18n from './i18n';
import store, { persistor } from 'store';
import routes, { Fallback } from 'routes';
import reportWebVitals from './reportWebVitals';

// UI
import './index.css';
import theme from './theme';

createRoot(document.getElementById('root')).render(
  <React.Fragment>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ChakraProvider theme={theme}>
          <I18nextProvider i18n={i18n}>
            <HelmetProvider>
              <PDFDocumentProvider>
                <RouterProvider router={routes} fallbackElement={<Fallback />} />
              </PDFDocumentProvider>
            </HelmetProvider>
          </I18nextProvider>
        </ChakraProvider>
      </PersistGate>
    </Provider>
  </React.Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
