import React, { Fragment } from 'react';
import { PropTypes } from 'prop-types';

// Components
import SubjectForm from './SubjectForm';
import NoteForm from './NoteForm';

function GetPinForm({ type, children, ...props }) {
  if (type === 'subject') {
    return <SubjectForm {...props}>{children}</SubjectForm>;
  } else if (type === 'note') {
    return <NoteForm {...props}>{children}</NoteForm>;
  }

  return <Fragment />;
}

GetPinForm.propTypes = {
  type: PropTypes.string.isRequired,
  children: PropTypes.node
};

export default GetPinForm;