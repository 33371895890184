import * as React from 'react';
import PropTypes from 'prop-types';

const SvgCloseIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={13.121}
    height={13.121}
    viewBox="0 0 13.121 13.121"
    {...props}
  >
    <path
      id="Close-2"
      data-name="Close"
      d="M8.042,6.56,12.8,1.834a1.094,1.094,0,0,0,.317-.776A1.014,1.014,0,0,0,12.8.353a1.086,1.086,0,0,0-1.481,0L6.56,5.079,1.834.317A1.094,1.094,0,0,0,1.058,0,1.014,1.014,0,0,0,.353.317a1.138,1.138,0,0,0-.282.741A.911.911,0,0,0,.388,1.8L5.079,6.56.317,11.287A1.094,1.094,0,0,0,0,12.062a1.014,1.014,0,0,0,.317.705,1.138,1.138,0,0,0,.741.282.961.961,0,0,0,.741-.317L6.56,8.042,11.287,12.8a1.015,1.015,0,0,0,.741.317h.035a1.014,1.014,0,0,0,.705-.317,1.128,1.128,0,0,0,.035-1.481Z"
      fill={props.color}
    />
  </svg>
);

SvgCloseIcon.propTypes = {
  color: PropTypes.string
};

export default SvgCloseIcon;
